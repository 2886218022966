import { postHomeRecommand } from 'apis/recommand';
import AdBanner from 'components/AdBanner';
import CenterView from 'components/CenterView';
import CenterViewSlide from 'components/CenterViewSlide';
import SessionList from 'components/SessionSlide';
import SportsTagView from 'components/SportsTagView';
import { sectionType } from 'constants/types/recommand';
import { CenterType, SessionType, SportsTagType } from 'constants/types/type';
import { useEffect, useState } from 'react';
import { Margin } from 'styles/style';
import * as S from './style';
import { useQuery } from '@tanstack/react-query';
import Loading from 'components/Loading';

const RecommandList = () => {
  const [offset, setOffset] = useState<number>(0);

  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: ['homeRecommand', offset], // 캐싱을 위한 키
    queryFn: () =>
      postHomeRecommand({
        limit: 10,
        offset,
        count: 10,
      }), // 이전 데이터를 유지
  });

  if (isLoading) {
    return <Loading num={2} />;
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  const { sections, sessions, centers, sportsTags, advertBanner } = data;
  return (
    <>
      <Margin margin='0 0 40px 0' />
      {sections.map(
        (section: { [key: string]: any; type: sectionType }, index: number) => {
          return (
            <div key={index}>
              {(section.type === 'center' ||
                section.type === 'sessionList' ||
                section.type === 'sportsTag') && (
                <>
                  <S.TitleContainer>
                    <S.SectionSubTitle>{section.subtitle}</S.SectionSubTitle>
                    <S.SectionTitle>{section.title}</S.SectionTitle>
                  </S.TitleContainer>
                  <Margin margin='0 0 20px 0' />
                </>
              )}

              {section.type === 'center' && (
                <>
                  <CenterViewSlide
                    items={centers.filter((center: CenterType) =>
                      section.items.some(
                        (item: any) => item.id === center.centerId
                      )
                    )}
                  />
                  <Margin margin='0 0 40px 0' />
                </>
              )}
              {section.type === 'sessionList' && (
                <>
                  <SessionList
                    items={sessions.filter((session: SessionType) =>
                      section.items.some(
                        (item: any) => item.id === session.sessionId
                      )
                    )}
                  />
                  <Margin margin='0 0 40px 0' />
                </>
              )}
              {section.type === 'sportsTag' && (
                <>
                  <SportsTagView
                    items={sportsTags.filter((sportsTag: SportsTagType) =>
                      section.items.some(
                        (item: any) => item.id === sportsTag.tagId
                      )
                    )}
                  />
                  <Margin margin='0 0 40px 0' />
                </>
              )}
              {section.type === 'advertBanner' && (
                <>
                  <AdBanner
                    items={advertBanner.filter((advertBanner: any) =>
                      section.items.some(
                        (item: any) => item.id === advertBanner.advertBannerId
                      )
                    )}
                  />
                  <Margin margin='0 0 40px 0' />
                </>
              )}
            </div>
          );
        }
      )}
    </>
  );
};

export default RecommandList;
