import {
  NotificationTypes,
  PushIconType,
  PushType,
} from 'constants/types/notification';
import { Noti_Message, Noti_Out, Noti_Post } from 'components/Icon/Icons';
import Icon from 'components/Icon';
import { timeAgo } from '../../constants/common';

import * as S from './style';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CommunityItem from 'components/CommunityItem';
import FeedbackItem from 'components/FeedbackItem';

type NotiItemType = {
  item: NotificationTypes;
};
const NotiItem = ({ item }: NotiItemType) => {
  const navigate = useNavigate();
  const [currentInfo, setCurrentInfo] = useState<{
    icon?: PushIconType;
    url: string;
    centerName?: string;
  }>({
    icon: 'Noti_Message',
    url: '',
    centerName: '',
  });

  useEffect(() => {
    switch (item.pushType) {
      case 'P001':
        setCurrentInfo({
          icon: 'Noti_Message',
          url: item.deeplinkPath,
          centerName: item.notiData?.centerName,
        });
        break;
      case 'P002':
        setCurrentInfo({
          icon: 'Noti_Post',
          url: item.deeplinkPath,
          centerName: item.notiData?.centerName,
        });
        break;
      case 'P003':
        setCurrentInfo({
          url: item.deeplinkPath,
          centerName: item.notiData?.centerName,
        });
        break;
      case 'P004':
        setCurrentInfo({
          icon: 'Noti_Post',
          url: item.deeplinkPath,
        });
        break;
      case 'P005':
        setCurrentInfo({
          icon: 'Noti_Post',
          url: item.deeplinkPath,
        });
        break;
    }
  }, [item]);
  return (
    <S.Container onClick={() => navigate(currentInfo.url)}>
      {item.pushType === 'P003' && (
        <FeedbackItem
          role='member'
          feedback={item.notiData}
          title={item.pushTitle}
          circleImage={item.notiData?.requestedBy?.profileImage.mediumImage}
          firstDesc={currentInfo.centerName}
        />
      )}
      {item.pushType !== 'P003' && (
        <>
          <S.IconWrapper>
            {currentInfo.icon && <Icon icon={currentInfo.icon} />}
          </S.IconWrapper>
          <S.Contaienr2>
            <S.Title>{item.pushTitle}</S.Title>
            <S.SubDesc>
              {currentInfo.centerName && (
                <>
                  <span>{currentInfo.centerName}</span>
                  <span>-</span>
                </>
              )}
              <span>{timeAgo(item.sendDate)}</span>
            </S.SubDesc>
            {(item.pushType === 'P001' || item.pushType === 'P002') && (
              <S.Content maxLine={2}>{item.notiData.content}</S.Content>
            )}
          </S.Contaienr2>
        </>
      )}
    </S.Container>
  );
};
export default NotiItem;
