import * as S from '../../PageHeader/style';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { deviceSizes } from 'constants/theme';
import { useTranslation } from 'react-i18next';

type PageHeaderForRequestFeedbackType = {
  centerName: string;
};
export const PageHeaderForRequestFeedback = ({
  centerName,
}: PageHeaderForRequestFeedbackType) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Container backgroundColor='black'>
        <TitleContainer>
          <Title>{t('피드백 요청하기')}</Title>
          <TitleSub>{centerName}</TitleSub>
        </TitleContainer>
        <CancleButton onClick={() => window.history.back()}>{t('취소')}</CancleButton>
      </Container>
    </>
  );
};

type ContainerType = {
  backgroundColor?: string;
};
const Container = styled.div<ContainerType>`
  position: fixed;
  width: 100%;
  max-width: 600px;
  min-width: 320px;
  height: 60px;
  z-index: 9999;
  background-color: ${({ backgroundColor }) => backgroundColor || 'black'};
  border-bottom: 1px solid var(--c900);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  font-size: 1.3rem;
  font-weight: 600;
`;
const TitleSub = styled.span`
  font-family: SF Pro Text;
  font-size: 1rem;
  font-weight: 400;
  line-height: 11.93px;
  letter-spacing: -0.5px;
  text-align: center;
`;
const CancleButton = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  font-family: SF Pro Text;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-align: right;
  cursor: pointer;
`;
