import { CenterType } from './types/type';

type targetNameType = 'recentSearch' | 'recentActivity';
type recentSearchType = {
  targetName: 'recentSearch';
  data: string;
};
type recentActivityType = {
  targetName: 'recentActivity';
  data: CenterType;
};
export const getLocalStorage = (targetName: targetNameType) => {
  const data = localStorage.getItem(targetName);
  if (data) {
    const sortedData = JSON.parse(data) as any[];
    return sortedData.reverse();
  } else {
    return [];
  }
};
export const saveLocaStorage = ({
  targetName,
  data,
}: recentSearchType | recentActivityType) => {
  const targetArray = JSON.parse(localStorage.getItem(targetName) || '[]');

  // item type = string | Object
  const existingIndex = targetArray.findIndex(
    (item: any) => JSON.stringify(item) === JSON.stringify(data)
  );
  if (existingIndex !== -1) {
    targetArray.splice(existingIndex, 1);
  }

  targetArray.push(data);

  if (targetName === 'recentSearch' && targetArray.length > 3) {
    targetArray.shift();
  }
  if (targetName === 'recentActivity' && targetArray.length > 10) {
    targetArray.shift();
  }

  localStorage.setItem(targetName, JSON.stringify(targetArray));
};
