import styled from 'styled-components';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const AllCheckItem = styled(Item)`
  justify-content: space-between;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
export const CheckBox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
export const TermName = styled.span`
  font-size: 1.4rem;
  color: var(--c100);
  text-align: left;
`;
export const ViewSpan = styled.div`
  color: white;
  font-size: 1.4rem;
  text-decoration: underline;
  cursor: pointer;
`;
export const IconWrapper = styled.div`
  cursor: pointer;
`;
