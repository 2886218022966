import styled from 'styled-components';

type ProgressClickableAreaType = {
  height: number;
};
export const ProgressClickableArea = styled.div<ProgressClickableAreaType>`
  position: absolute;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  bottom: ${({ height }) => `-${height / 2}%`};
  background-color: transparent;
  z-index: 999;
`;

type ProgressContainerType = {
  height: number;
};
export const ProgressContainer = styled.div<ProgressContainerType>`
  position: relative;
  width: 100%;
  height: ${({ height }) => `${height}px`};
`;

type ProgressBarType = {
  backgroundColor: string;
  borderRadius: string;
  height: number;
};
export const ProgressBar = styled.div<ProgressBarType>`
  position: absolute;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ backgroundColor }) => backgroundColor};
  overflow: hidden;
  cursor: pointer;
`;

type CurrentBarType = {
  width: number;
  height: number;
};
export const CurrentBar = styled.div<CurrentBarType>`
  position: absolute;
  left: 0;
  max-width: 100%;
  width: ${({ width }) => (width ? `${width}%` : '0%')};
  height: ${({ height }) => `${height}px`};
  background-color: var(--primary);
  cursor: pointer;
`;
type CurrentBallType = {
  left: number;
};
export const CurrentBall = styled.div<CurrentBallType>`
  position: absolute;
  left: ${({ left }) => (left ? `calc(${left}% - 8px)` : '0%')};
  top: -50%;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: var(--primary);
`;

type FlagBarType = {
  left: number;
  height: number;
};
export const FlagBar = styled.div<FlagBarType>`
  position: absolute;
  left: ${({ left }) => `${left}%`};
  height: ${({ height }) => `${height}px`};
  width: 5px;
  background-color: white;
`;
