import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCZ1BNRaJiFXqnw64SSE3I0xRZqQS8tdrM',
  authDomain: 'whistle-pro-f7cce.firebaseapp.com',
  projectId: 'whistle-pro-f7cce',
  storageBucket: 'whistle-pro-f7cce.appspot.com',
  messagingSenderId: '282257427084',
  appId: '1:282257427084:web:8230898161cccc3fe149a9',
  measurementId: 'G-DHWTV3FFRS',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(app);
export default firebaseAnalytics;
