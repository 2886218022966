import Icon from 'components/Icon';
import PageHeader from 'components/PageHeader';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccountStore } from 'store/auth';
import styled from 'styled-components';
import { logout } from 'utils/logout';
import { handleLanguageChange, openNewWindow } from 'constants/common';
import { WithdrawalModal } from 'components/WithdrawalModal';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { LanguageSelectBox } from 'components/LanguageSelectBox';

const AccountPage = () => {
  const { account } = useAccountStore();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  type ItemType = {
    name: string;
    value?: string | JSX.Element;
    isArrow?: boolean;
    action?: any;
  };
  const ItemList: { title: string; subList: ItemType[] }[] = [
    {
      title: t('계정정보'),
      subList: [
        {
          name: t('계정 아이디'),
          value: account.email,
        },
        {
          name: t('비밀번호 변경'),
          value: t('변경하기'),
          action: () => navigate('/myaccount/pwdReset'),
          isArrow: true,
        },
        {
          name: t('휴대폰 번호'),
          value: account.phoneNumber,
        },
      ],
    },
    {
      title: t('언어'),
      subList: [
        {
          name: t('언어 선택'),
          value: <LanguageSelectBox />,
        },
      ],
    },
    {
      title: t('서비스 정보'),
      subList: [
        {
          name: t('고객센터'),
          action: () => openNewWindow('https://pf.kakao.com/_zldjG'),
        },
        {
          name: t('이용약관'),
          action: () => openNewWindow('/setting/terms/provision'),
        },
        {
          name: t('개인정보 처리방침'),
          action: () => openNewWindow('/setting/terms/privacy'),
        },
        {
          name: t('마케팅 이용정보'),
          action: () => openNewWindow('/setting/terms/marketing'),
        },
      ],
    },
    {
      title: t('로그인'),
      subList: [
        {
          name: t('로그아웃'),
          action: logout,
        },
        {
          name: t('회원 탈퇴'),
          action: () => setIsModalOpen(true),
        },
      ],
    },
  ];

  const clickHandler = (item: ItemType) => {
    if (item.action) {
      item.action();
      return;
    }
  };
  return (
    <>
      <PageHeader title={t('계정')} isLineShow={true} />
      <Container>
        {ItemList.map((item) => (
          <Section>
            <Row cursor='default'>
              <ItemTitle>{item.title}</ItemTitle>
            </Row>
            {item.subList.map((sub) => (
              <Row
                cursor={sub.action ? 'pointer' : 'default'}
                onClick={() => clickHandler(sub)}
              >
                <Item>{sub.name}</Item>
                <Item>
                  {sub.value}
                  {sub.isArrow && <Icon icon='RightArrow2' />}
                </Item>
              </Row>
            ))}
          </Section>
        ))}
      </Container>
      {isModalOpen && <WithdrawalModal setIsModalOpen={setIsModalOpen} />}
    </>
  );
};
export default AccountPage;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
`;
const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;
type RowType = {
  cursor: 'default' | 'pointer';
};
const Row = styled.div<RowType>`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid var(--background-color);
  cursor: ${({ cursor }) => cursor};
`;
export const ItemTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  letter-spacing: -0.5px;
  color: var(--c300);
`;
const Item = styled.div`
  font-family: SF Pro Text;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 14.32px;
  letter-spacing: -0.5px;
  text-align: left;
  color: white;

  display: flex;
  gap: 9.7px;
`;
const CustomSelect = styled.select`
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 8px;
  padding-right: 22px;
  font-size: 1.2rem;
  border: none;
  appearance: none;
  -webkit-appearance: none; /* for Safari */
  -moz-appearance: none; /* for Firefox */
  position: relative;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpath fill='white' d='M0 0l5 6 5-6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center; /* Adjust as needed */
  background-size: 8px 6px; /* Adjust as needed */
  cursor: pointer;

  &:focus {
    outline: none;
  }

  option {
    font-size: 1.2rem;
    color: white;
  }
`;
