import React from 'react';

const LogoText = (props: any) => (
  <svg
    {...props}
    id='레이어_2'
    data-name='레이어 2'
    width='auto'
    height='auto'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 703.4 100'
  >
    <defs>
      <linearGradient
        id='무제_그라디언트_10'
        x1='196.67'
        y1='1.58'
        x2='196.67'
        y2='97.66'
        gradientTransform='translate(-180.54) skewX(5.29)'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0' stopColor='#191919' />
        <stop offset='1' stopColor='#16f596' />
      </linearGradient>
      <linearGradient
        id='무제_그라디언트_14'
        x1='578.19'
        y1='71.88'
        x2='578.19'
        y2='4.77'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.11' stopColor='#16f596' />
        <stop offset='0.44' stopColor='#f6f6f6' />
      </linearGradient>
      <style>
        {`.cls-1 { fill: url(#무제_그라디언트_10); }
          .cls-2 { fill: #f6f6f6; }
          .cls-3 { fill: #16f596; }
          .cls-4 { fill: url(#무제_그라디언트_14); }`}
      </style>
    </defs>
    <g id='logo_set' data-name='logo set'>
      <g>
        <g>
          <polygon
            className='cls-1'
            points='21.81 97.66 0 97.66 20.23 1.58 41.64 3.66 21.81 97.66'
          />
          <path
            className='cls-3'
            d='M20.23,1.58S60.68-3.16,85.16,3.77c7.11,2.01,9.8,9.8,5.31,15.15h0c-4.25,5.06-12.51,5.45-17.42,.89C65.01,12.34,40.88,3.48,20.23,1.58Z'
          />
          <path
            className='cls-3'
            d='M54.56,51.91c10.78-2.79,21.63-7.88,30.21-16.89,3.14-3.3-.56-8.42-5.06-7.06-11.39,3.44-34.68,7.07-65.5,2.2l-4.88,23.17s9.04,1.79,21.46,1.61c23.77,12.35,30.54,42.71,30.54,42.71h27.81c-3.55-27.54-18.59-40.22-34.58-45.74Z'
          />
        </g>
        <g>
          <g>
            <g>
              <path
                className='cls-3'
                d='M459.15,89.57h203.71c-1.51,6.12-7,10.43-13.31,10.43h-203.71c1.51-6.12,7-10.43,13.31-10.43Z'
              />
              <path
                className='cls-3'
                d='M462.88,74.25h217.79c-1.51,6.12-7,10.43-13.31,10.43h-217.79s0,0,0,0c1.51-6.12,7-10.43,13.31-10.43Z'
              />
              <path
                className='cls-4'
                d='M697.68,16.38c-14.23-19.69-43.42-14.73-66.44-14.87l-10.96,52.35h20.24l7.53-36.09c5.9,.08,20.3-.43,24.19,1.21,19.84,7.38,12.27,39.94-3.24,39.95h-202.7c-6.31,0-11.8,4.3-13.31,10.43h216.37c.06,0,.12,0,.18,0,42.31-.32,36.08-42,28.16-52.96Z'
              />
            </g>
            <polygon
              className='cls-2'
              points='386.25 2.07 364.43 97.7 384.92 97.7 394.11 57.47 438.89 57.47 442.15 42.3 397.58 42.3 403.3 17.24 450.29 17.24 453.55 2.07 386.25 2.07'
            />
            <polygon
              className='cls-2'
              points='549.94 2.07 538.35 52.87 603.56 52.87 605.83 42.3 561.26 42.3 566.99 17.24 613.97 17.24 617.23 2.07 549.94 2.07'
            />
            <polygon
              className='cls-2'
              points='468.59 2.07 457 52.87 522.21 52.87 524.48 42.3 479.91 42.3 485.64 17.24 532.62 17.24 535.88 2.07 468.59 2.07'
            />
          </g>
          <g>
            <polygon
              className='cls-2'
              points='120.4 2.04 98.56 97.7 165.81 97.7 169.07 82.53 122.53 82.53 128.25 57.46 173.05 57.46 176.31 42.28 131.72 42.28 137.45 17.21 184.45 17.21 187.71 2.04 120.4 2.04'
            />
            <path
              className='cls-2'
              d='M242.22,77.58l3.48,20.12h20.55L249.7,2.04h-21.99l-50.29,95.66h19.98l10.58-20.12h34.25Zm-8.48-49.02l5.85,33.84h-23.65l17.79-33.84Z'
            />
            <polygon
              className='cls-2'
              points='299.86 82.53 318.25 2.04 297.76 2.04 275.89 97.7 347.83 97.7 351.09 82.53 299.86 82.53'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LogoText;
