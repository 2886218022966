import './App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import GlobalStyle from './GlobalStyle';
import DefaultLayout from './components/DefaultLayout';
import PwdResetForm from './components/PwdResetForm';
import LoginPage from './pages/LoginPage';
import DashBoardPage from './pages/RecommandPage';
import SessionPage from './pages/SessionPage';
import ManageUserPage from 'pages/ManageUserPage';
import CommunityPage from './pages/CommunityPage';
import NotifyPage from './pages/NotifyPage';
import ProfilePage from './pages/ProfilePage';
import PayPage from './pages/PayPage';
import KaKaoMap from 'components/KaKaoMap';
import SignUpPage from 'pages/SignupPage';
import AddSessionPage from 'pages/AddSessionPage';
import './firebase.tsx'; //Analytics 사용
import {
  useAccessTokenStore,
  useCenterStore,
  useProfileStore,
} from 'store/auth';
import LoginLayout from 'components/LoginLayout';
import FeedbackPage from 'pages/FeedbackPage';
import DetailFeedbackPage from 'pages/DetailFeedbackPage';
import EditCenterForm from 'components/EditCenterForm';
import { DetailCommunityPage } from 'pages/DetailCommunityPage';
import ManageCenterPage from 'pages/ManageStaffPage';
import DetailSessionPage from 'pages/DetailSessionPage';
import { AddCommunityPage } from 'pages/AddCommunityPage';
import { EditCommunityPage } from 'pages/EditCommunityPage';
import EditSessionPage from 'pages/EditSessionPage';
import MemberListPage from 'pages/MemberListPage';
import StaffListPage from 'pages/StaffListPage';
import ManageStaffPage from 'pages/ManageStaffPage';
import { Term1 } from 'pages/Term1';
import { Term2 } from 'pages/Term2';
import { CenterProfilePage } from 'pages/CenterProfilePage';
import ApplyCenterPage from 'pages/ApplyCenterPage';
import EditCenterPage from 'pages/EditCenterPage';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorFallback from 'components/ErrorFallback';
import PendingView from 'components/PendingView';
import { Term3 } from 'pages/Term3';
import MyActivityPage from 'pages/MyActivityPage';
import RecommandPage from './pages/RecommandPage';
import SessionDetailPage from 'pages/SessionDetailPage';
import CenterMainPage from 'pages/CenterMainPage';
import SearchPage from 'pages/SearchPage';
import SessionVideoPage from 'pages/SessionVideoPage';
import AccountPage from 'pages/AccountPage';
import PwdResetPage_InAccount from 'pages/PwdResetPage_InAccount';

import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import { Term1_en } from 'pages/Term1_en';
import { Term2_en } from 'pages/Term2_en';
import { Term3_en } from 'pages/Term3_en';

const queryClient = new QueryClient();

function UnauthenticatedRoutes() {
  return (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/signup' element={<SignUpPage />} />
        <Route path='/pwdReset' element={<PwdResetForm />} />
      </Route>
    </Routes>
  );
}

function ActivatedCenterRoutes() {
  return (
    <Routes>
      <Route element={<DefaultLayout />}>
        <Route path='/kakao' element={<KaKaoMap />} />

        {/* 대시보드 */}
        <Route path='/dashboard/recommand' element={<RecommandPage />} />
        <Route path='/dashboard/myActivity' element={<MyActivityPage />} />

        {/* 세션 */}
        <Route path='/session/detail' element={<SessionDetailPage />} />

        {/* 센터 */}
        <Route path='/center/detail/:nav' element={<CenterMainPage />} />

        {/* 검색 */}
        <Route path='/search' element={<SearchPage />} />

        {/* 알림 */}
        <Route path='/notify' element={<NotifyPage />} />

        {/* 피드백 */}
        <Route path='/feedback' element={<FeedbackPage />} />
        <Route path='/feedback/detail' element={<DetailFeedbackPage />} />

        {/* 커뮤니티 */}
        <Route path='/center/community' element={<CommunityPage />} />
        <Route path='/center/community/add' element={<AddCommunityPage />} />
        <Route path='/center/community/edit' element={<EditCommunityPage />} />
        <Route
          path='/center/community/detail'
          element={<DetailCommunityPage />}
        />

        <Route path='/setting/profile' element={<ProfilePage />} />
        <Route path='/setting/pay' element={<PayPage />} />

        {/* 계정 */}
        <Route path='/myaccount' element={<AccountPage />} />
      </Route>

      {/* 세션 */}
      <Route path='/session/detail/video' element={<SessionVideoPage />} />

      <Route path='/myaccount/pwdReset' element={<PwdResetPage_InAccount />} />

      <Route path='*' element={<Navigate to='/dashboard/recommand' />} />
    </Routes>
  );
}
function RoutingComponent() {
  //auth
  const { accessToken } = useAccessTokenStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (accessToken === '') {
      navigate('/login');
    }
  }, [accessToken]);

  if (!accessToken) return <UnauthenticatedRoutes />;

  return <ActivatedCenterRoutes />;
}

function App() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState('ko');

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      setLanguage(savedLanguage);
    }
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <GlobalStyle />
        <Router>
          <Routes>
            {/* 모두 접근 가능 */}
            <Route
              path='/setting/terms/provision'
              element={language === 'en' ? <Term1_en /> : <Term1 />}
            />
            <Route
              path='/setting/terms/privacy'
              element={language === 'en' ? <Term2_en /> : <Term2 />}
            />
            <Route
              path='/setting/terms/marketing'
              element={language === 'en' ? <Term3_en /> : <Term3 />}
            />

            {/* 기존의 RoutingComponent 로직 */}
            <Route path='*' element={<RoutingComponent />} />
          </Routes>
        </Router>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
