import { postSearch } from 'apis/search';
import CenterViewSlide from 'components/CenterViewSlide';
import Loading from 'components/Loading';
import SearchInput from 'components/SearchInput';
import SessionCard from 'components/SessionCard';
import SessionSlide from 'components/SessionSlide';
import SessionView from 'components/SessionView';
import SportsTagView from 'components/SportsTagView';
import { debounce } from 'constants/common';
import { CenterType, SessionType, SportsTagType } from 'constants/types/type';
import { saveLocaStorage } from 'constants/useLocalStorage';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NotFoundDiv } from 'styles/style';
import { RecentSection } from './RecentSection';
import * as S from './style';

const SearchBox = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const keyword = searchParams.get('keyword');

  const [searchInput, setSearchInput] = useState<string>('');
  const [centers, setCenters] = useState<CenterType[]>([]);
  const [sportsTags, setSportsTags] = useState<SportsTagType[]>([]);
  const [sessions, setSessions] = useState<SessionType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (keyword) {
      setSearchInput(keyword);
      search(keyword);
    }
  }, [keyword]);

  const search = async (keyword: string) => {
    try {
      setIsLoading(true);
      const { listCenter, sessions, sportsTag } = await postSearch({
        searchDisplayName: keyword,
      });
      setSportsTags(sportsTag);
      setCenters(listCenter);
      setSessions(sessions);
      if (keyword) {
        saveLocaStorage({
          targetName: 'recentSearch',
          data: keyword,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate(`/search?keyword=${searchInput}`);
  };
  const { t } = useTranslation();

  return (
    <S.Container>
      <form onSubmit={submitHandler}>
        <SearchInput
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
      </form>
      {!keyword ? (
        <RecentSection />
      ) : (
        <>
          {isLoading ? (
            <Loading num={2} />
          ) : (
            <>
              <S.SearchListContainer>
                {sportsTags.length > 0 && (
                  <S.SectionContainer>
                    <S.Title>{t('카테고리 검색결과')}</S.Title>
                    <SportsTagView items={sportsTags} />
                  </S.SectionContainer>
                )}
                {centers.length > 0 && (
                  <S.SectionContainer>
                    <S.Title>{t('센터 검색결과')}</S.Title>
                    <CenterViewSlide items={centers} />
                  </S.SectionContainer>
                )}
                {sessions.length > 0 && (
                  <S.SectionContainer>
                    <S.Title>{t('세션 검색결과')}</S.Title>
                    <S.SessionCardContainer>
                      {sessions.map((session, index) => (
                        <SessionCard
                          key={session.sessionId + index}
                          session={session}
                        />
                      ))}
                    </S.SessionCardContainer>
                  </S.SectionContainer>
                )}
              </S.SearchListContainer>
              {sportsTags.length < 1 &&
                centers.length < 1 &&
                sessions.length < 1 && (
                  <NotFoundDiv>{t('검색 결과가 없습니다')}</NotFoundDiv>
                )}
            </>
          )}
        </>
      )}
    </S.Container>
  );
};
export default SearchBox;
