import AspectRatioBox from 'components/AspectRatioBox';
import Icon from 'components/Icon';
import {
  ControlsContainer,
  PlayButtonWrapper,
  ProgressTime,
} from 'components/MainVideoInput/style';
import { ConvertButton } from 'components/Video/ConvertButton';
import VideoPlayer from 'components/Video/VideoPlayer';
import { VideoType } from 'constants/types/type';
import { getMMSSFormat_colon } from 'constants/video';
import useVideoControl from 'hooks/useVideoControl';
import useVideoMetadata from 'hooks/useVideoMetadata';
import React, { useEffect, useRef, useState } from 'react';
import { Flex, Span } from 'styles/style';
import {
  Container,
  ConvertButtonWrapper,
  CurrentBar,
  ProgressBar,
  VideoWrapper,
  WholeContainer,
} from './style';
type FeedbackVideoContainerType = {
  // 필요한거 다 적음
  feedbackVideo: VideoType;
  userVideo: VideoType;
};
const FeedbackVideoContainer = ({
  feedbackVideo,
  userVideo,
}: FeedbackVideoContainerType) => {
  const {
    videoRef: feedbackVideoRef,
    currentBarHandler: currentBarHandler_f,
    play: playFeedbackVideo,
    pause: pauseFeedbackVideo,
  } = useVideoControl();
  const {
    videoRef: userVideoRef,
    currentBarHandler: currentBarHandler_u,
    play: playUserVideo,
    pause: pauseUserVideo,
  } = useVideoControl();
  const { currentTime } = useVideoMetadata({
    videoRef: feedbackVideoRef,
  });
  const [duration, setDuration] = useState<number>(0);
  const [isPaused, setIsPaused] = useState<boolean>(true);

  useEffect(() => {
    setDuration(Math.min(feedbackVideo.duration, userVideo.duration));
  }, [feedbackVideo, userVideo]);
  useEffect(() => {
    pauseAllVideos(); // autoplay true 설정되어 있기 때문에 처음에 멈춰주는 로직 필요함
  }, [feedbackVideoRef.current, userVideoRef.current]);

  const playAllVideos = () => {
    playFeedbackVideo();
    playUserVideo();
    setIsPaused(false);
  };
  const pauseAllVideos = () => {
    pauseFeedbackVideo();
    pauseUserVideo();
    setIsPaused(true);
  };
  return (
    <WholeContainer>
      <Flex flexDirection='column' maxWidth='600px' margin='0 auto'>
        <Container ratio={feedbackVideo.ratio}>
          <VideoWrapper>
            <AspectRatioBox
              ratio={feedbackVideo.ratio === 'landscape' ? 0.56 : 1.75}
            >
              <VideoWrapperComponent
                videoRef={feedbackVideoRef}
                video={feedbackVideo}
                isPaused={isPaused}
                setIsPaused={setIsPaused}
                duration={duration}
                muted={false}
              />
            </AspectRatioBox>
          </VideoWrapper>
          <VideoWrapper>
            <AspectRatioBox
              ratio={feedbackVideo.ratio === 'landscape' ? 0.56 : 1.75}
            >
              <VideoWrapperComponent
                videoRef={userVideoRef}
                video={userVideo}
                isPaused={isPaused}
                setIsPaused={setIsPaused}
                duration={duration}
                muted={true}
              />
            </AspectRatioBox>
          </VideoWrapper>
        </Container>
        <ControlsContainer>
          {isPaused ? (
            <PlayButton icon='PlayButton' action={playAllVideos} />
          ) : (
            <PlayButton icon='PauseButton' action={pauseAllVideos} />
          )}
          <Flex flexDirection='column' gap='7px' width='100%'>
            <ProgressTime>
              {getMMSSFormat_colon(currentTime)} /{' '}
              {getMMSSFormat_colon(duration)}
            </ProgressTime>
            <ProgressBar
              onClick={(e) => {
                currentBarHandler_f(e);
                currentBarHandler_u(e);
              }}
            >
              <CurrentBar width={(currentTime / duration) * 100} />
            </ProgressBar>
          </Flex>
        </ControlsContainer>
      </Flex>
    </WholeContainer>
  );
};

type PlayButtonProps = {
  icon: 'PlayButton' | 'PauseButton';
  action: () => void;
};

const PlayButton = ({ icon, action }: PlayButtonProps) => (
  <PlayButtonWrapper onClick={action}>
    <Icon icon={icon} width={20} height={20} />
  </PlayButtonWrapper>
);

type VideoWrapperProps = {
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  video: VideoType;
  isPaused: boolean;
  setIsPaused: React.Dispatch<React.SetStateAction<boolean>>;
  muted?: boolean;
  duration?: number;
};
const VideoWrapperComponent = ({
  videoRef,
  video,
  isPaused,
  setIsPaused,
  muted,
  duration,
}: VideoWrapperProps) => {
  const [flipped, setFlipped] = useState(false);

  useEffect(() => {
    if (video.streamingUrl.startsWith('blob:')) {
      setFlipped(true);
    }
  }, [video]);

  return (
    <>
      <VideoPlayer
        autoplay={true} // true여야 비디오 데이터 로드 이벤트를 받아올 수 있음
        videoRef={videoRef}
        src={video.streamingUrl}
        flipped={flipped}
        muted={muted}
        endTime={duration && duration / 1000} //VideoPlayer는 초단위를 쓰고 있음
        setIsPaused={setIsPaused}
        isStopImmediate={true}
      />
      {isPaused && (
        <ConvertButtonWrapper>
          <ConvertButton setFlipped={setFlipped} />
        </ConvertButtonWrapper>
      )}
    </>
  );
};

export default FeedbackVideoContainer;
