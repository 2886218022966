import styled from 'styled-components';
export const Container = styled.div`
  padding: 0 16px;
`;
export const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: 500px;
  z-index: -100;
`;
type BackgroundImageType = {
  backgroundImage?: string;
};
export const BackgroundImage = styled.div<BackgroundImageType>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: var(--background-color);
  background-position: center;
  background-image: ${({ backgroundImage }) =>
    `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0,0,0) 100%), url(${backgroundImage})`};

  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 -25px 20px -10px rgba(0, 0, 0, 1) inset;

  z-index: 0;
`;
export const MainInfoContainer = styled.div`
  position: absolute;
  top: 100px;
  left: 30px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-weight: 700;
  z-index: 2;
`;
export const SubInfoContainer = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 30px;
  letter-spacing: -0.5px;
`;
export const DisplayName = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CompletionCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 9px;
  margin-top: -18px;
  align-items: center;
`;
export const CircleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const CircleImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
  :not(:first-child) {
    margin-left: -11px;
  }
`;
export const CompletionCountText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.5px;
`;
export const SubInfoTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
`;
export const TextData = styled.div`
  width: 100%;
  height: 100%;
  word-break: keep-all;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 17.76px;
  text-align: left;
`;
type SubItemContainerType = {
  margin?: string;
};
export const SubItemContainer = styled.div<SubItemContainerType>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: ${({ margin }) => margin};
`;
export const IntroducesTitle = styled.div`
  color: white;
  font-size: 1.6rem;
  font-weight: 700;
`;
export const StartButton = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: -0.5px;
  margin: 35px auto;
  color: black;
  border-radius: 10px;
  cursor: pointer;
`;
export const CenterInfoCircle = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
`;
