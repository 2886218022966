import { postFeedbackList } from 'apis/feedback';
import Icon from 'components/Icon';
import { FeedbackType, ProfileType, SessionType } from 'constants/types/type';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Margin, NotFoundDiv, RelativeDiv, Span } from 'styles/style';
import useHandleScroll from 'utils/useHandleScroll';
import * as S from './style';
import FeedbackItem from 'components/FeedbackItem';
import { Line } from 'components/FeedbackItem/style';
import FeedbackItemForUser from 'components/FeedbackItemForUser';
import { useProfileStore } from 'store/auth';
import { useTranslation } from 'react-i18next';

const Feedback = () => {
  const navigate = useNavigate();
  const [FeedbackList, setFeedbackList] = useState<FeedbackType[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [menuOpenId, setMenuOpenId] = useState<string>('');

  const [scrollActive, setScrollActive] = useState<boolean>(true);

  // 필터링 조건
  const [onlyFailComplete, setOnlyFailComplete] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const centerId = searchParams.get('centerId');

  const [isPending, setIsPending] = useState<boolean>(true);

  const { profile, setProfile } = useProfileStore();

  useEffect(() => {
    getData();
  }, [onlyFailComplete, offset]);

  const getData = async () => {
    if (!centerId) {
      return;
    }
    setIsPending(true);
    const { feedbackList, listInfo } = await postFeedbackList({
      centerId,
      failedToComplete: onlyFailComplete,
      limit: 10,
      offset,
      count: 10,
    });
    if (feedbackList?.length > 0 && offset === 0) {
      setFeedbackList(feedbackList);
    } else if (feedbackList.length > 0) {
      setFeedbackList((prevList) => [...prevList, ...feedbackList]);
    } else {
      setScrollActive(false);
    }
    setIsPending(false);
  };

  const handleFilteringButton = () => {
    setOffset(0);
    setOnlyFailComplete((prev) => !prev);
  };
  useHandleScroll(setOffset, 5, scrollActive);
  const { t } = useTranslation();

  return (
    <>
      <S.TopBar>
        <S.FilteringContainer onClick={() => handleFilteringButton()}>
          {onlyFailComplete ? (
            <Icon icon='ToggleCheckButton_Active' width={16} height={16} />
          ) : (
            <Icon icon='ToggleCheckButton' width={16} height={16} />
          )}
          <Span
            fontSize='14px'
            color={onlyFailComplete ? 'var(--primary)' : 'white'}
            padding='1px 0 0 0'
          >
            {t('미완료만')}
          </Span>
        </S.FilteringContainer>
      </S.TopBar>

      {FeedbackList.length < 1 && !isPending ? (
        <NotFoundDiv>{t('등록된 피드백이 없습니다')}</NotFoundDiv>
      ) : (
        FeedbackList.map((feedback: FeedbackType, index: number) => (
          <RelativeDiv key={feedback.feedbackId + index}>
            <FeedbackItem
              role={profile.role}
              feedback={feedback}
              circleImage={feedback.requestedBy.profileImage?.mediumImage}
              title={feedback?.requestedBy?.nickname}
            />
            <Line isLast={index === FeedbackList.length - 1} />
          </RelativeDiv>
        ))
      )}
    </>
  );
};
export default Feedback;
