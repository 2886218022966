import { postAddLike } from 'apis/community';
import { ButtonWrapper } from 'components/CommentContainer/style';
import Icon from 'components/Icon';
import { ToggleMenu } from 'components/ToggleMenu';
import VideoPlayer from 'components/Video/VideoPlayer';
import { timeAgo } from 'constants/common';
import { PostType } from 'constants/types/type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CircleImage, Flex, PreLineDiv, Span } from 'styles/style';

import {
  ContentContainer,
  ContentWrapper,
  ItemContainer,
  NotiButton,
  StateContainer,
  StateItem,
  ThumbImageWrapper,
  TopContainer,
} from './style';
type CommunityItemProps = PostType & {
  menuOpenId?: string;
  setMenuOpenId?: React.Dispatch<React.SetStateAction<string>>;
  activeMenu?: boolean;
};

const CommunityItem = ({
  menuOpenId,
  setMenuOpenId,
  activeMenu,
  ...post
}: CommunityItemProps) => {
  const timeStamp = new Date().getTime();
  const navigate = useNavigate();
  const [isLike, setIsLike] = useState<boolean>(post.isLikeExist);
  const [likeCount, setLikeCount] = useState<number>(post.likeCount);

  const handleVideoClick = (
    e: React.MouseEvent<HTMLVideoElement, MouseEvent>
  ) => {
    e.stopPropagation(); // 이벤트 버블링 방지
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const centerId = searchParams.get('centerId');
  const { t } = useTranslation();

  const handleThumbsUp = async () => {
    if (!centerId) return;
    const response = await postAddLike({
      centerId,
      postId: post.postId,
      postType: '0',
      isLikeData: !isLike,
    });
    const result = response;
    if (!result) return;

    setLikeCount((prev) => (isLike ? (prev - 1 < 0 ? 0 : prev - 1) : prev + 1));
    setIsLike(!isLike);
  };
  return (
    <>
      {!post.postId || post.isDeleted ? (
        <ItemContainer>
          <CircleImage>
            <img src='/defaultUserImage.png' />
          </CircleImage>
          <ContentContainer>
            <ContentWrapper>{t('삭제된 게시글입니다')}</ContentWrapper>
          </ContentContainer>
        </ItemContainer>
      ) : (
        <ItemContainer
          onClick={() =>
            navigate(
              `/center/community/detail?postId=${post.postId}&centerId=${post.centerId}`
            )
          }
        >
          <Flex width='auto' flexDirection='column' gap='14px'>
            <CircleImage>
              <img
                src={
                  post.authorProfile?.profileImage?.thumbnailImage ||
                  '/defaultUserImage.png'
                }
                alt={t('프로필 이미지')}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.onerror = null; // 재귀적인 호출을 방지
                  target.src = '/defaultUserImage.png';
                }}
              />
            </CircleImage>
            {post.isNoti && <NotiButton>{t('공지')}</NotiButton>}
          </Flex>
          <Flex flexDirection='column' gap='10px' width='100%'>
            <TopContainer>
              <Span fontSize='14px' color='white'>
                {post.authorProfile?.nickname}
              </Span>
              <Span fontSize='12px' color='var(--c500)'>
                {timeAgo(post.createdAt)}
              </Span>
            </TopContainer>
            <ContentContainer>
              <ContentWrapper>
                <PreLineDiv>{post.content}</PreLineDiv>
              </ContentWrapper>
            </ContentContainer>
            {post.image && post.image.thumbnailImage && (
              <ThumbImageWrapper>
                <img
                  src={`${post.image.thumbnailImage}?${timeStamp}`}
                  alt='thumbnail'
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.style.display = 'none'; // 이미지를 숨깁니다.
                  }}
                />
              </ThumbImageWrapper>
            )}
            {post.video && post.video.streamingUrl && (
              <ThumbImageWrapper>
                <VideoPlayer
                  src={post.video.streamingUrl}
                  controls={true}
                  autoplay={false}
                  thumbnailImage={post.video.thumbnailImage?.originalImage}
                  handleClick={handleVideoClick}
                  isStopImmediate={false}
                />
              </ThumbImageWrapper>
            )}
            <StateContainer>
              <StateItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleThumbsUp();
                }}
              >
                {isLike || likeCount ? (
                  <>
                    <Icon icon='ThumbsUp_Active' width={17} height={15} />
                    <Span color='var(--primary)'>{likeCount}</Span>
                  </>
                ) : (
                  <>
                    <Icon icon='ThumbsUp' width={17} height={15} />
                    <Span color='white'>{likeCount}</Span>
                  </>
                )}
              </StateItem>
              <StateItem>
                <Icon icon='Message' width={16} height={16} />
                <Span color='white'>{post.replyCount}</Span>
              </StateItem>
            </StateContainer>
          </Flex>
          {activeMenu && (
            <Flex
              position='relative'
              flex={1}
              justifyContents='right'
              alignItems='flex-start'
            >
              <Flex
                onClick={(e) => {
                  e.stopPropagation();
                  if (menuOpenId === '' || menuOpenId !== post.postId) {
                    setMenuOpenId && setMenuOpenId(post.postId);
                  } else {
                    setMenuOpenId && setMenuOpenId('');
                  }
                }}
              >
                <Icon icon='DotMenu' width={20} height={20} />
              </Flex>
              {post.postId === menuOpenId && (
                <ToggleMenu
                  isMyPost={post.isMine}
                  postId={post.postId}
                  editUrl={`/center/community/edit?postId=${post.postId}&centerId=${post.centerId}`}
                />
              )}
            </Flex>
          )}
        </ItemContainer>
      )}
    </>
  );
};
export default CommunityItem;
