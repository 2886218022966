import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useCenterStore } from 'store/auth';
import { Container, Item } from './style';
import { postDeletePost, postDeleteReply } from 'apis/community';
import { useTranslation } from 'react-i18next';

type Type = {
  isMyPost: boolean;
  postId: string;
  replyId?: string;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setMenuOpenId?: React.Dispatch<React.SetStateAction<string>>;
  editUrl?: string;
  top?: string;
};
export const ToggleMenu = ({
  isMyPost,
  postId,
  replyId,
  editUrl,
  top,
  setMenuOpenId,
  setIsEdit,
}: Type) => {
  const { center } = useCenterStore();
  const location = useLocation();
  const pathname = location.pathname;
  const page = pathname.split('/').pop();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const centerId = searchParams.get('centerId');
  const { t } = useTranslation();

  const deletePost = async () => {
    try {
      if (!centerId) return;
      const response = await postDeletePost({
        centerId,
        postId,
      });
      const result = response;
      if (!result) return;

      page === 'detail'
        ? navigate(`/center/detail/community?centerId=${centerId}`)
        : navigate(0);
    } catch (e: any) {
      alert(
        `${t('게시글 삭제에 실패하였습니다')}.\n(${
          e.response.data.reasonCodeText
        })`
      );
    }
  };
  const deleteReply = async () => {
    if (!replyId || !centerId) return;
    const response = await postDeleteReply({
      params: { postId },
      data: {
        centerId,
        replyId,
      },
    });
    const result = response;
    if (!result) return;

    navigate(0);
  };
  return (
    <>
      <Container top={top}>
        {isMyPost ? (
          <>
            <Item
              onClick={(e) => {
                e.stopPropagation();
                editUrl && navigate(editUrl);
                setMenuOpenId && setMenuOpenId('');
                setIsEdit && setIsEdit(true);
              }}
            >
              {t('수정하기')}
            </Item>
            <Item
              color='var(--semantic-red)'
              onClick={(e) => {
                e.stopPropagation();
                if (window.confirm(t('정말 삭제하시겠습니까?'))) {
                  replyId ? deleteReply() : deletePost();
                }
              }}
            >
              {t('삭제하기')}
            </Item>
          </>
        ) : (
          <>
            <Item color='var(--semantic-red)'>{t('신고하기')}</Item>
            <Item
              color='var(--semantic-red)'
              onClick={(e) => {
                e.stopPropagation();
                if (window.confirm(t('정말 삭제하시겠습니까?')))
                  replyId ? deleteReply() : deletePost();
              }}
            >
              {t('삭제하기')}
            </Item>
          </>
        )}
      </Container>
    </>
  );
};
