const SupportAgent = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='ic_supportagent'>
      <path
        id='Union'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.22757 3.22098C5.2275 2.22615 6.58442 1.66665 8.00001 1.66665C9.4156 1.66665 10.7725 2.22615 11.7725 3.22098C12.5393 3.9839 13.048 4.95487 13.2435 5.99998H11.6667C10.5621 5.99998 9.66668 6.89541 9.66668 7.99998V10.3333C9.66668 11.4379 10.5621 12.3333 11.6667 12.3333H12C12.0888 12.3333 12.1765 12.329 12.263 12.3205C12.076 12.5302 11.8651 12.73 11.6309 12.9173C10.5114 13.813 8.96686 14.3333 7.33334 14.3333C6.96515 14.3333 6.66668 14.6318 6.66668 15C6.66668 15.3682 6.96515 15.6666 7.33334 15.6666C9.23605 15.6666 11.0829 15.0632 12.4639 13.9585C13.8473 12.8517 14.665 11.3151 14.6667 9.67157L14.6667 9.66665V6.96937V6.66665C14.6667 6.61835 14.6615 6.57125 14.6518 6.52587C14.5443 4.92685 13.8581 3.41514 12.7128 2.27576C11.4623 1.03164 9.76702 0.333313 8.00001 0.333313C6.233 0.333313 4.53767 1.03164 3.28717 2.27576C2.14196 3.41514 1.45576 4.92685 1.34824 6.52587C1.33848 6.57125 1.33334 6.61835 1.33334 6.66665V6.96937V9.66665V10.2381C1.33334 10.7995 1.56249 11.3343 1.96443 11.7258C2.36577 12.1167 2.90646 12.3333 3.46668 12.3333H4.20001C4.76023 12.3333 5.30092 12.1167 5.70226 11.7258C6.1042 11.3343 6.33334 10.7995 6.33334 10.2381V8.09522C6.33334 7.53378 6.1042 6.999 5.70226 6.6075C5.30092 6.21658 4.76023 5.99998 4.20001 5.99998H2.75653C2.95199 4.95487 3.46074 3.9839 4.22757 3.22098Z'
        fill='#B0B2BF'
      />
    </g>
  </svg>
);

export default SupportAgent;
