import { handleLanguageChange } from 'constants/common';
import { CustomSelect } from 'styles/LoginStyles';

export const LanguageSelectBox = () => {
  return (
    <CustomSelect
      onChange={handleLanguageChange}
      defaultValue={localStorage.getItem('language') || 'ko'}
    >
      <option value='ko'>한국어</option>
      <option value='en'>English</option>
    </CustomSelect>
  );
};
