import styled from 'styled-components';

export const Title = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 1.8rem;
  letter-spacing: -0.5px;
  margin-bottom: 20px;
  white-space: nowrap;
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--c900);
  margin-bottom: 20px;
`;
