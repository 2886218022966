import { postWithdrawal } from 'apis/user';
import { DEFAULT_ALERT_TEXT } from 'constants/alertText';
import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Span } from 'styles/style';
import { logout } from 'utils/logout';

type WithdrawalModalType = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export const WithdrawalModal = ({ setIsModalOpen }: WithdrawalModalType) => {
  const handleWithdrawal = async () => {
    try {
      const response = await postWithdrawal();
      console.log(response);
      logout();
    } catch (e) {
      alert(DEFAULT_ALERT_TEXT);
    }
  };
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <ContentContainer>
          <Title>{t('계정 탈퇴')}</Title>
          <Span textAlign='center'>
            {t('Whitle Pro를 탈퇴한 이후에는')}
            <br /> {t('서비스 내에 있는 전문가들의')}
            <br />
            <Span color='red'>
              {t('온라인 코칭 서비스를 이용할 수 없습니다')}
            </Span>
            <br />
            {t('그래도 탈퇴하시겠습니까?')}
          </Span>
          <ButtonContainer>
            <DeleteButton onClick={() => handleWithdrawal()}>
              {t('회원 탈퇴')}
            </DeleteButton>
            <CancleButton onClick={() => setIsModalOpen(false)}>
              {t('이용하기')}
            </CancleButton>
          </ButtonContainer>
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 64px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
`;
export const Wrapper = styled.div`
  width: 375px;
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
`;
export const ContentContainer = styled.div`
  width: 100%;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  padding: 24px;
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  gap: 20px;
`;
export const Title = styled.span`
  font-size: 1.5rem;
  color: white;
  font-weight: 500;
`;
export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;
export const Button = styled.button`
  height: 38px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-weight: 600;
`;
export const DeleteButton = styled(Button)`
  background-color: transparent;
  border: 1px solid white;
  color: white;
`;
export const CancleButton = styled(Button)`
  background-color: var(--primary);
  color: black;
  border: none;
`;
