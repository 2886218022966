import { useState, useEffect } from 'react';
import styled from 'styled-components';

type CountDownType = {
  count: number;
};
export const CountDown = ({ count }: CountDownType) => {
  return <Circle>{count}</Circle>;
};
const Circle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 130px;
  height: 130px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.35);

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70px;
  font-weight: 700;
  line-height: 83.54px;
  letter-spacing: -0.5px;
  text-align: center;

  font-family: 'San Francisco' !important;
`;
