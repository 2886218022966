import React, { useEffect, useCallback } from 'react';

const useHandleScroll = (
  setOffset: React.Dispatch<React.SetStateAction<number>>,
  range: number = 5,
  isActive: boolean
) => {
  const handleScroll = useCallback(() => {
    const scrolled =
      Math.floor(window.innerHeight + document.documentElement.scrollTop) / 2;
    const totalScroll = Math.floor(document.documentElement.offsetHeight / 2);

    if (Math.abs(scrolled - totalScroll) <= range) {
      setOffset((prevOffset) => prevOffset + 10);
    }
  }, [setOffset]);

  useEffect(() => {
    if (isActive) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll, isActive]);
};

export default useHandleScroll;
