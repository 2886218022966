import React, { useEffect, useRef } from 'react';
import Hls, { ErrorData } from 'hls.js';
import { VIDEOLOAD_ALERT_TEXT } from 'constants/alertText';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

type VideoPlayerType = {
  src: string;
  videoRef?: React.MutableRefObject<HTMLVideoElement | null>;
  controls?: boolean;
  flipped?: boolean;
  muted?: boolean;
  endTime?: number;
  setIsPaused?: React.Dispatch<React.SetStateAction<boolean>>;
  autoplay: boolean;
  thumbnailImage?: string;
  handleClick?: any;
  setIsConvertEnd?: React.Dispatch<React.SetStateAction<boolean>>;
  isStopImmediate: boolean;
};

const VideoPlayer = ({
  src,
  videoRef: externalVideoRef,
  thumbnailImage,
  controls,
  flipped,
  muted,
  endTime,
  setIsPaused,
  autoplay,
  handleClick,
  setIsConvertEnd,
  isStopImmediate,
}: VideoPlayerType) => {
  const internalVideoRef = useRef<HTMLVideoElement | null>(null);
  const retryInterval = 8000; // 초 (5000ms)
  const retryCountRef = useRef(0);
  const maxRetries = 10; // 최대 재시도 횟수 설정 (옵션)
  const timeStamp = new Date().getTime();
  const navigate = useNavigate();

  const finalVideoRef = externalVideoRef || internalVideoRef;
  useEffect(() => {
    try {
      if (
        Hls.isSupported() &&
        finalVideoRef.current &&
        !src.startsWith('blob:')
      ) {
        const hls = new Hls();
        const onError = (event: any, data: ErrorData) => {
          // 재시도
          if (retryCountRef.current >= maxRetries) {
            // 최대 재시도 횟수에 도달하면 더 이상 시도하지 않음.
            alert(VIDEOLOAD_ALERT_TEXT);
            navigate(-1);
            return;
          }
          setTimeout(() => {
            hls.loadSource(`${src}?${timeStamp}`);
            if (finalVideoRef.current) hls.attachMedia(finalVideoRef.current);
            retryCountRef.current += 1; // 재시도 횟수 증가
          }, retryInterval); // 5초 후 재시도
        };
        hls.on(Hls.Events.ERROR, onError);

        hls.loadSource(src);
        hls.attachMedia(finalVideoRef.current);
        return () => {
          if (hls) hls.destroy();
        };
      } else {
        if (finalVideoRef.current) finalVideoRef.current.src = src; // Blob URL 처리
      }
    } catch (e) {
      alert(VIDEOLOAD_ALERT_TEXT);
    }
  }, [src, finalVideoRef]);

  //endTime 설정했을 때
  useEffect(() => {
    const handleTimeUpdate = () => {
      if (
        endTime &&
        finalVideoRef.current &&
        finalVideoRef.current.currentTime >= endTime
      ) {
        finalVideoRef.current.pause();
        finalVideoRef.current.currentTime = 0;
        setIsPaused && setIsPaused(true);
      }
    };

    if (finalVideoRef.current) {
      finalVideoRef.current.addEventListener('timeupdate', handleTimeUpdate);
      finalVideoRef.current.addEventListener('canplay', () => {
        setIsConvertEnd && setIsConvertEnd(true);
        if (finalVideoRef.current && isStopImmediate)
          finalVideoRef.current.pause();
      });
    }

    return () => {
      if (finalVideoRef.current) {
        finalVideoRef.current.removeEventListener(
          'timeupdate',
          handleTimeUpdate
        );
      }
    };
  }, [endTime, finalVideoRef]);

  return (
    <Video
      controls={controls}
      ref={finalVideoRef}
      autoPlay={autoplay}
      playsInline
      muted={false}
      onClick={handleClick} // 클릭 이벤트 처리
      style={{
        transform: flipped ? 'scaleX(-1)' : 'scaleX(1)',
      }}
      poster={thumbnailImage && thumbnailImage}
    ></Video>
  );
};

export default VideoPlayer;

const Video = styled.video`
  video::-webkit-media-controls {
    display: none !important;
  }
`;
