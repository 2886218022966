import { useEffect } from 'react';
import * as S from './style';

type VideoProgressBarType = {
  progressRef?: React.RefObject<HTMLDivElement>;
  currentBarHandler: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  currentTime: number;
  duration: number;
  height: number;
  backgroundColor: string;
  borderRadius: string;
  flagTime?: number;
  startDragging?: () => void;
  isShowCurrenetBall: boolean;
};
const VideoProgressBar = ({
  progressRef,
  currentBarHandler,
  currentTime,
  duration,
  height,
  backgroundColor,
  borderRadius,
  flagTime,
  startDragging,
  isShowCurrenetBall,
}: VideoProgressBarType) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    currentBarHandler(e);
  };
  return (
    <>
      <S.ProgressClickableArea
        height={height + 20}
        ref={progressRef}
        onClick={(e) => handleClick(e)}
        onMouseDown={startDragging}
        onTouchStart={startDragging}
      />
      <S.ProgressContainer height={height}>
        <S.ProgressBar
          height={height}
          borderRadius={borderRadius}
          backgroundColor={backgroundColor}
        >
          <S.CurrentBar
            height={height}
            width={(currentTime / duration) * 100}
          />
          {flagTime && (
            <S.FlagBar height={height} left={(flagTime / duration) * 100} />
          )}
        </S.ProgressBar>
        {isShowCurrenetBall && (
          <S.CurrentBall left={(currentTime / duration) * 100} />
        )}
      </S.ProgressContainer>
    </>
  );
};
export default VideoProgressBar;
