export const deviceSizesNum = {
  mobile: 320,
  tablet: 768,
  laptop: 1024,
};
export const deviceSizes = {
  mobile: '320px',
  tablet: '768px',
  laptop: '1024px',
};

export const theme = {
  mobile: `screen and (max-width: ${deviceSizes.mobile})`,
  tablet: `screen and (max-width: ${deviceSizes.tablet})`,
  laptop: `screen and (max-width: ${deviceSizes.laptop})`,
};

export const RatioSize = {
  count_1: {
    left: 95,
    width: 90,
    ratio: 0.67,
  },
  count_2: {
    left: 50,
    width: 48,
    ratio: 0.36,
  },
};
