import Icon from 'components/Icon';
import Loading from 'components/Loading';
import SessionVideo from 'components/Session/SessionVideo';
import VideoRecorder from 'components/Session/VideoRecorder';
import { SceneType, VideoStatus } from 'constants/types/type';
import useVideoControl from 'hooks/useVideoControl';
import useVideoMetadata from 'hooks/useVideoMetadata';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Margin, Span } from 'styles/style';
import { CountDown } from '../CountDown';
import * as S from './style';

type CoachingVideo = {
  coachingScene: SceneType;
  currentStatus: VideoStatus;
  setCurrentStatus: (value: React.SetStateAction<VideoStatus>) => void;
  setRecordVideoBlob: (value: React.SetStateAction<Blob>) => void;
  setIsRequestFeedback: React.Dispatch<React.SetStateAction<boolean>>;
};
export const CoachingVideo = ({
  coachingScene,
  currentStatus,
  setCurrentStatus,
  setRecordVideoBlob,
  setIsRequestFeedback,
}: CoachingVideo) => {
  const {
    videoRef,
    progressRef,
    currentBarHandler,
    play,
    pause,
    move,
    startDragging,
    isVideoEnd,
  } = useVideoControl();
  const [count, setCount] = useState(0);
  const [isConvert, setIsConvert] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { currentTime: currentTime_c } = useVideoMetadata({
    videoRef,
    play,
    pause,
  });

  useEffect(() => {
    pause();
  }, []);
  useEffect(() => {
    const handleLoadedData = () => {
      pause();
    };

    const video = videoRef.current;
    if (video) {
      if (video.readyState >= 2) {
        // 이미 로드된 경우
        pause();
      } else {
        // 로드 이벤트 대기
        video.addEventListener('loadeddata', handleLoadedData);
      }
    }

    return () => {
      video?.removeEventListener('loadeddata', handleLoadedData);
    };
  }, []);

  // count down
  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1000);
      return () => clearTimeout(timer);
    }

    if (count < 1 && currentStatus === 'coaching_countdown') {
      setCurrentStatus('coaching_start');
      play();
    }
  }, [count]);

  useEffect(() => {
    if (
      coachingScene.additionalVideo &&
      currentTime_c >= coachingScene.additionalVideo?.duration
    ) {
      setCurrentStatus('session');
    }
  }, [currentTime_c]);

  const handleCoachingSkip = () => {
    setCurrentStatus('session');
    setIsRequestFeedback(false);
  };
  const countdownCoaching = () => {
    try {
      setIsLoading(true);
      if (videoRef.current) {
        videoRef.current
          .play()
          .then(() => {
            videoRef.current && videoRef.current.pause();
            setIsLoading(false);
            setIsRequestFeedback(true);
            setCurrentStatus('coaching_countdown');
            setCount(5);
          })
          .catch((error) => {
            console.error('Error playing video:', error);
            // 네트워크 상태가 좋지 않을 때 재시도 로직을 추가할 수 있음
            if (error.name === 'NotAllowedError') {
              console.log('User interaction required to play the video.');
            } else {
              // 재시도 로직 또는 대체 방법을 추가할 수 있습니다.
              setTimeout(countdownCoaching, 500); // 재시도
            }
          });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      {isLoading && <Loading num={2} />}
      {coachingScene.additionalVideo && (
        <S.VideoContainer>
          <SessionVideo
            video={coachingScene?.additionalVideo}
            videoRef={videoRef}
            flipped={isConvert}
            setIsPaused={pause}
            isCoaching={true}
          />
          <VideoRecorder
            ratio={coachingScene?.additionalVideo.ratio}
            duration={coachingScene.additionalVideo.duration}
            setRecordVideoBlob={setRecordVideoBlob}
            currentStatus={currentStatus}
            setCurrentStatus={setCurrentStatus}
            isCoachingEnd={isVideoEnd}
          />
        </S.VideoContainer>
      )}
      {count > 0 && <CountDown count={count} />}

      {currentStatus === 'coaching_wait' && (
        <S.PausedContainer onClick={(e) => e.stopPropagation()}>
          <S.SessionTextWrapper>
            <Span fontSize='2.5rem' fontWeight='700' lineHeight='3.5rem'>
              {t('지금 바로')}
              <br />
              {t('연습하고')}
              <br />
              {t('피드백을 받아보세요!')}
            </Span>
            <Margin margin='10px 0 0 0' />
            <S.CoachingSkipButton onClick={() => handleCoachingSkip()}>
              {t('건너뛰기')}
            </S.CoachingSkipButton>
            <Margin margin='20px 0 0 0' />
            <Flex
              gap='5px'
              alignItems='center'
              cursor='pointer'
              onClick={() => setIsConvert((prev) => !prev)}
            >
              {isConvert ? (
                <>
                  <Span color='var(--primary)'>{t('좌우반전')}</Span>
                  <Icon icon='CheckBox_Active' />
                </>
              ) : (
                <>
                  <Span color='var(--white)'>{t('좌우반전')}</Span>
                  <Icon icon='CheckBox' color='white' />
                </>
              )}
            </Flex>
          </S.SessionTextWrapper>
          <S.GreenStartBox onClick={() => countdownCoaching()}>
            <Icon icon='START_text' />
          </S.GreenStartBox>
        </S.PausedContainer>
      )}
    </>
  );
};
