import { useNavigate } from 'react-router-dom';
import { NewBadge, PremiumBadge } from 'styles/style';
import * as S from './style';
import 'swiper/css';
import { CenterType } from 'constants/types/type';
import { useTranslation } from 'react-i18next';

type CenterViewType = {
  item: CenterType;
};
const CenterView = ({ item }: CenterViewType) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <S.ItemContainer
      onClick={() => navigate(`/center/detail/all?centerId=${item.centerId}`)}
      backgroundImage={item?.bannerImages[0]?.largeImage}
    >
      {item.centerGrade === 'premium' && (
        <PremiumBadge>{t('회원전용')}</PremiumBadge>
      )}
      {item.centerGrade === 'new' && <NewBadge>New</NewBadge>}
      <S.Title>{item.displayName}</S.Title>
      <S.Desc>{item.location.fullAddress.split('|')[0]}</S.Desc>
    </S.ItemContainer>
  );
};

export default CenterView;
