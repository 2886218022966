import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Flex, RelativeDiv, Span } from '../../styles/style';
import {
  Form,
  Title,
  InputContainer,
  SubmitButton,
  Input,
  InputName,
  Red,
  GrayButton,
  Hr,
  Error,
  CheckBoxInput,
  CustomSelect,
} from '../../styles/LoginStyles';
import { Container } from './style';
import Icon from '../Icon';
import { TimerSpan } from 'styles/FormStyle';
import { TermsWrapper } from 'components/EditCenterFormAfter/style';
import Terms from 'components/Terms';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

import { handleLanguageChange } from 'constants/common';
import { LanguageSelectBox } from 'components/LanguageSelectBox';
import { IoIosArrowBack } from 'react-icons/io';
type TermType = {
  name: string;
  required: boolean;
  termId: number;
  url: string;
};
function SignUpForm() {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    control,
    formState: { isDirty, isValid, dirtyFields, errors },
  } = useForm();
  const expireTime = 180;
  const [emailRegToken, setEmailRegToken] = useState('');
  const [emailChecked, setEmailChecked] = useState('');
  const [isReSendActive, setIsResendActive] = useState(false);
  const [isAuthNumCorrect, setIsAuthNumCorrect] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(expireTime);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [resultTerms, setResultTerms] = useState<{
    [key: string]: boolean;
  }>({});

  const email = watch('email');
  const password = watch('password');
  const password2 = watch('password2');
  const phoneNumber = watch('phoneNumber');
  const authNumber = watch('authNumber');
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const phoneNumberRegex = /^(01([016789]))(\d{4})(\d{4})$/;
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,36}$/;
  const { t } = useTranslation();

  useEffect(() => {
    dirtyFields['email'] && trigger('email');
    dirtyFields['password'] && trigger('password');
    dirtyFields['password2'] && trigger('password2');
    dirtyFields['authNumber'] && trigger('authNumber');
  }, [email, password, password2, emailChecked, isAuthNumCorrect]);

  const isValidEmail = (email: string) => {
    return emailRegex.test(email);
  };
  const isValidPhoneNumber = (phoneNumber: string) => {
    return phoneNumberRegex.test(phoneNumber);
  };
  const emailDupCheck = async () => {
    trigger('email');
    if (!email || !isValidEmail(email)) {
      return;
    }
    const response = await fetch(`${API_URL}/api/mem-join/duplication-pwd`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    if (response.ok) {
      const result = await response.json();
      setEmailChecked(email);
      alert(t('사용 가능한 이메일입니다'));
      trigger('email');
    } else {
      alert(t('중복되는 이메일입니다'));
    }
  };
  const sendAuthNumber = async () => {
    setIsSending(true);
    trigger('phoneNumber');
    if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
      setIsSending(false);
      return;
    }

    const response = await fetch(`${API_URL}/api/mem-join/authnum-send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phoneNumber }),
    });

    if (response.ok) {
      const { emailRegToken } = await response.json();
      setEmailRegToken(emailRegToken);
      setIsResendActive(true);
      resetTimer();
      startTimer();
    } else {
      alert(t('이미 존재하는 전화번호입니다'));
      //error
    }
    setIsSending(false);
  };
  const checkAuthNumber = async () => {
    if (authNumber === '') {
      return;
    }
    const response = await fetch(`${API_URL}/api/mem-join/authnum-check`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber,
        emailRegToken,
        authNumber,
      }),
    });

    if (response.ok) {
      alert(t('전화번호 인증이 완료되었습니다'));
      setIsAuthNumCorrect(true);
      resetTimer();
    } else {
      setIsAuthNumCorrect(false);
      alert(t('인증번호가 일치하지 않습니다'));
      trigger('authNumber');
    }
  };

  //타이머
  const startTimer = () => {
    const id = setInterval(decreaseCounter, 1000);
    setIntervalId(id);
    setTimer(expireTime);
  };

  const decreaseCounter = () => {
    setTimer((prev) => {
      const newTimerValue = prev - 1;

      if (newTimerValue <= 0) {
        alert(
          t(
            '입력 시간이 초과되었습니다. 다시 재전송 버튼을 클릭하여 발급된 인증번호를 입력해주세요'
          )
        );
        resetTimer();
        setIsResendActive(true);
      }

      return newTimerValue;
    });
  };

  const resetTimer = () => {
    clearInterval(intervalId!);
    setIntervalId(null);
  };
  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);
  //

  const signUp = async () => {
    const response = await fetch(`${API_URL}/api/mem-join/join-complete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        phoneNumber,
        emailRegToken,
        term_permissions: resultTerms,
      }),
    });

    if (response.ok) {
      const { accessToken, refreshToken } = await response.json();
      alert(t('회원가입에 성공하였습니다'));

      navigate('/login');
    } else {
      //error
    }
  };
  return (
    <Container>
      <Flex
        width='100%'
        maxWidth='360px'
        justifyContents='space-between'
        alignItems='center'
      >
        <IoIosArrowBack
          onClick={() => window.history.back()}
          size={20}
          cursor='pointer'
        />
        <LanguageSelectBox />
      </Flex>
      <Form
        onSubmit={handleSubmit((data: any) => {
          signUp();
        })}
      >
        <Flex gap='13px'>
          <Flex margin='0 0 40px 0'>
            <Icon icon='LogoText' width={130} />
          </Flex>
          <Title>{t('회원가입')}</Title>
        </Flex>
        <InputContainer>
          <InputName>
            {t('이메일')} <Red>*</Red>
          </InputName>
          <Flex flexDirection='row' gap='7px'>
            <Input
              id='email'
              placeholder={t('이메일을 입력하세요')}
              aria-invalid={isDirty ? (errors.email ? true : false) : undefined}
              {...register('email', {
                required: t('이메일을 입력하세요'),
                pattern: {
                  value: emailRegex,
                  message: t('이메일 형식을 확인하세요'),
                },
                validate: {
                  isVerified: () =>
                    emailChecked !== email
                      ? t('이메일 중복 검사를 완료해주세요')
                      : true,
                },
              })}
              autoComplete='new-password'
            />
            <GrayButton type='button' onClick={() => emailDupCheck()}>
              {t('중복확인')}
            </GrayButton>
          </Flex>
          {errors.email && (
            <Error role='alert'>{errors.email.message?.toString()}</Error>
          )}
        </InputContainer>
        <InputContainer>
          <InputName>
            {t('비밀번호')} <Red>*</Red>
          </InputName>
          <Flex flexDirection='row'>
            <Span
              fontSize='13px'
              color='var(--c400)'
              margin='-2px 0 0 0'
              textAlign='left'
            >
              {t('영어, 숫자, 특수문자 포함 8글자 이상 36자 이내')}
            </Span>
          </Flex>
          <Input
            id='password'
            type='password'
            placeholder={t('비밀번호를 입력하세요')}
            aria-invalid={
              isDirty ? (errors.password ? true : false) : undefined
            }
            {...register('password', {
              required: t('비밀번호를 입력하세요'),
              pattern: {
                value: passwordRegex,
                message: t(
                  '비밀번호는 영어, 숫자, 특수문자를 포함하여 8글자 이상 36자 이내여야 합니다'
                ),
              },
            })}
            autoComplete='new-password'
          />
          {errors.password && (
            <Error role='alert'>{errors.password.message?.toString()}</Error>
          )}
          <Input
            id='password2'
            type='password'
            placeholder={t('비밀번호를 확인하세요')}
            aria-invalid={
              isDirty ? (errors.password2 ? true : false) : undefined
            }
            {...register('password2', {
              required: t('비밀번호를 입력하세요'),
              validate: {
                isVerified: () =>
                  password !== password2 ? t('비밀번호를 확인하세요') : true,
              },
            })}
            autoComplete='new-password'
          />
          {errors.password2 && (
            <Error role='alert'>{errors.password2.message?.toString()}</Error>
          )}
        </InputContainer>
        <InputContainer>
          <InputName>
            {t('전화번호')} <Red>*</Red>
          </InputName>
          <Flex flexDirection='row'>
            <Span fontSize='13px' color='var(--c400)' margin='-2px 0 0 0'>
              {t('문자를 제외한 숫자만 입력하세요')}
            </Span>
          </Flex>
          <Flex flexDirection='row' gap='7px' margin='0 0 3px 0'>
            <RelativeDiv>
              <Input
                id='phoneNumber'
                placeholder={t('전화번호를 입력하세요')}
                disabled={isAuthNumCorrect === true}
                aria-invalid={
                  isDirty ? (errors.phoneNumber ? true : false) : undefined
                }
                {...register('phoneNumber', {
                  required: t('전화번호를 입력해주세요'),
                  pattern: {
                    value: phoneNumberRegex,
                    message: t('유효한 전화번호를 입력해주세요'),
                  },
                })}
              />
              {!isAuthNumCorrect && (
                <TimerSpan>
                  {Math.floor(timer / 60)}:
                  {(timer % 60).toString().padStart(2, '0')}
                </TimerSpan>
              )}
            </RelativeDiv>
            <GrayButton
              type='button'
              onClick={() => {
                sendAuthNumber();
              }}
              disabled={isAuthNumCorrect === true || isSending}
            >
              {isReSendActive ? t('재전송') : t('인증번호 발송')}
            </GrayButton>
          </Flex>
          <Flex flexDirection='row' gap='7px' margin='-4px 0 0 0'>
            <Input
              id='authNumber'
              placeholder={t('인증번호를 입력하세요')}
              disabled={isAuthNumCorrect === true}
              aria-invalid={
                isDirty ? (errors.authNumber ? true : false) : undefined
              }
              {...register('authNumber', {
                required: t('인증번호를 입력하세요'),
                validate: {
                  isVerified: () =>
                    isAuthNumCorrect !== true
                      ? t('인증번호를 정확하게 입력해주세요')
                      : true,
                },
              })}
            />
            <GrayButton
              type='button'
              onClick={() => checkAuthNumber()}
              disabled={isAuthNumCorrect === true}
            >
              {t('확인')}
            </GrayButton>
          </Flex>
          {errors.phoneNumber ? (
            <Error>{errors.phoneNumber.message?.toString()}</Error>
          ) : (
            errors.authNumber && (
              <Error>{errors.authNumber.message?.toString()}</Error>
            )
          )}
        </InputContainer>
        <InputContainer>
          <InputName>{t('약관동의')}</InputName>

          <>
            <TermsWrapper>
              <Terms
                control={control}
                errors={errors}
                resultTerms={resultTerms}
                setResultTerms={setResultTerms}
                trigger={trigger}
              />
            </TermsWrapper>
          </>
        </InputContainer>
        <SubmitButton isValid={isValid} type='submit'>
          {t('회원가입')}
        </SubmitButton>
      </Form>
    </Container>
  );
}
export default SignUpForm;
