import { postSessionDetail } from 'apis/session';
import PageHeader from 'components/PageHeader';
import SessionVideo from 'components/Session/SessionVideo';
import SessionDetail from 'components/SessionDetail';
import { GET_ALERT_TEXT } from 'constants/alertText';
import { SessionType } from 'constants/types/type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NotFoundDiv } from 'styles/style';

const SessionDetailPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [session, setSession] = useState<SessionType>();

  const navigate = useNavigate();

  const centerId = searchParams.get('centerId');
  const sessionId = searchParams.get('sessionId');
  useEffect(() => {
    getSessionData();
  }, [centerId, sessionId]);
  const getSessionData = async () => {
    if (!sessionId || !centerId) {
      return;
    }
    const response = await postSessionDetail({
      params: {
        sessionId,
      },
      data: {
        centerId,
      },
    });
    const result = response;
    if (!result) return;

    const { sessionView } = result;
    if (sessionView) {
      setSession(sessionView);
    } else {
      alert(GET_ALERT_TEXT);
      navigate(-1);
    }
  };
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title='' backgroundColor='transparent' isLineShow={true} />
      {session ? (
        <SessionDetail session={session} />
      ) : (
        <NotFoundDiv>{t('세션을 찾을 수 없습니다')}</NotFoundDiv>
      )}
    </>
  );
};

export default SessionDetailPage;
