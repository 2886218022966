import { CaptionType } from 'constants/types/type';
import { Flex } from 'styles/style';
import * as S from './style';

type CaptionsType = {
  captions: CaptionType[];
};
const Captions = ({ captions }: CaptionsType) => {
  return (
    <Flex flexDirection='column' gap='10px' margin='20px 0 0 0'>
      {captions &&
        captions.length > 0 &&
        captions.map((caption: CaptionType) => (
          <S.CaptionLine>
            <S.CaptionIcon>
              <img src={caption.iconImageUrl} />
            </S.CaptionIcon>
            <S.CaptionText>{caption.text}</S.CaptionText>
          </S.CaptionLine>
        ))}
    </Flex>
  );
};
export default Captions;
