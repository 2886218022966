import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Flex } from '../../styles/style';
import Header from '../../components/Header';
import Footer from 'components/Footer/inex';
import { useCenterStore, useProfileStore } from 'store/auth';
import { useLoadingStore } from 'store/loading';
import { getCenterData } from 'apis/center';
import Loading from 'components/Loading';

import { deviceSizes } from 'constants/theme';

interface DefaultLayoutProps {
  children?: React.ReactElement;
}
export default function DefaultLayout({ children }: DefaultLayoutProps) {
  const { loading } = useLoadingStore();

  return (
    <>
      {loading && <Loading num={2} />}
      <Flex flexDirection='row' minWidth={`${deviceSizes.mobile}`}>
        <Flex
          flexDirection='column'
          position='relative'
          width='100%'
          maxWidth={`${deviceSizes.laptop}`}
          minWidth={`${deviceSizes.mobile}`}
          minHeight='100vh'
          margin='0 auto'
          overflow='hidden'
        >
          <Outlet />
          <Footer />
        </Flex>
      </Flex>
    </>
  );
}
