import { Container } from './style';
import Icon from 'components/Icon';
import { IconWrapper } from 'styles/FormStyle';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { postNotificationYN } from 'apis/alarm';

function Header() {
  const navigate = useNavigate();
  const [isNewNoti, setIsNewNoti] = useState<boolean>(false);
  useEffect(() => {
    getIsNewNoti();
  }, []);
  const getIsNewNoti = async () => {
    const { notificationComplete } = await postNotificationYN();
    setIsNewNoti(notificationComplete);
  };

  return (
    <Container>
      <IconWrapperRelative onClick={() => navigate('/notify')}>
        <Icon icon='Notify' width={20} height={20} />
        {isNewNoti && <RedDot />}
      </IconWrapperRelative>
      <IconWrapper onClick={() => navigate('/myaccount')}>
        <Icon icon='UserIcon' width={20} height={20} />
      </IconWrapper>
    </Container>
  );
}
export default Header;

const IconWrapperRelative = styled(IconWrapper)`
  position: relative;
`;
const RedDot = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  top: 0;
  right: 0;
  background-color: red;
  border-radius: 100%;
`;
