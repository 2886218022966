import { theme, deviceSizes } from 'constants/theme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: ${deviceSizes.mobile};
  display: flex;
  justify-content: right;
  gap: 14px;
  margin-top: 25px;
`;
