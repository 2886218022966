import { timeAgo } from 'constants/common';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { CircleImage, Flex, NotFoundDiv, PreLineDiv, Span } from 'styles/style';
import { useAccessTokenStore, useCenterStore } from 'store/auth';
import VideoPlayer from 'components/Video/VideoPlayer';
import { CommentContainer } from 'components/CommentContainer';
import Icon from 'components/Icon';
import { ToggleMenu } from 'components/ToggleMenu';
import { DeleteButton } from 'styles/FormStyle';
import { NotificationLine } from 'components/NotificationLine';
import { PostType, VideoType } from 'constants/types/type';
import MyProfileImage from 'components/MyProfileImage';
import { VIDEOUPLOAD_ALERT_TEXT } from 'constants/alertText';
import Img from 'components/Img';
import { postAddLike, postCommunity } from 'apis/community';
import PageHeaderWithSub from 'components/PageHeaderWithSub';
import { useTranslation } from 'react-i18next';
export const DetailCommunityPage = () => {
  const [post, setPost] = useState<PostType>();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [video, setVideo] = useState<string>('');

  //form data 이미 들어있는 데이터
  const [content, setContent] = useState<string>('');
  const [isConvertEnd, setIsConvertEnd] = useState<boolean>(false);

  const [isLike, setIsLike] = useState<boolean>(false);
  const timeStamp = new Date().getTime();

  const [searchParams, setSearchParams] = useSearchParams();
  const centerId = searchParams.get('centerId');
  const postId = searchParams.get('postId');

  useEffect(() => {
    getData();
    if (post?.video) {
      updateVideoData(post.video);
    }
  }, []);
  useEffect(() => {
    let retryCount = 0;
    const maxRetries = 10;
    const interval = 1000;
    if (post?.video && post.video.convertStatus !== 'completed') {
      const intervalId = setInterval(() => {
        if (retryCount >= maxRetries) {
          clearInterval(intervalId);
        } else {
          getData();
          retryCount++;
        }
      }, interval);

      return () => clearInterval(intervalId);
    } else {
      if (post?.video) {
        updateVideoData(post.video);
      }
    }
  }, [post]);

  const updateVideoData = (video: VideoType) => {
    setVideo(`${video?.streamingUrl}?${timeStamp}`);
  };

  const handleThumbsUp = async (postId: string, isLike: boolean) => {
    if (!centerId) return;
    const response = await postAddLike({
      centerId,
      postId,
      postType: '0',
      isLikeData: !isLike,
    });
    const result = response;
    if (!result) return;

    setIsLike(!isLike);
  };
  const getData = async () => {
    if (!postId || !centerId) return;
    const response = await postCommunity({
      params: {
        postId,
      },
      data: {
        centerId,
      },
    });
    const result = await response;
    const { view } = result;
    setPost(view);
  };
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderWithSub
        title={t('상세보기')}
        sub={post ? post.centerName : ''}
        isLineShow={true}
      />
      {post && postId && centerId ? (
        <Container>
          <MainContainer>
            <UserInfoContainer>
              <CircleImage>
                <img
                  src={
                    post.authorProfile?.profileImage?.thumbnailImage ||
                    '/defaultUserImage.png'
                  }
                  alt='profileImage'
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.onerror = null; // 재귀적인 호출을 방지
                    target.src = '/defaultUserImage.png';
                  }}
                />
              </CircleImage>
              <Flex flexDirection='column' gap='3px'>
                <Span>{post.authorProfile?.nickname}</Span>
                <Span fontSize='14px' color='var(--c400)'>
                  {timeAgo(post.createdAt)}
                </Span>
              </Flex>
              <Flex
                position='relative'
                flex={1}
                justifyContents='right'
                alignItems='flex-start'
                gap='15px'
              >
                <ButtonWrapper onClick={() => handleThumbsUp(postId, isLike)}>
                  {isLike ? (
                    <Icon icon='ThumbsUp_Active' width={20} height={20} />
                  ) : (
                    <Icon icon='ThumbsUp' width={20} height={20} />
                  )}
                </ButtonWrapper>
                <ButtonWrapper onClick={() => setIsMenuOpen((prev) => !prev)}>
                  <Icon icon='DotMenu' width={20} height={20} />
                </ButtonWrapper>
                {isMenuOpen && (
                  <ToggleMenu
                    postId={postId}
                    isMyPost={post.isMine}
                    top='26px'
                    setIsEdit={setIsEdit}
                    editUrl={`/center/community/edit?postId=${postId}&centerId=${centerId}`}
                  />
                )}
              </Flex>
            </UserInfoContainer>
            <PreLineDiv>{post.content}</PreLineDiv>
            {post?.video &&
              !isConvertEnd &&
              post.video.convertStatus != 'completed' && (
                <NotificationLine
                  margin='30px 0 0px 0'
                  type='alert'
                  text={t('영상을 변환하고 있는 중입니다...')}
                />
              )}
            {isEdit &&
              !post.image?.originalImage &&
              !post.video?.streamingUrl && (
                <label htmlFor='feedbackMedia'>
                  <AddMediaButton>
                    <Icon icon='Plus' width={10} height={10} />
                    <Span>{t('사진이나 동영상 추가하기')}</Span>
                  </AddMediaButton>
                </label>
              )}
            <Flex position='relative'>
              {post?.image?.originalImage && (
                <MediaWrapper>
                  <Img
                    src={post?.image.originalImage}
                    alt={t('게시글 이미지')}
                  />
                </MediaWrapper>
              )}
              {post.video && (
                <>
                  <MediaWrapper>
                    <VideoPlayer
                      controls={true}
                      autoplay={false}
                      src={video}
                      thumbnailImage={`${post.video.thumbnailImage?.originalImage}?${timeStamp}`}
                      setIsConvertEnd={setIsConvertEnd}
                      isStopImmediate={false}
                    />
                  </MediaWrapper>
                </>
              )}
            </Flex>
          </MainContainer>
          {<CommentContainer />}
        </Container>
      ) : (
        <NotFoundDiv>{t('게시글을 찾을 수 없습니다')}</NotFoundDiv>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  height: 100%;
  display: flex;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  margin: 60px auto 0;
  cursor: default;

  @media ${`screen and (max-width: 800px)`} {
    flex-direction: column;
  }
`;
const MainContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding: 32px;
  flex: 1;
  border-right: 1px solid rgb(55, 57, 66);

  @media ${`screen and (max-width: 800px)`} {
    overflow: visible; //default
    flex: initial;
    height: auto;
  }
`;
const UserInfoContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;
const MediaWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  margin-top: 20px;
  overflow: hidden;
`;
const ButtonWrapper = styled.div`
  cursor: pointer;
`;

const AddMediaButton = styled.div`
  width: 100%;
  padding: 15px;
  border-radius: 12px;
  background-color: var(--c700);
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: var(--c300);
  font-size: 1.6rem;
  cursor: pointer;
`;
