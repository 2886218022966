import { useQuery } from '@tanstack/react-query';
import { postCommunityList } from 'apis/community';
import CommunityItem from 'components/CommunityItem';
import { Line } from 'components/FeedbackItem/style';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { PostType } from 'constants/types/type';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCenterStore } from 'store/auth';
import styled from 'styled-components';
import { Flex, Span, Container, RelativeDiv } from 'styles/style';

const CommunityPage = () => {
  const [menuOpenId, setMenuOpenId] = useState<string>('');
  const [offset, setOffset] = useState(0);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const centerId = searchParams.get('centerId');
  const postId = searchParams.get('postId');
  const { t } = useTranslation();

  const { center } = useCenterStore();

  // 스크롤 처리
  const [totalCount, setTotalCount] = useState(0);
  const lastRef = useRef(null);
  const [observe, unobserve] = useIntersectionObserver(() => {
    setOffset((offset) => offset + 10);
  });

  // useQuery로 리팩토링
  const { data, isLoading, isError } = useQuery({
    queryKey: ['communityList', centerId, postId, offset],
    queryFn: async () => {
      if (!postId || !centerId) return null;
      const response = await postCommunityList({
        data: {
          centerId,
          limit: 10,
          offset,
          orderBy: 'desc',
        },
      });
      return response;
    },
    enabled: !!centerId && !!postId,
  });

  useEffect(() => {
    if (totalCount <= (data?.length || 0) || !data) {
      unobserve(lastRef.current);
    }
  }, [data, totalCount]);

  return (
    <>
      <Container>
        <Flex
          margin='0 0 35px 0'
          justifyContents='space-between'
          alignItems='center'
        >
          <Span fontSize='18px' fontWeight='700' whiteSpace='nowrap'>
            {t('커뮤니티')}
          </Span>
          <PostAddButton onClick={() => navigate('./add')}>
            <Icon icon='Plus' width={10} height={10} color='white' />
            <Span>{t('새 글쓰기')}</Span>
          </PostAddButton>
        </Flex>
        {isLoading ? (
          <Loading num={2} />
        ) : isError || !data || data.length === 0 ? (
          <Flex padding='30px 0 50px 0' justifyContents='center'>
            <Span fontWeight='bold' color='var(--c300)'>
              {t('등록된 게시글이 없습니다')}
            </Span>
          </Flex>
        ) : (
          <>
            {data.map((item: any, i: number) => (
              <RelativeDiv key={item.postId + i}>
                <CommunityItemWrapper>
                  <CommunityItem
                    key={item.postId + 'item' + i}
                    {...item}
                    menuOpenId={menuOpenId}
                    setMenuOpenId={setMenuOpenId}
                  />
                </CommunityItemWrapper>
                <Line isLast={i === data.length - 1} />
              </RelativeDiv>
            ))}
            <div ref={lastRef} style={{ height: '10px' }}></div>
          </>
        )}
      </Container>
    </>
  );
};
export default CommunityPage;

const PostAddButton = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  border: 1px solid white;
  padding: 8px 10px;
  border-radius: 6.5px;
  font-size: 1.5rem;
  cursor: pointer;
`;
const CommunityItemWrapper = styled.div`
  cursor: pointer;
`;
