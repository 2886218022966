import PageHeader from 'components/PageHeader';
import { CenterType, ProfileType } from 'constants/types/type';

import * as S from './style';
import { useEffect, useState } from 'react';
import CenterProfileModal from 'components/CenterProfileModal';
import { deviceSizesNum } from 'constants/theme';
import SwiperBannerImage from 'components/CenterDetail/SwiperBannerImage';
import Badge from 'components/CenterDetail/Badge';
import Captions from 'components/CenterDetail/Captions';
import Staffs from 'components/CenterDetail/Staffs';
import Location from 'components/CenterDetail/Location';
import PreviewSessions from 'components/CenterDetail/PreviewSessions';
import SignupButton from 'components/CenterDetail/SignupButton';
import { useTranslation } from 'react-i18next';

type CenterDetailBeforeSignupType = {
  center: CenterType;
};
const CenterDetailBeforeSignup = ({ center }: CenterDetailBeforeSignupType) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [bannerImages, setBannerImages] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!center) {
      alert(t('존재하지 않는 센터입니다'));
      window.history.back();
    }

    setBannerImages([...center.bannerImages]);
    if (
      window.innerWidth > deviceSizesNum.tablet &&
      center.bannerImages.length <= 1
    ) {
      setBannerImages([...center.bannerImages, ...center.bannerImages]);
    }
  }, [center]);

  let delay = 100;
  let timer: any = null;
  window.addEventListener('resize', () => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      if (
        window.innerWidth > deviceSizesNum.tablet &&
        center.bannerImages.length <= 1 &&
        bannerImages.length < 2
      ) {
        setBannerImages([...bannerImages, ...bannerImages]);
      } else if (
        window.innerWidth < deviceSizesNum.tablet &&
        center.bannerImages.length <= 1
      ) {
        setBannerImages([...center.bannerImages]);
      } else {
        setBannerImages([...bannerImages]);
      }
    }, delay);
  });
  return (
    <>
      <PageHeader title='' isLineShow={false} backgroundColor='transparent' />
      <SwiperBannerImage bannerImages={bannerImages} />
      <S.Container>
        {/* 뱃지 */}
        <Badge centerGrade={center.centerGrade} />

        {/* 센터명 */}
        <S.CenterName>{center.displayName}</S.CenterName>

        {/* 캡션 */}
        {center.captions && <Captions captions={center.captions} />}

        {/* 스태프 */}
        {center.staffs && <Staffs staffs={center.staffs} />}

        {/* 소개 */}
        <S.Title>{t('소개')}</S.Title>
        <S.Desc>{center.introduce.text}</S.Desc>

        {/* 센터 위치 */}
        <Location
          latitude={center.location.latitude}
          longitude={center.location.longitude}
        />

        {/* 온라인 세션 */}
        <PreviewSessions previewSessions={center.previewSessions} />

        {/* 가입 버튼 */}
        <SignupButton setIsModalOpen={setIsModalOpen} />
      </S.Container>

      {/* 가입 모달 */}
      {isModalOpen && (
        <CenterProfileModal setIsModalOpen={setIsModalOpen} mode='signup' />
      )}
    </>
  );
};

export default CenterDetailBeforeSignup;
