import { postCenterMemberList } from 'apis/center';
import CenterProfileModal from 'components/CenterProfileModal';
import Icon from 'components/Icon';
import Img from 'components/Img';
import { DEFAULT_ALERT_TEXT } from 'constants/alertText';
import { compareObjects } from 'constants/common';
import { ProfileSample, ProfileType } from 'constants/types/type';
import { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useProfileStore } from 'store/auth';
import { ProfileImageWrapper } from 'styles/style';
import * as S from './style';

const Member = () => {
  const [staffList, setstaffList] = useState<ProfileType[]>([]);
  const [memberList, setMemberList] = useState<ProfileType[]>([]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const centerId = searchParams.get('centerId');

  const [isPending, setIsPending] = useState<boolean>(true);

  const [clickedProfile, setClickedProfile] =
    useState<ProfileType>(ProfileSample);

  const { profile: store_profile, setProfile: store_setProfile } =
    useProfileStore();

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [isModalOpen, setIsModalOpen]);

  const getData = async () => {
    try {
      if (!centerId) {
        return;
      }
      setIsPending(true);
      const { myProfile, stafflist, memlist } = await postCenterMemberList({
        params: {
          centerId,
        },
      });
      if (myProfile) {
        store_setProfile(myProfile);
      }
      if (stafflist?.length > 0) {
        const activatedList = stafflist.filter(
          (profile: ProfileType) => profile.permissionState === 'activated'
        );
        setstaffList(activatedList);
      }
      if (memlist?.length > 0) {
        const activatedList = memlist.filter(
          (profile: ProfileType) => profile.permissionState === 'activated'
        );
        setMemberList(activatedList);
      }
      setIsPending(false);
    } catch (e) {
      alert('멤버 리스트를 불러오는데 ' + DEFAULT_ALERT_TEXT);
      console.error(e);
    }
  };
  const { t } = useTranslation();

  return (
    <>
      <S.Container>
        <S.Section>
          <S.Title>{t('내 프로필')}</S.Title>
          <ProfileItem
            profile={store_profile}
            isMyProfile={true}
            setIsModalOpen={setIsModalOpen}
            setClickedProfile={setClickedProfile}
          />
        </S.Section>
        <S.Section>
          <S.Title>{t('스태프')}</S.Title>
          {staffList.length < 1 && !isPending ? (
            <S.NotItemDiv>{t('등록된 스태프가 없습니다')}</S.NotItemDiv>
          ) : (
            staffList.map((profile: ProfileType, index: number) => (
              <ProfileItem
                key={profile.userKey + index}
                profile={profile}
                setIsModalOpen={setIsModalOpen}
                setClickedProfile={setClickedProfile}
              />
            ))
          )}
        </S.Section>
        <S.Section>
          <S.Title>{t('멤버')}</S.Title>
          {memberList.length < 1 ? (
            <S.NotItemDiv>{t('등록된 멤버가 없습니다')}</S.NotItemDiv>
          ) : (
            memberList.map((profile: ProfileType, index: number) => (
              <ProfileItem
                key={profile.userKey + index}
                profile={profile}
                setIsModalOpen={setIsModalOpen}
                setClickedProfile={setClickedProfile}
              />
            ))
          )}
        </S.Section>
      </S.Container>
      {isModalOpen && (
        <CenterProfileModal
          profile={clickedProfile}
          setIsModalOpen={setIsModalOpen}
          mode={compareObjects(clickedProfile, store_profile) ? 'edit' : 'view'}
        />
      )}
    </>
  );
};

type ProfileItemType = {
  profile: ProfileType;
  isMyProfile?: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setClickedProfile: React.Dispatch<React.SetStateAction<ProfileType>>;
};
const ProfileItem = ({
  profile,
  isMyProfile,
  setIsModalOpen,
  setClickedProfile,
}: ProfileItemType) => {
  const imageClickHandler = () => {
    setIsModalOpen && setIsModalOpen(true);
    setClickedProfile && setClickedProfile(profile);
  };
  const { t } = useTranslation();

  return (
    <>
      <S.ProfileContainer>
        {/* <CircleProfileImage
          size='36px'
          backgroundImage={profile.profileImage?.mediumImage}
          cursor={isMyProfile ? 'default' : 'pointer'}
          onClick={() => {
            if (!isMyProfile) imageClickHandler();
          }}
        /> */}
        <ProfileImageWrapper
          size='36px'
          cursor={isMyProfile ? 'default' : 'pointer'}
          onClick={() => {
            if (!isMyProfile) imageClickHandler();
          }}
        >
          <Img src={profile.profileImage?.mediumImage} alt='profileImage' />
        </ProfileImageWrapper>
        <S.InfoContainer>
          <S.Name>{profile?.nickname}</S.Name>
          <S.Desc>{`${t('가입일')} ${profile.joinDate}`}</S.Desc>
        </S.InfoContainer>
        {isMyProfile && (
          <S.EditButton onClick={() => imageClickHandler()}>
            <Icon icon='Pen' />
            <span>{t('프로필 수정')}</span>
          </S.EditButton>
        )}
      </S.ProfileContainer>
    </>
  );
};
export default Member;
