import { deviceSizes } from 'constants/theme';
import styled from 'styled-components';

type ContainerType = {
  backgroundColor?: string;
};
export const Container = styled.div<ContainerType>`
  position: fixed;
  width: 100%;
  max-width: ${deviceSizes.laptop};
  min-width: ${deviceSizes.mobile};
  height: 60px;
  z-index: 9999;
  background-color: ${({ backgroundColor }) => backgroundColor || 'black'};
  border-bottom: ${({ backgroundColor }) =>
    backgroundColor || '1px solid var(--c900)'};

  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
`;
export const Title = styled.div`
  font-size: 1.3rem;
  font-weight: 600;
`;
export const BackIconWrapper = styled.div`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const HomeIconWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const LineWrapper = styled.div`
  position: relative;
`;
export const Line = styled.div`
  position: absolute;
  left: -30px;
  width: 120%;
  height: 1px;
  background-color: #24252d;
`;
export const CircleProfileImageWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;
