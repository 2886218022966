import styled from 'styled-components';
export const Container = styled.div`
  height: 100%;
  flex-direction: column;
  flex: 1;
  padding: 0 0 30px 0;
  overflow: auto;
  min-width: 400px;

  @media ${`screen and (max-width: 800px)`} {
    overflow: visible; //default
    flex: 1;
    min-width: initial;
  }
`;
export const TopBar = styled.div`
  background-color: #373942;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
`;
export const TopBarItem = styled.div`
  display: flex;
  font-size: 1.2rem;
  color: var(--c300);
  gap: 7px;
  align-items: center;
`;
export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 1.2rem;
  flex-shrink: 0;
`;
export const ProfileImageWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  overflow: hidden;
`;
export const CommentBox = styled.div`
  padding: 15px 21px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom: 1px solid var(--c800);
  :last-child {
    padding-bottom: 0px;
    border-bottom: none;
  }
`;
export const Content = styled.div<{ isDeleted: boolean }>`
  font-size: 1.4rem;
  color: ${({ isDeleted }) => (isDeleted ? 'var(--c500)' : 'white')};
`;
export const StateContainer = styled.div`
  display: flex;
  gap: 13px;
  align-items: center;
`;
export const StateItem = styled.div`
  display: flex;
  gap: 5px;
  font-size: 1.2rem;
  align-items: center;
  color: var(--c300);
`;
export const SeeMoreBox = styled.div`
  border-top: 1px solid var(--c800);
  font-size: 1.2rem;
  color: var(--c500);
  padding-top: 11px;
  padding-left: 15px;
  cursor: pointer;
`;
export const AddCommentContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 15px 21px;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid var(--c700);
`;
export const TextArea = styled.textarea`
  height: 20px;
  overflow: hidden;
  width: 100%;
  resize: none;
  padding: 2px 5px 5px 5px;
  color: white;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  :focus {
    outline: none;
  }
`;
export const AddCommentButton = styled.button<{
  isActive: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: ${({ isActive }) => (isActive ? 'var(--primary)' : 'var(--c500)')};
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: -0.5px;
  margin-top: 3px;
  white-space: nowrap;
  cursor: pointer;
`;
export const ButtonWrapper = styled.div`
  cursor: pointer;
`;

export const Button = styled.div`
  color: black;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 27px;
  border-radius: 4px;
  font-weight: bold;
  color: white;
  cursor: pointer;
`;
export const GreenButton = styled(Button)<{ isActive: boolean }>`
  background-color: #029c02;
  transition: all 1s;
  opacity: ${({ isActive }) => (isActive ? '100%' : '50%')};
`;
export const GrayButton = styled(Button)`
  background-color: var(--c400);
`;
