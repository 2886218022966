const Noti_Post = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <path
      d='M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 11.2347 2.79834 12.3997 3.32688 13.4268C3.57355 13.9062 3.64953 14.466 3.45851 14.9702L2.7138 16.9357C2.61052 17.2083 2.81192 17.5 3.10344 17.5H10Z'
      stroke='#D3D4DC'
    />
  </svg>
);
export default Noti_Post;
