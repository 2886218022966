import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 120px;
  height: auto;
  min-height: 120px;
  top: 16px;
  right: 20px;
  display: flex;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 15px;
  z-index: 999;
`;
export const TimeSpan = styled.span`
  font-family: SF Pro Text;
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 33.41px;
  letter-spacing: -0.5px;
  text-align: center;
  color: white;
  margin-bottom: 12px;
  white-space: nowrap;
`;
export const SceneTitleSpan = styled.span`
  font-family: SF Pro Text;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 14.32px;
  letter-spacing: -0.5px;
  margin-bottom: 10px;
`;
