import * as S from './style';
import { getMMSSFormat } from 'constants/video';
import { useNavigate } from 'react-router-dom';
import SessionCard from 'components/SessionCard';

type SessionSlideType = {
  items: any[];
};
const SessionSlide = ({ items }: SessionSlideType) => {
  const navigate = useNavigate();

  return (
    <S.Container>
      {items &&
        items.map((item: any, index) => (
          <SessionCard session={item} key={item.sessionId + index} />
        ))}
    </S.Container>
  );
};

export default SessionSlide;
