import CompleteIconText from 'components/CompleteIconText';
import { theme } from 'constants/theme';
import { SessionType } from 'constants/types/type';
import { getMMSSFormat } from 'constants/video';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PremiumBadge } from 'styles/style';

type SessionCard = {
  session: SessionType;
};
const SessionCard = ({ session }: SessionCard) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Background
      onClick={() =>
        navigate(
          `/session/detail?sessionId=${session.sessionId}&centerId=${session.centerId}`
        )
      }
      backgroundImage={session?.displayImage?.largeImage}
    >
      <TopContainer>
        <LeftItem>
          {session.authority === 'member_only' && (
            <PremiumBadge>{t('회원전용')}</PremiumBadge>
          )}
        </LeftItem>
        <RightItem>
          {session.isSessionCompleted && <CompleteIconText />}
        </RightItem>
      </TopContainer>
      <DescContainer>
        <Title>{session.title}</Title>
        <MetaText>
          {session.uploadUser?.nickname != '' &&
            `${session.uploadUser?.nickname} • `}
          {getMMSSFormat(session.video?.duration)}
        </MetaText>
      </DescContainer>
    </Background>
  );
};

export default SessionCard;

const TopContainer = styled.div`
  width: 100%;
  position: relative;
`;
const LeftItem = styled.div`
  position: absolute;
  left: 0;
`;
const RightItem = styled.div`
  position: absolute;
  right: 0;
`;

type ContainerType = {
  backgroundImage: string;
};
const Background = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 192px;
  min-width: 192px;
  height: 342px;

  border-radius: 8px;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.4)),
    url(${({ backgroundImage }) =>
      backgroundImage ? backgroundImage : '/loading.gif'});
  background-size: ${({ backgroundImage }) =>
    backgroundImage ? 'cover' : '25px 25px'};

  background-position: center;

  padding: 19px;

  cursor: pointer;

  @media ${theme.tablet} {
    width: 160px;
    min-width: 160px;
    height: 285px;
  }
`;
export const DescContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 6px;
`;
export const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  word-break: keep-all;
`;
export const MetaText = styled.span`
  font-size: 12px;
  color: var(--c300);
  word-break: keep-all;
`;
