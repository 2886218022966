import { theme } from 'constants/theme';
import { ReactNode } from 'react';
import styled from 'styled-components';

type AspectRatioBoxType = {
  children: ReactNode;
  ratio: number;
};
const AspectRatioBox = ({ children, ratio }: AspectRatioBoxType) => {
  return (
    <Wrapper ratio={ratio}>
      <Item>{children}</Item>
    </Wrapper>
  );
};
export default AspectRatioBox;

type WrapperType = {
  ratio: number;
};
const Wrapper = styled.div<WrapperType>`
  position: relative;
  height: 0;
  padding-bottom: ${({ ratio }) => (ratio - 0) * 100 + '%'};
`;
const Item = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
