import { postCenterHome } from 'apis/center';
import CommunityItem from 'components/CommunityItem';
import FeedbackItem from 'components/FeedbackItem';
import SessionView from 'components/SessionView';
import { CENTER_NOTFOUND_ALERT_TEXT } from 'constants/alertText';
import {
  FeedbackType,
  PostType,
  ProfileType,
  SessionType,
} from 'constants/types/type';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useHandleScroll from 'utils/useHandleScroll';
import { FaPlus } from 'react-icons/fa6';
import * as S from './style';
import { useProfileStore } from 'store/auth';
import { useTranslation } from 'react-i18next';

type homeItemType = {
  type: 'post' | 'session' | 'feedback';
  id: string;
  createdAt: string;
  index: number;
};

const All = () => {
  const navigate = useNavigate();

  const [offset, setOffset] = useState<number>(0);

  const [homeList, setHomeList] = useState<homeItemType[]>([]);
  const [postList, setPostList] = useState<PostType[]>([]);
  const [feedbackList, setFeedbackList] = useState<FeedbackType[]>([]);
  const [sessionList, setSessionList] = useState<SessionType[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const centerId = searchParams.get('centerId');

  const [scrollActive, setScrollActive] = useState<boolean>(true);

  const [isPending, setIsPending] = useState<boolean>(true);

  useEffect(() => {
    if (centerId) {
      getData();
    } else {
      alert(CENTER_NOTFOUND_ALERT_TEXT);
    }
  }, [offset]);
  const getData = async () => {
    if (!centerId) {
      return;
    }
    setIsPending(true);
    const { homeList, feedback_map, post_map, session_map, listInfo } =
      await postCenterHome({
        params: {
          centerId,
        },
        data: {
          limit: 10,
          offset,
          count: 10,
        },
      });

    if (homeList?.length > 0 && offset === 0) {
      setHomeList(homeList);
      setPostList(post_map);
      setFeedbackList(feedback_map);
      setSessionList(session_map);
    } else if (homeList.length > 0) {
      setHomeList((prevList) => [...prevList, ...homeList]);
      setPostList((prevList) => [...prevList, ...post_map]);
      setFeedbackList((prevList) => [...prevList, ...feedback_map]);
      setSessionList((prevList) => [...prevList, ...session_map]);
    } else {
      setScrollActive(false);
    }
    setIsPending(false);
  };

  useHandleScroll(setOffset, 5, scrollActive);

  const { t } = useTranslation();

  return (
    <>
      <S.TopBar>
        <S.WriteButton
          onClick={() => navigate(`/center/community/add?centerId=${centerId}`)}
        >
          <FaPlus size={10} color='white' /> <span>{t('새 글쓰기')}</span>
        </S.WriteButton>
      </S.TopBar>
      {homeList.length < 1 && !isPending ? (
        <S.NoListDiv>내용이 없습니다</S.NoListDiv>
      ) : (
        homeList.map((homeItem, index) => {
          return (
            <div key={homeItem.id + index}>
              {homeItem.type === 'post' && (
                <Community postList={postList} homeItem={homeItem} />
              )}
              {homeItem.type === 'feedback' && (
                <Feedback feedbackList={feedbackList} homeItem={homeItem} />
              )}
              {homeItem.type === 'session' && (
                <Session sessionList={sessionList} homeItem={homeItem} />
              )}
            </div>
          );
        })
      )}
    </>
  );
};

type CommunityType = {
  postList: PostType[];
  homeItem: homeItemType;
};
const Community = ({ postList, homeItem }: CommunityType) => {
  const post = postList.find((post: PostType) => homeItem.id === post.postId);
  return <>{post && <CommunityItem {...post} />}</>;
};

type _FeedbackType = {
  feedbackList: FeedbackType[];
  homeItem: homeItemType;
};
const Feedback = ({ feedbackList, homeItem }: _FeedbackType) => {
  const { profile, setProfile } = useProfileStore();

  const feedback = feedbackList.find(
    (feedback: FeedbackType) => homeItem.id === feedback.feedbackId
  );

  return (
    <>
      {feedback && (
        <FeedbackItem
          feedback={feedback}
          role={profile.role}
          title={feedback.requestedBy?.nickname}
          circleImage={feedback.requestedBy?.profileImage?.mediumImage}
        />
      )}
    </>
  );
};

type _SessionType = {
  sessionList: SessionType[];
  homeItem: homeItemType;
};
const Session = ({ sessionList, homeItem }: _SessionType) => {
  const session = sessionList.find(
    (session: SessionType) => homeItem.id === session.sessionId
  );
  return <>{session && <SessionView session={session} />}</>;
};

export default All;
