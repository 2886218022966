import React, { useEffect, useRef, useState } from "react";
import { Flex } from "styles/style";

declare global {
  interface Window {
    kakao: any;
  }
}

const KaKaoMap: React.FC = () => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<any>(null);
  const [address, setAddress] = useState("");

  const script = document.createElement("script");
  script.src =
    "//dapi.kakao.com/v2/maps/sdk.js?appkey=84e1c459f31b318cab45a0a83e1d85ae&autoload=false&libraries=services";
  script.async = true;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = event;
    setAddress(value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (map) {
      const geocoder = new window.kakao.maps.services.Geocoder();
      geocoder.addressSearch(address, function (result: any, status: any) {
        if (status === window.kakao.maps.services.Status.OK) {
          const coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);

          // 기존에 표시된 마커 제거
          for (let i = 0; i < map.markers.length; i++) {
            map.markers[i].setMap(null);
          }
          map.markers = [];

          const marker = new window.kakao.maps.Marker({
            map: map,
            position: coords,
          });
          map.markers.push(marker);

          map.setCenter(coords);
        }
      });
    }
  };

  useEffect(() => {
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      window.kakao.maps.load(function () {
        const mapContainer = mapRef.current;
        const mapOption = {
          center: new window.kakao.maps.LatLng(33.450701, 126.570667),
          level: 3,
        };

        const map = new window.kakao.maps.Map(mapContainer, mapOption);
        map.markers = [];
        setMap(map);
      });
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Flex flexDirection="row" margin="0 0 50px 0">
        <form onSubmit={onSubmit}>
          <input id="address" onChange={onChange} />
          <button type="submit">제출</button>
        </form>
      </Flex>
      <p style={{ marginTop: "-12px" }}>
        <em className="link">
          <a
            href="javascript:void(0);"
            onClick={() =>
              window.open(
                "http://fiy.daum.net/fiy/map/CsGeneral.daum",
                "_blank",
                "width=981, height=650"
              )
            }
          ></a>
        </em>
      </p>
      <div
        ref={mapRef}
        id="map"
        style={{ width: "100%", height: "350px" }}
      ></div>
    </>
  );
};

export default KaKaoMap;
