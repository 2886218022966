import CenterProfileModal from 'components/CenterProfileModal';
import Img from 'components/Img';
import { compareObjects } from 'constants/common';
import { ProfileSample, ProfileType } from 'constants/types/type';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileImageWrapper } from 'styles/style';
import * as S from './style';

type StaffsType = {
  staffs: ProfileType[];
};
const Staffs = ({ staffs }: StaffsType) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [clickedProfile, setClickedProfile] =
    useState<ProfileType>(ProfileSample);

  const imageClickHandler = (profile: ProfileType) => {
    setIsModalOpen && setIsModalOpen(true);
    setClickedProfile && setClickedProfile(profile);
  };
  const { t } = useTranslation();

  return (
    <>
      <S.Title>{t('스태프')}</S.Title>
      <S.StaffsContainer>
        {staffs.length > 0 &&
          staffs.map((staff, index) => (
            <ProfileImageWrapper
              key={index + staff.userKey}
              size='65px'
              cursor='pointer'
              onClick={() => imageClickHandler(staff)}
            >
              <Img src={staff?.profileImage?.largeImage} alt='profileImage' />
            </ProfileImageWrapper>
          ))}
      </S.StaffsContainer>
      {isModalOpen && (
        <CenterProfileModal
          profile={clickedProfile}
          setIsModalOpen={setIsModalOpen}
          mode='view'
        />
      )}
    </>
  );
};
export default Staffs;
