import {
  Title,
  Container,
  Contents,
  TermBody,
  TermTitle,
  TermBox,
  Dl,
  Dt,
  Dd,
  Table,
  Th,
  Td,
} from 'styles/TermStyle';
import { Margin } from 'styles/style';
import { CloseButtonOnTerms } from 'components/CloseButtonOnTerms';

export const Term3_en = () => {
  return (
    <Container>
      <CloseButtonOnTerms />
      <Title>Real-Feed E-mail and SMS Marketing Information Consent</Title>
      <Margin margin='0 0 20px 0' />
      <Dd>
        Thank you for using Real-Feed. We would like to provide you with
        advertising and promotional information. If you would like to receive
        marketing information, please review the following and provide your
        consent.
      </Dd>
      <Margin margin='0 0 20px 0' />
      <TermBox>
        <Dl>
          <Dt>1. Purpose of Using Marketing Information</Dt>
          <Dd>
            Spanlink Co., Ltd. provides users with customized services,
            newsletters, notifications about new features, and information on
            various promotional events and sweepstakes via email, postal mail,
            SMS, or push notifications. Regardless of your opt-out from
            receiving emails or SMS, emails that must be sent for purposes other
            than advertising or profit-making, such as policy changes or
            important service updates, will be sent as required. You may refuse
            to consent to receiving marketing information and, even after
            consenting, you may withdraw your consent at any time according to
            your preferences. Refusing to consent will not restrict your use of
            services provided by Spanlink Co., Ltd. However, marketing
            information services, such as event notifications and personalized
            product recommendations, may be limited. If you have consented to
            receive emails but are not receiving them, please check the
            following: - Your email address may be incorrectly registered. - The
            email from our site may be marked as spam. - If the issue persists
            after checking these items, please contact customer service. Even if
            you change your preference to 'opt-out,' scheduled emails or SMS may
            still be sent for about a week due to pre-set delivery. We
            appreciate your understanding.
          </Dd>
          <Dt>2. Disadvantages of Not Consenting</Dt>
          <Dd>
            Under Article 22(5) of the Personal Information Protection Act,
            refusal to consent to optional information will not restrict your
            use of the service. However, marketing information services, such as
            event notifications and personalized product recommendations, may be
            limited.
          </Dd>
          <Dt>3. Withdrawal of Service Information Consent</Dt>
          <Dd>
            If you do not wish to receive marketing information provided by
            Spanlink Co., Ltd., you can request a withdrawal of your consent by
            contacting admin@whistle-pro.com.
          </Dd>
        </Dl>
      </TermBox>
    </Container>
  );
};
