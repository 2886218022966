const START_text = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='117'
    height='27'
    viewBox='0 0 117 27'
    fill='none'
  >
    <path
      d='M11.2041 26.6665C5.05176 26.6665 1.18945 23.8638 0.933105 19.2837L0.916016 18.9761H6.89746L6.91455 19.1299C7.08545 20.6509 8.77734 21.7446 11.3066 21.7446C13.7505 21.7446 15.3569 20.6338 15.3569 19.0615V19.0444C15.3569 17.6602 14.2632 17.0107 11.375 16.4468L8.82861 15.9512C3.61621 14.9429 1.36035 12.4819 1.36035 8.63672V8.61963C1.37744 3.7832 5.479 0.672852 11.1528 0.672852C17.3394 0.672852 20.7061 3.80029 21.082 8.1582L21.0991 8.36328H15.2886L15.2544 8.1582C14.998 6.62012 13.46 5.59473 11.187 5.59473C8.93115 5.59473 7.59814 6.60303 7.59814 8.07275V8.08984C7.59814 9.49121 8.72607 10.2603 11.3921 10.79L13.9385 11.2856C19.1851 12.311 21.5776 14.4131 21.5776 18.395V18.4121C21.5776 23.4536 17.6982 26.6665 11.2041 26.6665ZM31.5879 26V6.38086H24.4785V1.33936H44.9692V6.38086H37.8428V26H31.5879ZM44.7598 26L53.0483 1.33936H60.7217L69.0103 26H62.4307L60.9097 20.5825H52.8604L51.3394 26H44.7598ZM56.7227 6.77393L54.125 16.0708H59.645L57.0474 6.77393H56.7227ZM72.646 26V1.33936H83.3613C89.4282 1.33936 92.6924 4.38135 92.6924 9.47412V9.5083C92.6924 12.5161 91.1714 15.2676 88.5054 16.4468L93.4272 26H86.3862L82.165 17.3696H78.918V26H72.646ZM78.918 13.0801H82.6606C84.9165 13.0801 86.3008 11.73 86.3008 9.59375V9.55957C86.3008 7.47461 84.8823 6.07324 82.6265 6.07324H78.918V13.0801ZM102.959 26V6.38086H95.8496V1.33936H116.34V6.38086H109.214V26H102.959Z'
      fill='black'
    />
  </svg>
);

export default START_text;
