import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
type ItemContainerType = {
  backgroundImage: string;
};
export const ItemContainer = styled.div<ItemContainerType>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.4)),
    url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  padding: 15px;

  align-items: flex-start;
  justify-content: flex-end;

  cursor: pointer;
`;
export const Title = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 8px;
`;
export const Desc = styled.span`
  font-size: 1.4rem;
  margin-top: 8px;
`;
