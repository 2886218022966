import styled from 'styled-components';
export const Outer = styled.div`
  overflow: hidden;
`;
export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
  max-width: 100vw !important;
  background-color: black;
  margin: 0 auto;
  cursor: pointer;
`;
export const ControlsContainer = styled.div`
  position: absolute;
  bottom: 35px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 20px;
  justify-content: right;

  width: 100%;
`;
export const ConvertButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;
export const GoFeedbackBox = styled.div`
  max-width: 460px;
  width: 85%;
  font-family: SF Pro Text;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--c800);
  color: white;
  height: auto;
  padding: 32px 30px 27px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  z-index: 99999;
  > span {
    width: 100%;
    font-size: 1.3rem;
    text-align: center;
    line-height: 2rem;
  }
`;
export const GoFeedbackButton = styled.div`
  font-family: SF Pro Text;
  background-color: var(--primary);
  width: 100%;
  color: black;
  height: 36px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: 700;
  font-size: 1.1rem;
  gap: 10px;
  cursor: pointer;
`;
export const StopIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
