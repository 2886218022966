import client from './client';
import { withErrorHandling } from './errorHandling';

// 센터 정보
type postCenterViewType = {
  params: {
    centerId: string;
  };
};
const postCenterViewRaw = ({ params }: postCenterViewType) =>
  client.get(`/api/center/view/${params.centerId}`);
export const postCenterView = withErrorHandling(postCenterViewRaw);

// 새로운 센터 정보 저장
type getCenterDataType = {
  centerId: string;
  setCenter: any;
  setProfile: any;
};
export const getCenterData = async ({
  centerId,
  setCenter,
  setProfile,
}: getCenterDataType) => {
  try {
    const response = await postCenterView({ params: { centerId } });
    const { center, watchingProfile } = response;
    setCenter(center);
    setProfile(watchingProfile);
  } catch (e) {
    console.error(e);
  }
};

// 센터 생성
type postCreateCenterType = {
  bannerImages: { [key: string]: any };
  businessRegImage: string;
  displayName: string;
  fullAddress: string;
  introduce: string;
  latitude: string | null;
  longitude: string | null;
  centerPhone: string;
  term_permissions: { [key: string]: boolean };
  ownerName: string;
  ownerPhone: string;
  regNumber: string;
  sportsTags: string[];
};
const postCreateCenterRaw = (data: postCreateCenterType) =>
  client.post('/api/center/create', data);
export const postCreateCenter = withErrorHandling(postCreateCenterRaw);

// 센터 수정
type postUpdateCenterType = {
  params: {
    centerId: string;
  };
  data: {
    bannerImages: { [key: string]: any };
    deleteBannerImages: any;
    businessRegImage: string | null;
    displayName: string;
    fullAddress: string;
    introduce: string;
    latitude: string | null;
    longitude: string | null;
    centerPhone: string;
    ownerName: string;
    ownerPhone: string;
    regNumber: string;
    sportsTags: string[];
    deleteSportsTags: string[];
    term_permissions: {
      [key: string]: boolean;
    };
  };
};
const postUpdateCenterRaw = ({ params, data }: postUpdateCenterType) =>
  client.post(`/api/center/${params.centerId}/update`, data);
export const postUpdateCenter = withErrorHandling(postUpdateCenterRaw);

// 센터 수정(accept 후)
type postUpdateAcceptCenterType = {
  params: {
    centerId: string;
  };
  data: {
    bannerImages: { [key: string]: any };
    deleteBannerImages: any;
    displayName: string;
    fullAddress: string;
    introduce: string;
    latitude: string | null;
    longitude: string | null;
    centerPhone: string;
    sportsTagIds: string[];
    deleteSportsTagIds: string[];
    captions: {
      captionsNo?: string | undefined;
      text: string;
      iconId: string;
    }[];
    deleteCaptions: any[];
    term_permissions: {
      [key: string]: boolean;
    };
  };
};
const postUpdateAcceptCenterRaw = ({
  params,
  data,
}: postUpdateAcceptCenterType) =>
  client.post(`/api/center/accept/${params.centerId}/update`, data);
export const postUpdateAcceptCenter = withErrorHandling(
  postUpdateAcceptCenterRaw
);

// 센터 검색
type postSearchCenterListType = {
  isOpenStatus: string;
  limit: number;
  offset: number;
  orderBy: string;
};
const postSearchCenterListRaw = (data: postSearchCenterListType) =>
  client.post('/api/center/search/list', data);
export const postSearchCenterList = withErrorHandling(postSearchCenterListRaw);

// 센터 신청 취소
type postCancelCenterType = {
  params: {
    centerId: string;
  };
};
const postCancelCenterRaw = ({ params }: postCancelCenterType) =>
  client.post(`/api/center/${params.centerId}/cancel`);
export const postCancelCenter = withErrorHandling(postCancelCenterRaw);

// 센터 회원 삭제
type postDeleteMemberType = {
  params: {
    accountId: string;
  };
  data: {
    centerId: string;
    connRegStatus: string;
  };
};
const postDeleteMemberRaw = ({ params, data }: postDeleteMemberType) =>
  client.post(`/api/center/member/${params.accountId}/delete`, data);
export const postDeleteMember = withErrorHandling(postDeleteMemberRaw);

// 센터 - 홈
type postCenterHomeType = {
  params: {
    centerId: string;
  };
  data: {
    limit: number;
    offset: number;
    count: number;
  };
};
const postCenterHomeRaw = ({ params, data }: postCenterHomeType) =>
  client.post(`/api/center/home/${params.centerId}`, data);
export const postCenterHome = withErrorHandling(postCenterHomeRaw);

// 센터 멤버 가져오기
type postCenterMemberListType = {
  params: {
    centerId: string;
  };
};
const postCenterMemberListRaw = ({ params }: postCenterMemberListType) =>
  client.post(`/api/app/center/${params.centerId}/memberlist`);
export const postCenterMemberList = withErrorHandling(postCenterMemberListRaw);

// 센터 가입하기(연결) 기능 - 유저
export type profileQueryDataType = {
  centerId: string;
  profileImage: string;
  nickname: string;
};
const postSignupCenterRaw = (data: profileQueryDataType) =>
  client.post(`/api/center/conn-complete`, data);
export const postSignupCenter = withErrorHandling(postSignupCenterRaw);

// 센터 프로필 수정하기
const postUpdateProfileRaw = (data: profileQueryDataType) =>
  client.post(`/api/center/profile/update`, data);
export const postUpdateProfile = withErrorHandling(postUpdateProfileRaw);

// 센터 스태프 프로필 수정하기
const postUpdateStaffProfileRaw = (data: profileQueryDataType) =>
  client.post(`/api/center/profileUpdate`, data);
export const postUpdateStaffProfile = withErrorHandling(
  postUpdateStaffProfileRaw
);
