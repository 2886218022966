import AspectRatioBox from 'components/AspectRatioBox';
import Swiper, { Slide } from 'components/Swiper';
import { breakpoints_int } from 'constants/swiper_breakpoints';
import { ImageType } from 'constants/types/type';
import * as S from './style';

type SwiperBannerImageType = {
  bannerImages: ImageType[];
};
const SwiperBannerImage = ({ bannerImages }: SwiperBannerImageType) => {
  return (
    <Swiper
      breakpoints={breakpoints_int}
      loop={false}
      autoplay={false}
      pagination={true}
    >
      {bannerImages.map((image, index) => {
        return (
          <Slide key={index}>
            <AspectRatioBox ratio={0.75}>
              <S.BannerImageWrapper backgroundImage={image.largeImage} />
            </AspectRatioBox>
          </Slide>
        );
      })}
    </Swiper>
  );
};
export default SwiperBannerImage;
