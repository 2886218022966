import { postCenterView } from 'apis/center';
import CenterDetailAfterSignup from 'components/CenterDetailAfterSignup';
import CenterDetailBeforeSignup from 'components/CenterDetailBeforeSignup';
import Loading from 'components/Loading';
import {
  CENTER_NOTFOUND_ALERT_TEXT,
  DEFAULT_ALERT_TEXT,
  PROFILE_NOTFOUND_ALERT_TEXT,
} from 'constants/alertText';
import { CenterType, ProfileType } from 'constants/types/type';
import { saveLocaStorage } from 'constants/useLocalStorage';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useProfileStore } from 'store/auth';
import styled from 'styled-components';

const CenterMainPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const centerId = searchParams.get('centerId');

  const { profile: store_profile, setProfile: store_setProfile } =
    useProfileStore();

  const { data, error, isLoading } = useQuery({
    queryKey: ['centerView', centerId],
    queryFn: () =>
      postCenterView({
        params: {
          centerId: centerId || '',
        },
      }),
    enabled: !!centerId, // centerId가 있을 때만 요청 수행
  });

  useEffect(() => {
    if (data) {
      saveLocaStorage({
        targetName: 'recentActivity',
        data: data.center,
      });
      store_setProfile(data.watchingProfile);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      alert(DEFAULT_ALERT_TEXT);
      window.history.back();
    }
  }, [error]);

  const RenderedComponent = useMemo(() => {
    if (isLoading) {
      return <Loading num={2} />;
    }

    if (error || !data?.center) {
      return (
        <NotFound>
          {error ? DEFAULT_ALERT_TEXT : CENTER_NOTFOUND_ALERT_TEXT}
        </NotFound>
      );
    }

    const { center, watchingProfile } = data;
    const { role, permissionState } = watchingProfile || {};

    if (center && watchingProfile) {
      return role === 'guest' ||
        (role === 'member' && permissionState === 'pending') ? (
        <CenterDetailBeforeSignup center={center} />
      ) : (
        <CenterDetailAfterSignup center={center} />
      );
    }

    if (center && !watchingProfile) {
      return <CenterDetailBeforeSignup center={center} />;
    }

    return <NotFound>{CENTER_NOTFOUND_ALERT_TEXT}</NotFound>;
  }, [isLoading, error, data]);

  return RenderedComponent;
};

export default CenterMainPage;

const NotFound = styled.div`
  text-align: center;
  padding: 30px;
`;
