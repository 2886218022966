import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import {
  CenterType,
  AccountType,
  ProfileType,
  ProfileSample,
  StaffProfileType,
} from 'constants/types/type';

type useAccessTokenStoreState = {
  accessToken: string;
  setAccessToken: (input: string) => void;
};
type useRefreshTokenStoreState = {
  refreshToken: string;
  setRefreshToken: (input: string) => void;
};
type useAccountStoreState = {
  account: AccountType;
  setAccount: (input: AccountType) => void;
};
type useCenterStoreState = {
  center: CenterType;
  setCenter: (
    input: CenterType | ((prevState: CenterType) => CenterType)
  ) => void;
};
type useProfileStoreState = {
  profile: ProfileType | StaffProfileType;
  setProfile: (input: ProfileType) => void;
};
export const useAccessTokenStore = create<useAccessTokenStoreState>()(
  persist(
    (set) => ({
      accessToken: '',
      setAccessToken: (input: string) => set(() => ({ accessToken: input })),
    }),
    {
      name: 'accessToken',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
export const useRefreshTokenStore = create<useRefreshTokenStoreState>()(
  persist(
    (set) => ({
      refreshToken: '',
      setRefreshToken: (input: string) => set(() => ({ refreshToken: input })),
    }),
    {
      name: 'refreshToken',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const useProfileStore = create<useProfileStoreState>()(
  persist(
    (set) => ({
      profile: ProfileSample,
      setProfile: (input: ProfileType) => set(() => ({ profile: input })),
    }),
    {
      name: 'profile',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
export const useAccountStore = create<useAccountStoreState>()(
  persist(
    (set) => ({
      account: {
        userKey: '',
        phoneNumber: '', // masked
        username: '', // email @ 앞의 내용
        email: '',
        isAdult: false,
        createdAt: '',
        profileImage: {
          largeImage: '',
          mediumImage: '',
          originalImage: '',
          thumbnailImage: '',
        },
      },
      setAccount: (input: AccountType) => set(() => ({ account: input })),
    }),
    {
      name: 'account',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
export const useCenterStore = create<useCenterStoreState>()(
  persist(
    (set) => ({
      center: {
        centerId: '',
        displayName: '',
        staffs: [],
        centerMemCount: 0,
        centerGrade: 'free',
        hasNewBadge: false,
        previewSessions: [],
        introduce: {
          text: '',
        },
        location: {
          fullAddress: '',
          latitude: '',
          longitude: '',
        },
        captions: [],
        centerProfileImage: {
          thumbnailImage: '',
          largeImage: '',
          mediumImage: '',
          originalImage: '',
        },
        bannerIds: [],
        bannerImages: [],
        businessRegImage: {
          thumbnailImage: '',
          largeImage: '',
          mediumImage: '',
          originalImage: '',
        },
        sportsTagIds: [],
        ownerAccountId: '',
        ownerName: '',
        ownerPhone: '',
        activateState: 'pending',
        regNumber: '',
        isDeleted: false,
        startedAt: '',
        createdAt: '',
        approvedAt: '',
        modifiedAt: '',
        myProfile: {
          userKey: '',
          centerId: '',
          profileImage: {
            thumbnailImage: '',
            largeImage: '',
            mediumImage: '',
            originalImage: '',
          },
          nickname: '',
          role: 'guest',
          permissionState: 'pending',
          isAdult: false,
          isDeleted: false,
          createdAt: '',
          modifiedAt: '',
        },
        terms: [],
      },
      setCenter: (input) =>
        set((prevState) => {
          return {
            center:
              typeof input === 'function' ? input(prevState.center) : input,
          };
        }),
    }),
    {
      name: 'center',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
