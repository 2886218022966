import ProfileImage from 'components/ProfileImage';
import { getRoleDisplayName } from 'constants/common';
import { ProfileType } from 'constants/types/type';
import styled from 'styled-components';
import { Flex, Span } from 'styles/style';
type RowProfileBoxType = {
  profile: ProfileType;
  clickEvent?: any;
};
export const RowProfileBox = ({ profile, clickEvent }: RowProfileBoxType) => {
  return (
    <>
      <UserInfoContainer>
        <CircleImage
          onClick={() => clickEvent()}
          cursor={clickEvent && 'pointer'}
        >
          <ProfileImage
            profileImageUrl={profile.profileImage?.thumbnailImage}
          />
        </CircleImage>
        <Flex flexDirection='column' gap='3px'>
          <Span>{profile.nickname}</Span>
          <Span fontSize='14px' color='var(--c400)'>
            {getRoleDisplayName(profile.role)}
          </Span>
        </Flex>
      </UserInfoContainer>
    </>
  );
};

const UserInfoContainer = styled.div`
  display: flex;
  gap: 10px;
`;
type CircleImageType = {
  size?: string;
  cursor?: string;
};
export const CircleImage = styled.div<CircleImageType>`
  position: relative;
  width: ${({ size }) => size || '35px'};
  height: ${({ size }) => size || '35px'};
  border-radius: 100%;
  flex-shrink: 0;
  overflow: hidden;
  cursor: ${({ cursor }) => (cursor ? cursor : 'default')};
  > img {
    width: 100%;
    height: 100%;
  }
`;
