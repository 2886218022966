import Header from 'components/Header';
import PageHeader from 'components/PageHeader';
import SearchBox from 'components/Search/SearchBox';

const SearchPage = () => {
  return (
    <>
      <PageHeader title='' isLineShow={true} />
      <SearchBox />
    </>
  );
};

export default SearchPage;
