import { postMyActivity } from 'apis/myActivity';
import CenterViewSlide from 'components/CenterViewSlide';
import CommunityItem from 'components/CommunityItem';
import { Line } from 'components/FeedbackItem/style';
import FeedbackItemForUser from 'components/FeedbackItemForUser';
import SessionList from 'components/SessionSlide';
import SportsTagView from 'components/SportsTagView';
import { FeedbackType, PostType } from 'constants/types/type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Margin, RelativeDiv } from 'styles/style';
import * as S from './style';

const MyActivity = () => {
  const [offset, setOffset] = useState<number>(0);
  const [sections, setSections] = useState<any>([]);
  const [centers, setCenters] = useState<any>([]);
  const [feedbacks, setFeedbacks] = useState<any>([]);
  const [posts, setPosts] = useState<any>([]);
  const [sessions, setSessions] = useState<any>([]);
  const [menuOpenId, setMenuOpenId] = useState<string>('');
  const [sportsTags, setSportsTags] = useState<any>([]);

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const { centers, sessions, feedbacks, posts, sportsTags } =
      await postMyActivity({ offset, limit: 10, count: 10 });
    setCenters(centers);
    setSessions(sessions);
    setFeedbacks(feedbacks);
    setPosts(posts);
    setSportsTags(sportsTags);
  };
  const { t } = useTranslation();

  return (
    <>
      {feedbacks?.length > 0 && (
        <>
          <S.Title>{t('새로운 피드백 도착')}</S.Title>
          {feedbacks.map((feedback: FeedbackType) => {
            return (
              <>
                <FeedbackItemForUser
                  feedback={feedback}
                  circleImage={feedback.requestedBy?.profileImage?.mediumImage}
                />
                <S.Line />
              </>
            );
          })}
        </>
      )}
      {centers?.length > 0 && (
        <>
          <S.Title>{t('내가 가입한 센터')}</S.Title>
          <CenterViewSlide items={centers} />
          <Margin margin='0 0 40px 0' />
        </>
      )}
      {sessions?.length > 0 && (
        <>
          <S.Title>{t('내가 시청한 세션')}</S.Title>
          <SessionList items={sessions} />
          <Margin margin='0 0 40px 0' />
        </>
      )}
      {posts?.length > 0 && (
        <>
          <S.Title>{t('내가 참여한 게시글')}</S.Title>
          {posts.map((post: PostType, index: number) => {
            return (
              <RelativeDiv>
                <CommunityItem
                  {...post}
                  menuOpenId={menuOpenId}
                  setMenuOpenId={setMenuOpenId}
                />
                <Line isLast={index === posts.length - 1} />
              </RelativeDiv>
            );
          })}
        </>
      )}
      {/* {sportsTags?.length > 0 && (
        <>
          <SportsTagView items={sportsTags} />
          <Margin margin='0 0 50px 0' />
        </>
      )} */}
    </>
  );
};
export default MyActivity;
