import styled from 'styled-components';

export const PausedContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 9999;

  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  cursor: default;
`;
export const SessionTextWrapper = styled.div`
  position: absolute;
  top: 71px;
  left: 21px;
`;
export const CoachingSkipButton = styled.span`
  font-family: SF Pro Text;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.5px;
  text-align: justified;
  color: white;
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;
`;
export const GreenStartBox = styled.div`
  position: absolute;
  bottom: 0;
  bottom: 0px;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: SF Pro Text;
  font-size: 3.5rem;
  font-weight: 800;
  line-height: 41.77px;
  letter-spacing: -0.5px;
  text-align: center;
  background-color: var(--primary);
  color: black;
  z-index: 999;

  cursor: pointer;
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  height: fill-available;
  background-color: black;
  margin: 0 auto;
`;
