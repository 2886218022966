import { postChangePwd } from 'apis/user';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  useAccessTokenStore,
  useAccountStore,
  useRefreshTokenStore,
} from 'store/auth';
import styled from 'styled-components';
import PwdResetForm from '../components/PwdResetForm';

type formInputType = {
  password: string;
  newPassword: string;
};
const PwdResetPage_InAccount = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isLoading },
  } = useForm<formInputType>();
  const { setAccessToken } = useAccessTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  const { setAccount } = useAccountStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,36}$/;

  const submitPassword: SubmitHandler<formInputType> = async (
    data: formInputType
  ) => {
    try {
      const response = await postChangePwd({
        oldPassword: data.password,
        newPassword: data.newPassword,
      });
      if (response) {
        const { accessToken, refreshToken, account, userKey } = response;
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        setAccount(account);
        alert(t('비밀번호 변경에 성공하였습니다'));
        navigate(-1);
      }
    } catch (e: any) {
      console.log(e);
      if (e.response && e.response.data) {
        alert(
          `${t('비밀번호 변경에 실패하였습니다')}\n(${
            e.response.data.reasonCodeText
          })`
        );
      } else {
        alert(t('비밀번호 변경에 실패하였습니다'));
      }
    }
  };
  return (
    <>
      {isLoading && <Loading num={2} />}
      <PageHeader title={t('비밀번호 변경')} isLineShow={false} />
      <Container>
        <Form onSubmit={handleSubmit(submitPassword)}>
          <ItemBox>
            <Title>{t('기존 비밀번호')}</Title>
            <Input
              placeholder={t('비밀번호를 입력하세요')}
              type='password'
              {...register('password', { required: true })}
            />
          </ItemBox>
          <ItemBox>
            <Title>{t('새 비밀번호')}</Title>
            <Input
              placeholder={t('새 비밀번호를 입력하세요')}
              type='password'
              {...register('newPassword', {
                required: true,
                pattern: {
                  value: passwordRegex,
                  message: t(
                    '비밀번호는 영어, 숫자, 특수문자를 포함하여 8글자 이상 36자 이내여야 합니다'
                  ),
                },
              })}
            />
          </ItemBox>
          <InputDesc>
            {t('영어, 숫자, 특수문자 포함 10글자 이상 40자 이내')}
          </InputDesc>
          <SubmitButton isActive={isValid} type='submit'>
            {t('변경하기')}
          </SubmitButton>
        </Form>
      </Container>
    </>
  );
};

const Container = styled.div`
  margin-top: 100px;
  padding: 0 16px;
`;
const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
`;
const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Title = styled.div`
  font-family: SF Pro Text;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 16.71px;
  letter-spacing: -0.5px;
  text-align: left;
  color: white;
`;
const Input = styled.input`
  border: 1px solid var(--c500);
  border-radius: 10px;
  width: 100%;
  height: 48px;
  background-color: transparent;
  padding: 15px;
  color: white;
`;
const InputDesc = styled.div`
  font-family: SF Pro Text;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 14.32px;
  letter-spacing: -0.5px;
  text-align: left;
`;
type SubmitButtonType = {
  isActive: boolean;
};
const SubmitButton = styled.button<SubmitButtonType>`
  position: absolute;
  bottom: 0;

  width: calc(100% - 32px);
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive }) =>
    isActive ? 'var(--primary)' : 'var(--c700)'};
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: -0.5px;
  padding: 0px 20px;
  margin: 30px 0;
  color: ${({ isActive }) => (isActive ? 'black' : 'var(--c300)')};
  border-radius: 10px;
  cursor: pointer;
`;
export const Error = styled.small`
  text-align: left;
  font-size: 1.2rem;
  color: var(--semantic-red);
  line-height: 19px;
`;
export default PwdResetPage_InAccount;
