import Icon from 'components/Icon';
import { ProfileImage } from 'styles/style';
import { timeAgo } from 'constants/common';
import { FeedbackType, SessionType } from 'constants/types/type';
import { useNavigate } from 'react-router-dom';
import { CircleImage, Flex, PremiumBadge, Span } from 'styles/style';
import * as S from './style';
import Img from 'components/Img';
import { getMMSSFormat } from 'constants/video';
import CompleteIconText from 'components/CompleteIconText';
import { useTranslation } from 'react-i18next';
type SessionViewType = {
  session: SessionType;
};
const SessionView = ({ session }: SessionViewType) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <S.ItemContainer>
      <CircleImage>
        <img
          src={
            session.center.bannerImages[0]?.mediumImage ||
            '/defaultUserImage.png'
          }
          alt={t('프로필 이미지')}
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.onerror = null; // 재귀적인 호출을 방지
            target.src = '/defaultUserImage.png';
          }}
        />
      </CircleImage>
      <Flex flexDirection='column' gap='10px'>
        <S.TopContainer>
          <Span fontSize='14px' color='white'>
            {session.center.displayName}
          </Span>
          <Span fontSize='12px' color='var(--c500)'>
            {timeAgo(session.createdAt)}
          </Span>
        </S.TopContainer>
        <S.MainContainer>
          <S.ThumbImageWrapper>
            {session.displayImage?.mediumImage && (
              <Img
                src={session.displayImage?.mediumImage}
                alt={t('세션 이미지')}
              />
            )}
          </S.ThumbImageWrapper>
          <S.Contaienr2>
            <Flex gap='8px' margin='0 0 10px 0'>
              {session.authority === 'member_only' && (
                <PremiumBadge>{t('회원전용')}</PremiumBadge>
              )}
              {session.isSessionCompleted && <CompleteIconText />}
            </Flex>
            <S.Title>{session.title}</S.Title>
            <S.SubInfo>
              {session.uploadUser?.nickname != '' &&
                `${session.uploadUser?.nickname} • `}
              {getMMSSFormat(session.video.duration)}
            </S.SubInfo>
            <Flex
              gap='8px'
              onClick={() =>
                navigate(
                  `/session/detail/?sessionId=${session.sessionId}&centerId=${session.centerId}`
                )
              }
              cursor='pointer'
            >
              <Span>{t('지금 바로 시작하기')}</Span>
              <Icon icon='RightArrow' />
            </Flex>
          </S.Contaienr2>
        </S.MainContainer>
      </Flex>
    </S.ItemContainer>
  );
};
export default SessionView;
