import { deviceSizes } from 'constants/theme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  min-width: ${deviceSizes.mobile};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 20px;
`;
