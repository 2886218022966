import SessionList from 'components/SessionSlide';
import { SessionType } from 'constants/types/type';
import { useTranslation } from 'react-i18next';
import { Margin } from 'styles/style';
import * as S from './style';

type PreviewSessionsType = {
  previewSessions: SessionType[];
};
const PreviewSessions = ({ previewSessions }: PreviewSessionsType) => {
  const { t } = useTranslation();

  return (
    <>
      {previewSessions && previewSessions.length > 0 && (
        <>
          <S.Title>{t('온라인 세션')}</S.Title>
          <Margin margin='13px 0 0 0' />
          <SessionList items={previewSessions} />
        </>
      )}
    </>
  );
};
export default PreviewSessions;
