import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const Notify = (props: IconPropsWithSVGProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.10827 13.995C3.93106 15.1225 4.72333 15.9052 5.69338 16.2952C9.41234 17.7905 14.5877 17.7905 18.3066 16.2952C19.2767 15.9051 20.0689 15.1225 19.8917 13.995C19.7828 13.3021 19.2443 12.7251 18.8453 12.1617C18.3227 11.4146 18.2708 10.5998 18.2707 9.73283C18.2707 6.38258 15.4632 3.66666 12 3.66666C8.53677 3.66666 5.72927 6.38258 5.72927 9.73283C5.72919 10.5998 5.67727 11.4146 5.15467 12.1617C4.7557 12.7251 4.21718 13.3021 4.10827 13.995Z'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.5 19.5C10.1634 20.0182 11.0396 20.3333 12 20.3333C12.9604 20.3333 13.8366 20.0182 14.5 19.5'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Notify;
