import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const Menu = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <path
      d='M6.66663 2.29166H4.16663C3.13109 2.29166 2.29163 3.13113 2.29163 4.16666V6.66666C2.29163 7.7022 3.13109 8.54166 4.16663 8.54166H6.66663C7.70216 8.54166 8.54163 7.7022 8.54163 6.66666V4.16666C8.54163 3.13113 7.70216 2.29166 6.66663 2.29166Z'
      stroke='currentColor'
      strokeWidth='1.5'
    />
    <path
      d='M15.8334 2.29166H13.3334C12.2978 2.29166 11.4584 3.13113 11.4584 4.16666V6.66666C11.4584 7.7022 12.2978 8.54166 13.3334 8.54166H15.8334C16.8689 8.54166 17.7084 7.7022 17.7084 6.66666V4.16666C17.7084 3.13113 16.8689 2.29166 15.8334 2.29166Z'
      stroke='currentColor'
      strokeWidth='1.5'
    />
    <path
      d='M15.8334 11.4583H13.3334C12.2978 11.4583 11.4584 12.2978 11.4584 13.3333V15.8333C11.4584 16.8689 12.2978 17.7083 13.3334 17.7083H15.8334C16.8689 17.7083 17.7084 16.8689 17.7084 15.8333V13.3333C17.7084 12.2978 16.8689 11.4583 15.8334 11.4583Z'
      stroke='currentColor'
      strokeWidth='1.5'
    />
    <path
      d='M6.66663 11.4583H4.16663C3.13109 11.4583 2.29163 12.2978 2.29163 13.3333V15.8333C2.29163 16.8689 3.13109 17.7083 4.16663 17.7083H6.66663C7.70216 17.7083 8.54163 16.8689 8.54163 15.8333V13.3333C8.54163 12.2978 7.70216 11.4583 6.66663 11.4583Z'
      stroke='currentColor'
      strokeWidth='1.5'
    />
  </svg>
);

export default Menu;
