import { CenterGradeType } from 'constants/types/type';
import { useTranslation } from 'react-i18next';
import { NewBadge, PremiumBadge } from 'styles/style';

type BadgeType = {
  centerGrade: CenterGradeType;
};
const Badge = ({ centerGrade }: BadgeType) => {
  const { t } = useTranslation();

  return (
    <>
      {centerGrade === 'premium' && (
        <PremiumBadge>{t('회원전용')}</PremiumBadge>
      )}
      {<PremiumBadge>{t('회원전용')}</PremiumBadge>}
      {centerGrade === 'new' && <NewBadge>New</NewBadge>}
    </>
  );
};
export default Badge;
