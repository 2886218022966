import { theme } from 'constants/theme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
export const Form = styled.form`
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  margin: 50px 0px;
  justify-content: center;
  align-items: center;
  text-align: center;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
  }
`;

export const Title = styled.h1`
  color: var(--c100);
  margin-bottom: 40px;
  font-size: 1.5em;
  font-weight: bold;
`;
export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;
export const InputName = styled.div`
  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;
  display: flex;
  gap: 3px;
`;
export const Input = styled.input`
  width: 100%;
  color: white;
  background-color: transparent;
  border: 1px solid var(--c500);

  padding: 16px;
  border-radius: 10px;
`;
const Button = styled.button`
  min-width: 107px;
  font-size: 1.4rem;
  border-radius: 10px;
  font-weight: 600;
  padding: 16px;
  white-space: nowrap;
  background-color: var(--primary);
  letter-spacing: -0.5px;
`;
export const LoginButton = styled(Button)`
  width: 100%;
  color: var(--c1100);
  margin-top: 7px;
`;

type SubmitButtonType = {
  isValid: boolean;
};
export const SubmitButton = styled(Button)<SubmitButtonType>`
  color: var(--c1100);
  margin-top: 7px;
  width: 100%;
  background-color: ${({ isValid }) => (isValid ? `var(--primary)` : 'gray')};
  :disabled {
    background-color: red;
  }
`;
export const NextButton = styled(Button)`
  width: 100%;
  color: var(--c1100);
  background-color: var(--c100);
  :hover {
    background-color: var(--c200);
  }
`;
type GrayButtonType = {
  isActive?: boolean;
};
export const GrayButton = styled(Button)<GrayButtonType>`
  color: var(--c1100);
  background-color: white;
  :disabled {
    color: var(--c600);
    background-color: var(--c200);
  }
`;
export const Red = styled.span`
  color: var(--semantic-red);
`;
type AlertType = {
  isActive: boolean;
};
export const Alert = styled.div<AlertType>`
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
  color: red;
  text-align: left;
  font-size: 1.3rem;
`;
export const CheckBox = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid var(--c300);
  border-radius: 1px;
  background-color: transparent;
`;

type ErrorType = {
  margin?: string;
};
export const Error = styled.small<ErrorType>`
  text-align: left;
  font-size: 1.3rem;
  color: var(--semantic-red);
  margin: ${({ margin }) => margin};
  line-height: 19px;
`;
export const CheckBoxInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
export const Hr = styled.div`
  background-color: var(--c500);
  margin: 7px 0;
  width: 100%;
  height: 1px;
  border-radius: 10px;
`;

export const Line = styled.div`
  color: var(--semantic-red);
  width: 360px;
  height: 1px;
  background-color: var(--c200);
  margin-bottom: 30px;
`;
export const CustomSelect = styled.select`
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 8px;
  padding-right: 22px;
  font-size: 1.2rem;
  border: none;
  appearance: none;
  -webkit-appearance: none; /* for Safari */
  -moz-appearance: none; /* for Firefox */
  position: relative;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpath fill='white' d='M0 0l5 6 5-6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center; /* Adjust as needed */
  background-size: 8px 6px; /* Adjust as needed */
  cursor: pointer;

  &:focus {
    outline: none;
  }

  option {
    font-size: 1.2rem;
    color: white;
  }
`;
