import { postSessionDetail, postSessionViewed } from 'apis/session';
import Icon from 'components/Icon';
import PageHeader from 'components/PageHeader';
import PlaySummaryBox from 'components/Session/PlaySummaryBox';
import SessionVideo from 'components/Session/SessionVideo';
import SessionDetail from 'components/SessionDetail';
import { CoachingVideo } from 'components/Video/CoachingVideo';
import { ConvertButton } from 'components/Video/ConvertButton';
import { CountDown } from 'components/Video/CountDown';
import { SceneSkipButton } from 'components/Video/SceneSkipButton';
import VideoControls from 'components/Video/VideoControls';
import VideoProgressBar from 'components/Video/VideoProgressBar';
import { GET_ALERT_TEXT } from 'constants/alertText';
import { SceneType, SessionType, VideoStatus } from 'constants/types/type';
import useVideoControl from 'hooks/useVideoControl';
import useVideoMetadata from 'hooks/useVideoMetadata';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Flex, Margin, NotFoundDiv } from 'styles/style';
import * as S from '../styles/SessionVideoPageStyle';
import RequestFeedbackPage from './RequestFeedbackPage';

const SessionVideoPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [session, setSession] = useState<SessionType>();
  const [coachingScene, setCoachingScene] = useState<SceneType | null>();

  // coaching
  const [currentScene, setCurrentScene] = useState<SceneType | null>(null);
  const [currentStatus, setCurrentStatus] = useState<VideoStatus>('session');
  const [isRequestFeedback, setIsRequestFeedback] = useState<boolean>(false);

  // video
  const [flipped, setFlipped] = useState(false);
  const {
    videoRef,
    progressRef,
    currentBarHandler,
    play,
    pause,
    move,
    startDragging,
    isVideoEnd,
    isVideoPlaying,
  } = useVideoControl();
  const { currentTime } = useVideoMetadata({
    videoRef,
    play,
    pause,
  });

  // user record video
  const nullBlob = new Blob([]);
  const [recordVideoBlob, setRecordVideoBlob] = useState<Blob>(nullBlob);

  const navigate = useNavigate();
  const centerId = searchParams.get('centerId');
  const sessionId = searchParams.get('sessionId');

  // 영상 재생에 부하를 주기 위한 변수
  const prevTimeRef = useRef(0);

  useEffect(() => {
    getSessionData();
  }, [centerId, sessionId]);

  useEffect(() => {
    let scene;
    const prevTime = prevTimeRef.current;

    if (prevTime === 0 || Math.abs(currentTime - prevTime) > 100) {
      prevTimeRef.current = currentTime;
      scene = session?.scenes.find((scene) => {
        if (
          currentTime >= scene.sceneStartAt &&
          currentTime < scene.sceneEndAt
        ) {
          if (scene.sceneType === 'coaching') {
            setCurrentStatus('coaching_wait');
          }
          return scene;
        }
      });

      if (scene) setCurrentScene(scene);
      else setCurrentScene(null);
    }
    if (
      (session?.video.duration && currentTime >= session?.video.duration) ||
      isVideoEnd
    ) {
      setCurrentStatus('end');
    }
  }, [currentTime, currentStatus]);

  useEffect(() => {
    // coaching 영상 제어
    switch (currentStatus) {
      case 'session':
        if (coachingScene?.sceneEndAt) {
          move(coachingScene?.sceneEndAt);
          // play();
        }
        break;
      case 'end':
        if (!session?.scenes.find((scene) => scene.sceneType === 'coaching')) {
          handleSessionViewed();
        }
        if (!isRequestFeedback) {
          navigate(
            `/session/detail?sessionId=${sessionId}&centerId=${centerId}`
          );
        }
    }
  }, [currentStatus]);

  const getSessionData = async () => {
    if (!sessionId || !centerId) {
      return;
    }
    const response = await postSessionDetail({
      params: {
        sessionId,
      },
      data: {
        centerId,
      },
    });
    const result = response;
    if (!result) return;

    const { sessionView } = result;
    if (sessionView) {
      setSession(sessionView);
      setCoachingScene(getCoachingScene(sessionView.scenes));
      play();
    } else {
      alert(GET_ALERT_TEXT);
      navigate(-1);
    }
  };
  const handleVideo = () => {
    if (videoRef.current && videoRef.current.paused) {
      play();
    } else {
      pause();
    }
  };
  const getCoachingScene = (scenes: SceneType[]): SceneType | null => {
    for (let scene of scenes) {
      if (scene.sceneType === 'coaching') {
        return scene;
      }
    }
    return null;
  };
  const handleSessionViewed = async () => {
    if (sessionId) {
      await postSessionViewed({ sessionId });
    }
  };

  const { t } = useTranslation();

  return (
    <>
      {session && currentStatus !== 'end' && (
        <S.Outer>
          <PageHeader
            title=''
            backgroundColor='transparent'
            isLineShow={false}
          />
          <S.Container
            onClick={() => currentStatus === 'session' && handleVideo()}
          >
            {currentStatus === 'session' ? (
              <>
                <PlaySummaryBox
                  title={currentScene?.title || ''}
                  currentTime={currentTime}
                  duration={session.video.duration}
                  currentBarHandler={currentBarHandler}
                />
                <SessionVideo
                  video={session.video}
                  videoRef={videoRef}
                  flipped={flipped}
                  setIsPaused={pause}
                  isCoaching={false}
                />
                {!isVideoPlaying && (
                  <S.StopIconWrapper>
                    <Icon icon='Stop' />
                  </S.StopIconWrapper>
                )}
              </>
            ) : (
              coachingScene &&
              coachingScene?.additionalVideo && (
                <CoachingVideo
                  coachingScene={coachingScene}
                  currentStatus={currentStatus}
                  setCurrentStatus={setCurrentStatus}
                  setRecordVideoBlob={setRecordVideoBlob}
                  setIsRequestFeedback={setIsRequestFeedback}
                />
              )
            )}

            {videoRef.current &&
            videoRef.current.paused &&
            currentStatus === 'session' ? (
              <S.ControlsContainer>
                <Flex justifyContents='right'>
                  <ConvertButton
                    setFlipped={setFlipped}
                    backgroundColor='rgba(0,0,0,0.5)'
                  />
                </Flex>
                <VideoProgressBar
                  progressRef={progressRef}
                  height={8}
                  borderRadius='4px'
                  backgroundColor='rgba(255,255,255,0.3)'
                  currentBarHandler={currentBarHandler}
                  currentTime={currentTime}
                  duration={session.video.duration}
                  flagTime={coachingScene?.sceneStartAt}
                  startDragging={startDragging}
                  isShowCurrenetBall={true}
                />
              </S.ControlsContainer>
            ) : (
              currentScene &&
              currentStatus === 'session' && (
                <SceneSkipButton move={move} scene={currentScene} />
              )
            )}
          </S.Container>

          {currentStatus === 'session' && isRequestFeedback && (
            <S.GoFeedbackBox>
              <span>
                {t('방금 촬영한 영상을 전문가의 영상과 직접 비교하고')}
                <br /> {t('맞춤형 피드백 요청을 보내보세요!')}
              </span>
              <S.GoFeedbackButton
                onClick={(e) => {
                  setCurrentStatus('end');
                }}
              >
                {t('따라하기 영상 비교 화면 보기')}
              </S.GoFeedbackButton>
            </S.GoFeedbackBox>
          )}
        </S.Outer>
      )}
      {session &&
        centerId &&
        sessionId &&
        recordVideoBlob &&
        isRequestFeedback &&
        currentStatus === 'end' &&
        coachingScene?.additionalVideo && (
          <RequestFeedbackPage
            centerId={centerId}
            sessionId={sessionId}
            userVideoBlob={recordVideoBlob}
            sceneVideo={coachingScene?.additionalVideo}
            centerName={session.center.displayName}
          />
        )}
    </>
  );
};

export default SessionVideoPage;
