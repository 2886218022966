import React from 'react';
import styled from 'styled-components';

type SwitchButtonType = {
  id: string;
  switchOn: boolean;
  setSwitchOn: React.Dispatch<React.SetStateAction<boolean>>;
};
const SwitchButton = ({ id, switchOn, setSwitchOn }: SwitchButtonType) => {
  return (
    <Wrapper>
      <Checkbox
        type='checkbox'
        id={id}
        checked={switchOn}
        onChange={(e) => setSwitchOn(e.target.checked)}
      />
      <Label htmlFor={id} className='switch_label'>
        <Span className='onf_btn'></Span>
      </Label>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;
const Checkbox = styled.input`
  position: absolute;
  /* hidden */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  :checked + .switch_label {
    background: #65c365;
  }
  :checked + .switch_label .onf_btn {
    left: 23px;
    background: #fff;
    box-shadow: 1px 2px 3px #00000020;
  }
`;
const Label = styled.label`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 51px;
  height: 31px;
  background-color: var(--c200);
  border-radius: 20px;
  transition: 0.2s;
`;
const Span = styled.span`
  position: absolute;
  top: 3px;
  left: 3px;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  background: white;
  transition: 0.2s;
  box-shadow: 2px;
`;

export default SwitchButton;
