import styled from 'styled-components';
type BannerImageWrapperType = {
  backgroundImage: string;
};
export const BannerImageWrapper = styled.div<BannerImageWrapperType>`
  width: 100%;
  height: 100%;
  box-shadow: 0 -25px 20px -10px rgba(0, 0, 0, 1) inset;
  z-index: 999;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: center;
  background-size: cover;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin-bottom: 20px;
`;
export const CenterName = styled.span`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 28.64px;
  letter-spacing: -0.5px;
  margin-top: 12px;
`;
export const Title = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 16.71px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-top: 30px;
`;

export const CaptionLine = styled.div`
  display: flex;
  gap: 8px;
`;
export const CaptionIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-top: 1px;
`;
export const CaptionText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
`;

export const StaffsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  overflow-x: auto;
  margin-top: 12px;
  padding-bottom: 10px;
`;
type StaffWrapperType = {
  backgroundImage: string;
};
export const StaffWrapper = styled.div<StaffWrapperType>`
  flex-shrink: 0;
  width: 65px;
  height: 65px;
  border-radius: 100%;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-image: url(${({ backgroundImage }) => backgroundImage});
`;

export const Desc = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.5px;
  text-align: justified;
  margin-top: 12px;
`;

export const MapWrapper = styled.div`
  width: 100%;
  margin-top: 14px;
  border-radius: 8px;
  overflow: hidden;
`;

const Button = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background-color: var(--primary);
  color: black;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 16.71px;
  letter-spacing: -0.5px;
  text-align: center;
  margin-top: 64px;
`;

export const SignupButton = styled(Button)`
  background-color: var(--primary);
  color: black;
  cursor: pointer;
`;
export const WaitingButton = styled(Button)`
  background-color: var(--c800);
  color: white;
  cursor: default;
`;
