import { Swiper as _Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './style.css';

import SwiperCore from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
import { useEffect } from 'react';

type SwiperType = {
  children: any;
  loop: boolean;
  autoplay: boolean;
  breakpoints: any;
  pagination: boolean;
};
const Swiper = ({
  children,
  loop,
  breakpoints,
  autoplay,
  pagination,
}: SwiperType) => {
  SwiperCore.use([Autoplay, Pagination]);

  const sliderOptions = {
    loop,
    autoplay: autoplay
      ? {
          delay: 3000,
        }
      : false,
    breakpoints,
    pagination: pagination ? { clickable: true } : false,
    observeParents: true,
    observer: true,
  };

  return (
    <_Swiper class={`swiper-container`} {...sliderOptions}>
      {children}
    </_Swiper>
  );
};
export default Swiper;
export { SwiperSlide as Slide };
