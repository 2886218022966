const Noti_Post = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='18'
    viewBox='0 0 16 18'
    fill='none'
  >
    <path
      d='M12.1667 0.666626V2.33329M7.99999 0.666626V2.33329M3.83333 0.666626V2.33329'
      stroke='#D3D4DC'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.916664 7.33333C0.916664 4.58347 0.916664 3.20854 1.77094 2.35427C2.62521 1.5 4.00014 1.5 6.75 1.5H9.25C11.9999 1.5 13.3748 1.5 14.2291 2.35427C15.0833 3.20854 15.0833 4.58347 15.0833 7.33333V11.5C15.0833 14.2499 15.0833 15.6248 14.2291 16.4791C13.3748 17.3333 11.9999 17.3333 9.25 17.3333H6.75C4.00014 17.3333 2.62521 17.3333 1.77094 16.4791C0.916664 15.6248 0.916664 14.2499 0.916664 11.5V7.33333Z'
      stroke='#D3D4DC'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.66666 11.4999H8M4.66666 7.33325H11.3333'
      stroke='#D3D4DC'
      strokeLinecap='round'
    />
  </svg>
);
export default Noti_Post;
