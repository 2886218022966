import * as S from './style';
import { IoIosArrowBack } from 'react-icons/io';
import CenterProfileModal from 'components/CenterProfileModal';
import { useState } from 'react';
import { useProfileStore } from 'store/auth';
import Img from 'components/Img';
import { ProfileImageWrapper } from 'styles/style';
import { useNavigate } from 'react-router-dom';

type BaseType = {
  title: string;
  sub: string;
  backgroundColor?: string;
  isLineShow: boolean;
  backLink?: string;
  maxWidth?: string;
  children?: any;
};
type BasicType = BaseType & {
  isProfileShow?: never;
};
type ProfileShowType = BaseType & {
  isProfileShow: true;
};
const PageHeaderWithSub = ({
  title,
  sub,
  backgroundColor,
  isLineShow,
  isProfileShow,
  backLink,
  maxWidth,
  children,
}: BasicType | ProfileShowType) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { profile, setProfile } = useProfileStore();
  const navigate = useNavigate();

  return (
    <>
      <S.Container backgroundColor={backgroundColor} maxWidth={maxWidth}>
        <S.BackIconWrapper
          onClick={() =>
            backLink ? navigate(backLink) : window.history.back()
          }
        >
          <IoIosArrowBack size={24} cursor='pointer' />
        </S.BackIconWrapper>
        <S.TitleContainer>
          <S.Title>{title}</S.Title>
          <S.TitleSub>{sub}</S.TitleSub>
        </S.TitleContainer>
        {children}
        {isProfileShow && (
          <S.CircleProfileImageWrapper>
            <ProfileImageWrapper
              size='24px'
              cursor='pointer'
              onClick={() => setIsModalOpen(true)}
            >
              <Img src={profile.profileImage?.mediumImage} alt='profileImage' />
            </ProfileImageWrapper>
          </S.CircleProfileImageWrapper>
        )}
      </S.Container>
      {isLineShow && (
        <S.LineWrapper>
          <S.Line />
        </S.LineWrapper>
      )}
      {isModalOpen && profile && (
        <CenterProfileModal
          profile={profile}
          setIsModalOpen={setIsModalOpen}
          mode='edit'
        />
      )}
    </>
  );
};
export default PageHeaderWithSub;
