import { useTranslation } from 'react-i18next';
import { useProfileStore } from 'store/auth';
import * as S from './style';

type SignupButtonType = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const SignupButton = ({ setIsModalOpen }: SignupButtonType) => {
  const { profile, setProfile } = useProfileStore();
  const { t } = useTranslation();

  return (
    <>
      {profile?.permissionState === 'pending' && (
        <S.WaitingButton>{t('센터의 승인 대기중 입니다')}</S.WaitingButton>
      )}
      {profile?.permissionState === 'denied' && (
        <S.SignupButton onClick={() => setIsModalOpen(true)}>
          {t('가입하기')}
        </S.SignupButton>
      )}
      {profile?.role === 'guest' && (
        <S.SignupButton onClick={() => setIsModalOpen(true)}>
          {t('가입하기')}
        </S.SignupButton>
      )}
    </>
  );
};
export default SignupButton;
