import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const RightArrow2 = (props: IconPropsWithSVGProps) => (
  <svg
    width='6'
    height='10'
    viewBox='0 0 6 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.75 9.5L4.54289 5.70711C4.87623 5.37377 5.04289 5.20711 5.04289 5C5.04289 4.79289 4.87623 4.62623 4.54289 4.29289L0.75 0.5'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default RightArrow2;
