import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
`;
export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const ItemTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  letter-spacing: -0.5px;
  color: var(--c300);
`;
export const ItemSpan = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  letter-spacing: -0.5px;
  color: white;
  cursor: pointer;
`;
