import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Flex, Span } from '../../styles/style';
import {
  Form,
  Title,
  LoginButton,
  Container,
  Input,
  Line,
  Error,
  CustomSelect,
} from '../../styles/LoginStyles';
import {
  useAccessTokenStore,
  useCenterStore,
  useRefreshTokenStore,
  useAccountStore,
  useProfileStore,
} from 'store/auth';
import { getCenterData } from 'apis/center';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { handleLanguageChange } from 'constants/common';
import { LanguageSelectBox } from 'components/LanguageSelectBox';
import styled from 'styled-components';
import Icon from 'components/Icon';

function LoginForm() {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, errors },
  } = useForm();
  const [isLoginOK, setIsLoginOK] = useState(true);

  //auth
  const { setAccessToken } = useAccessTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  const { center, setCenter } = useCenterStore();
  const { setAccount } = useAccountStore();
  const { profile, setProfile } = useProfileStore();

  const { t } = useTranslation();

  const login = async (data: any) => {
    const { email, password } = data;
    try {
      const response = await fetch(`${API_URL}/api/auth-submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email.trim(),
          password: password,
          osVer: 'os',
          adid: 'a53590d3a50652b4',
          fcmToken:
            'dkzX2a4MQQ66dHqD7hUxTb:APA91bEldq8uJg75aCUY4o95LSArECm9d0E5ygYgZRzsl0dJX1QR53qAAsoWBbo1gcxDBNicTDOgxMCg6astAQ2KMFQsoZBZLe3Sng9rW2P_MnszjQCurFCQicf55Vm6SYCkUnByR2GF',
        }),
      });

      if (response.ok) {
        const result = await response.json();
        const { centerId, account, accessToken, refreshToken } = result;

        const accessToken2 =
          'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyMDMxRjlCQjk4MDkzQzJENTFFQ0EyM0Q4MTE4REM4NDQ1NDU4QzJDMEJGQTExNDk0REVFMkZFQzE2OTU2OENCIiwiYXV0aCI6Im93bmVyIiwiZXhwIjoxNjk5OTI0MjQ0fQ.w2kGOgP4PHKkwqCpB9w-pMMhqdJlcTVR4xY_wzTvN7U';
        const refreshToken2 =
          'eyJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MDUwNzIyNDR9.9xoTBLmi1qrQoF7nZcHSePK9M_WIFC2IruqZ325nL3o';

        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        setAccount(account);
        getCenterData({ centerId, setCenter, setProfile });

        navigate('/');
      } else {
        setIsLoginOK(false);
        alert(
          t(
            '등록되지 않은 아이디거나, 아이디 또는 비밀번호가 일치하지 않습니다'
          )
        );
      }
    } catch (error) {
      console.error(
        'There has been a problem with your fetch operation: ',
        error
      );
    }
  };

  return (
    <Container>
      <Flex
        width='100%'
        maxWidth='360px'
        justifyContents='space-between'
        alignItems='center'
      >
        <LogoWrapper>
          <Icon icon='LogoText' width={130} />
        </LogoWrapper>
        <LanguageSelectBox />
      </Flex>
      <Form onSubmit={handleSubmit((data: any) => login(data))}>
        <Title>
          {t('회원')} {t('로그인')}
        </Title>
        <Input
          id='email'
          placeholder={t('이메일')}
          aria-invalid={isDirty ? (errors.email ? 'true' : 'false') : undefined}
          {...register('email', {
            required: t('이메일을 입력하세요'),
            pattern: {
              value: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i,
              message: t('이메일 형식을 확인하세요'),
            },
          })}
        />
        <Flex flexDirection='row' margin='0 0 5px 0 ' />
        <Input
          id='password'
          type='password'
          placeholder={t('비밀번호')}
          aria-invalid={
            isDirty ? (errors.password ? 'true' : 'false') : undefined
          }
          {...register('password', {
            required: t('비밀번호를 입력하세요'),
          })}
        />
        <Flex
          flexDirection='row'
          width='100%'
          margin='10px 0 '
          justifyContents='left'
        >
          {!isLoginOK ? (
            <Error>
              {t('로그인에 실패하였습니다')}{' '}
              {t(
                '등록되지 않은 아이디거나, 아이디 또는 비밀번호가 일치하지 않습니다'
              )}
            </Error>
          ) : errors.email ? (
            <Error role='alert'>{errors.email.message?.toString()}</Error>
          ) : (
            errors.password && (
              <Error role='alert'>{errors.password.message?.toString()}</Error>
            )
          )}
        </Flex>
        <LoginButton type='submit' disabled={isSubmitting}>
          {t('로그인')}
        </LoginButton>
        <Flex
          width='100%'
          flexDirection='row'
          margin='15px 0 0 0'
          justifyContents='right'
        >
          <Span
            fontSize='12px'
            cursor='pointer'
            onClick={() => navigate('/pwdReset')}
          >
            {t('비밀번호 재설정')}
          </Span>
        </Flex>
      </Form>
      <Line />
      <Flex flexDirection='row' gap='10px'>
        <Span fontSize='14px' fontWeight='600'>
          {t('계정이 없으신가요?')}
        </Span>
        <Span
          // color="rgb(0 181 110)"
          color='var(--primary)'
          fontSize='14px'
          fontWeight='bold'
          cursor='pointer'
          onClick={() => navigate('/signup')}
        >
          RealFeed {t('가입하기')}
        </Span>
      </Flex>
    </Container>
  );
}
export default LoginForm;

const LogoWrapper = styled.div`
  width: 15vh;
`;
