import client from './client';
import { withErrorHandling } from './errorHandling';

// term
const postTermRaw = () => client.post('/api/mem-join/terms-list');
export const postTerm = withErrorHandling(postTermRaw);

// 스포츠태그
const postSportsTagRaw = () => client.post('/api/center/sportstag');
export const postSportsTag = withErrorHandling(postSportsTagRaw);

// 준비물
const postPreperationListRaw = () =>
  client.post('/api/session/preperationList');
export const postPreperationList = withErrorHandling(postPreperationListRaw);

// 캡션
const postCaptionListRaw = () => client.post('/api/center/captionList');
export const postCaptionList = withErrorHandling(postCaptionListRaw);
