import * as S from './style';
import { IoIosArrowBack } from 'react-icons/io';
import CenterProfileModal from 'components/CenterProfileModal';
import { useState } from 'react';
import { useProfileStore } from 'store/auth';
import Img from 'components/Img';
import { ProfileImageWrapper } from 'styles/style';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';

type BaseType = {
  title: string;
  backgroundColor?: string;
  isLineShow: boolean;
  backLink?: string;
  isHomeShow?: boolean;
};
type BasicType = BaseType & {
  isProfileShow?: never;
};
type ProfileShowType = BaseType & {
  isProfileShow: true;
};
const PageHeader = ({
  title,
  backgroundColor,
  isLineShow,
  isProfileShow,
  backLink,
  isHomeShow = true,
}: BasicType | ProfileShowType) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { profile, setProfile } = useProfileStore();
  const navigate = useNavigate();

  return (
    <>
      <S.Container backgroundColor={backgroundColor}>
        <S.BackIconWrapper
          onClick={() =>
            backLink ? navigate(backLink) : window.history.back()
          }
        >
          <IoIosArrowBack size={24} cursor='pointer' />
        </S.BackIconWrapper>
        <S.Title>{title}</S.Title>
        {isProfileShow && (
          <S.CircleProfileImageWrapper>
            <ProfileImageWrapper
              size='40px'
              cursor='pointer'
              onClick={() => setIsModalOpen(true)}
            >
              <Img src={profile.profileImage?.mediumImage} alt='profileImage' />
            </ProfileImageWrapper>
          </S.CircleProfileImageWrapper>
        )}
        {!isProfileShow && isHomeShow && (
          <S.HomeIconWrapper onClick={() => navigate('/recommand')}>
            <Icon icon='Home' />
          </S.HomeIconWrapper>
        )}
      </S.Container>
      {isLineShow && (
        <S.LineWrapper>
          <S.Line />
        </S.LineWrapper>
      )}
      {isModalOpen && profile && (
        <CenterProfileModal
          profile={profile}
          setIsModalOpen={setIsModalOpen}
          mode='edit'
        />
      )}
    </>
  );
};
export default PageHeader;
