const Graph = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.66699 11.3334L4.66699 8.66669'
      stroke='#D3D4DC'
      stroke-linecap='round'
    />
    <path d='M8 11.3334L8 4.66669' stroke='#D3D4DC' stroke-linecap='round' />
    <path
      d='M11.334 11.3334L11.334 7.33337'
      stroke='#D3D4DC'
      stroke-linecap='round'
    />
    <path
      d='M1.66699 8.00002C1.66699 5.01446 1.66699 3.52168 2.59449 2.59418C3.52198 1.66669 5.01476 1.66669 8.00033 1.66669C10.9859 1.66669 12.4787 1.66669 13.4062 2.59418C14.3337 3.52168 14.3337 5.01446 14.3337 8.00002C14.3337 10.9856 14.3337 12.4784 13.4062 13.4059C12.4787 14.3334 10.9859 14.3334 8.00033 14.3334C5.01476 14.3334 3.52198 14.3334 2.59449 13.4059C1.66699 12.4784 1.66699 10.9856 1.66699 8.00002Z'
      stroke='#D3D4DC'
      stroke-linejoin='round'
    />
  </svg>
);

export default Graph;
