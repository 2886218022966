import internal from 'stream';
import client from './client';
import { withErrorHandling } from './errorHandling';

type postSearchCenterType = {
  isOpenStatus: string;
  limit: number;
  offset: number;
  orderBy: 'asc' | 'desc';
};
const postSearchCenterRaw = (data: postSearchCenterType) =>
  client.post('/api/center/search/list', data);
export const postSearchCenter = withErrorHandling(postSearchCenterRaw);

// 웹앱 검색
type postSearchType = {
  searchDisplayName: string;
};
const postSearchRaw = (data: postSearchType) =>
  client.post('/api/app/center/list', data);
export const postSearch = withErrorHandling(postSearchRaw);
