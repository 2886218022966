import AspectRatioBox from 'components/AspectRatioBox';
import Swiper, { Slide } from 'components/Swiper';
import {
  breakpoints_float,
  breakpoints_int,
} from 'constants/swiper_breakpoints';
import { Item } from './style';

type AdBannerType = {
  items: any[];
};
const AdBanner = ({ items }: AdBannerType) => {
  return (
    <>
      <Swiper
        breakpoints={breakpoints_int}
        loop={true}
        autoplay={true}
        pagination={false}
      >
        {[...items, ...items, ...items].map((item, index) => {
          return (
            <Slide
              key={index}
              onClick={() => window.open(item.advertBannerUrl)}
            >
              <AspectRatioBox ratio={0.29}>
                <Item>
                  <img src={item.advertBannerImgLink} />
                </Item>
              </AspectRatioBox>
            </Slide>
          );
        })}
      </Swiper>
    </>
  );
};

export default AdBanner;
