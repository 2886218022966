import { SceneType } from 'constants/types/type';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type SceneSkipButtonType = {
  move: (time: number) => void;
  scene: SceneType;
};
export const SceneSkipButton = ({ move, scene }: SceneSkipButtonType) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    move(scene.sceneEndAt);
  };
  const { t } = useTranslation();

  return (
    <Container onClick={(e) => handleClick(e)}>
      {scene.title} {t('건너뛰기')}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  right: 20px;
  bottom: 55px;

  width: auto;
  height: 36px;
  background-color: white;
  border-radius: 18px;

  color: black;
  font-family: SF Pro Text;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  letter-spacing: -0.5px;
  text-align: left;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 13px;
`;
