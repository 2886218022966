import { getKoreanDate } from 'constants/common';
import client from './client';
import { withErrorHandling } from './errorHandling';

// 회원 리스트
type postMemberListType = {
  centerId: string;
  limit: number;
  offset: number;
  orderBy: string;
  role: string;
};
const postMemberListRaw = (data: postMemberListType) =>
  client.post('/api/center/member/list', data);
export const postMemberList = withErrorHandling(postMemberListRaw);

// 회원 리스트 가져온 후 list 전달
type postGetUserListType = {
  centerId: string;
  role: 'staff' | 'member' | 'all';
  offset: number;
};
export const getUserList = async ({
  centerId,
  role,
  offset,
}: postGetUserListType) => {
  try {
    const response = await postMemberList({
      centerId,
      limit: 10,
      offset,
      orderBy: 'desc',
      role,
    });

    const { memlist, listInfo } = response;
    if (memlist && listInfo) {
      const new_memberlist = memlist.map((v: any) => {
        return {
          ...v,
          createdAt: getKoreanDate(v.createdAt),
          ...(v.modifiedAt && { modifiedAt: getKoreanDate(v.modifiedAt) }),
        };
      });
      return {
        listInfo,
        list: new_memberlist,
      };
    } else {
      return {
        list: [],
        listInfo: {},
      };
    }
  } catch (e) {
    console.error(e);
    return {
      list: [],
      listInfo: {},
    };
  }
};

// 회원/스태프 요청 리스트
type postProposalListType = {
  centerId: string;
};
const postProposalMemberListRaw = (data: postProposalListType) =>
  client.post('/api/center/member/proposal/list', data);
export const postProposalMemberList = withErrorHandling(
  postProposalMemberListRaw
);
const postProposalStaffListRaw = (data: postProposalListType) =>
  client.post('/api/center/staff/proposal/list', data);
export const postProposalStaffList = withErrorHandling(
  postProposalStaffListRaw
);

// 스태프 요청
type postProposalStaffType = {
  centerId: string;
  nickname: string;
};
const postProposalStaffRaw = (data: postProposalStaffType) =>
  client.post('/api/center/staff/proposal', data);
export const postProposalStaff = withErrorHandling(postProposalStaffRaw);

// 회원 요청 확인
type postConfirmMemberType = {
  params: {
    accountId: string;
  };
  data: {
    centerId: string;
    connRegStatus: string;
  };
};
const postConfirmMemberRaw = ({ params, data }: postConfirmMemberType) =>
  client.post(`/api/center/member/${params.accountId}/confirm`, data);
export const postConfirmMember = withErrorHandling(postConfirmMemberRaw);

// 스태프 요청 확인
type postConfirmStaffType = {
  centerId: string;
  accountId: string;
  connRegStatus: string;
  nickname: string;
};
const postConfirmStaffRaw = (data: postConfirmStaffType) =>
  client.post('/api/center/staff/confirm', data);
export const postConfirmStaff = withErrorHandling(postConfirmStaffRaw);

// 비밀번호 변경
type postChangePwdType = {
  oldPassword: string;
  newPassword: string;
};
export const postChangePwdRaw = (data: postChangePwdType) =>
  client.post('/api/app/change-pwd', data);
export const postChangePwd = withErrorHandling(postChangePwdRaw);

// 회원 탈퇴
export const postWithdrawalRaw = () => client.post('/api/withdrawal-submit');
export const postWithdrawal = withErrorHandling(postWithdrawalRaw);
