import styled from 'styled-components';
type ContainerType = {
  top?: string;
};
export const Container = styled.div<ContainerType>`
  position: absolute;
  right: 0;
  width: 100px;
  background-color: rgba(24, 24, 24);
  border: 1px solid var(--c800);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  top: ${({ top }) => top || '16px'};
`;
type ItemType = {
  color?: string;
};
export const Item = styled.div<ItemType>`
  width: 100%;
  height: 27px;
  font-size: 1.4rem;
  color: ${({ color }) => color || 'white'};
  border-bottom: 1px solid var(--c800);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :last-child {
    border-bottom: none;
  }
`;
