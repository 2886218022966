import Header from 'components/Header';
import Navigation from 'components/Navigation';
import RecommandList from 'components/RecommandList';
import { Container } from 'styles/style';
import { IoSearchOutline } from 'react-icons/io5';
import * as SearchS from '../components/SearchInput/style';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RecommandPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container>
      <Header />
      <Navigation />
      <SearchS.Container onClick={() => navigate('/search')}>
        <IoSearchOutline size={18} color='71727E' />
        <SearchS.Input
          placeholder={t('스포츠 아카데미(센터), 관심운동 찾기')}
        />
      </SearchS.Container>
      <RecommandList />
    </Container>
  );
};
export default RecommandPage;
