import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 30px;
`;
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
export const SectionTitle = styled.span`
  width: 100%;
  max-width: 105px;
  font-weight: 500;
  font-size: 1.8rem;
  letter-spacing: -0.5px;
  word-break: keep-all;
`;
export const SectionSubTitle = styled.span`
  font-size: 1.2rem;
  color: var(--c400);
`;
