import * as S from './style';
import { IoSearchOutline } from 'react-icons/io5';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type SearchInputType = {
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
};
const SearchInput = ({ searchInput, setSearchInput }: SearchInputType) => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <IoSearchOutline size={18} color='71727E' />
      <S.Input
        placeholder={t('스포츠 아카데미(센터), 관심운동 찾기')}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
    </S.Container>
  );
};

export default SearchInput;
