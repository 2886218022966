import PageHeader from 'components/PageHeader';
import { CenterType, ProfileType } from 'constants/types/type';

import * as S from './style';
import { useEffect, useState } from 'react';
import { deviceSizesNum } from 'constants/theme';
import SwiperBannerImage from 'components/CenterDetail/SwiperBannerImage';
import Badge from 'components/CenterDetail/Badge';
import Navigation from 'components/CenterDetail/Navigation';
import All from 'components/CenterDetail/Sections/All';
import OnlineSession from 'components/CenterDetail/Sections/OnlineSession';
import Community from 'components/CenterDetail/Sections/Community';
import Feedback from 'components/CenterDetail/Sections/Feedback';
import Member from 'components/CenterDetail/Sections/Member';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Margin } from 'styles/style';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

export const NavItems = {
  all: '전체',
  session: '온라인세션',
  community: '커뮤니티',
  feedback: '피드백',
  member: '멤버',
} as const;
type NavKeys = keyof typeof NavItems;
export type NavItemType = (typeof NavItems)[NavKeys];

type CenterDetailBeforeSignupType = {
  center: CenterType;
};
const CenterDetailBeforeSignup = ({ center }: CenterDetailBeforeSignupType) => {
  const { nav } = useParams<{ nav?: string }>();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [bannerImages, setBannerImages] = useState<any[]>([]);
  const { t } = useTranslation();

  const getNavKeyByValue = (value: NavItemType): NavKeys | undefined => {
    return (Object.keys(NavItems) as Array<NavKeys>).find(
      (key) => NavItems[key] === value
    );
  };
  const pathArray = location.pathname.split('/');
  const pathArrayLength = pathArray.length;
  const path = pathArray[pathArrayLength - 1] as NavItemType;
  const [activeNavItem, setActiveNavItem] = useState<NavItemType>(
    path || '전체'
  );

  useEffect(() => {
    if (!center) {
      alert(t('존재하지 않는 센터입니다'));
      window.history.back();
    }

    setBannerImages([...center.bannerImages]);
    if (
      window.innerWidth > deviceSizesNum.tablet &&
      center.bannerImages.length <= 1
    ) {
      setBannerImages([...center.bannerImages, ...center.bannerImages]);
    }
  }, [center]);
  useEffect(() => {
    if (nav && isNavKey(nav)) setActiveNavItem(NavItems[nav]);
  }, [nav]);
  useEffect(() => {
    const navKey = getNavKeyByValue(activeNavItem);
    if (navKey) {
      navigate({
        pathname: `/center/detail/${navKey}`,
        search: searchParams.toString(),
      });
    }
  }, [activeNavItem]);
  const isNavKey = (key: string): key is NavKeys => {
    return key in NavItems;
  };

  let delay = 100;
  let timer: any = null;
  window.addEventListener('resize', () => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      if (
        window.innerWidth > deviceSizesNum.tablet &&
        center.bannerImages.length <= 1 &&
        bannerImages.length < 2
      ) {
        setBannerImages([...bannerImages, ...bannerImages]);
      } else if (
        window.innerWidth < deviceSizesNum.tablet &&
        center.bannerImages.length <= 1
      ) {
        setBannerImages([...center.bannerImages]);
      } else {
        setBannerImages([...bannerImages]);
      }
    }, delay);
  });
  return (
    <>
      <PageHeader
        title=''
        isLineShow={false}
        backgroundColor='transparent'
        isProfileShow={true}
        backLink={'/dashboard/recommand'}
      />
      <SwiperBannerImage bannerImages={bannerImages} />
      <S.Container>
        {/* 뱃지 */}
        <Badge centerGrade={center.centerGrade} />

        {/* 센터명 */}
        <S.CenterName>{center.displayName}</S.CenterName>
      </S.Container>
      <Navigation
        activeNavItem={activeNavItem}
        setActiveNavItem={setActiveNavItem}
      />
      <S.Container>
        {activeNavItem === '전체' && <All />}
        {activeNavItem === '온라인세션' && <OnlineSession />}
        {activeNavItem === '커뮤니티' && <Community />}
        {activeNavItem === '피드백' && <Feedback />}
        {activeNavItem === '멤버' && <Member />}
      </S.Container>
      <Margin margin='0 0 120px 0' />
    </>
  );
};

export default CenterDetailBeforeSignup;
