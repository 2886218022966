import { theme } from 'constants/theme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  flex-wrap: nowrap;
  display: flex;
  gap: 16px;
  overflow: auto;
  padding-bottom: 10px;
`;
