import {
  Title,
  Container,
  Contents,
  TermBody,
  TermTitle,
  TermBox,
  Dl,
  Dt,
  Dd,
  Table,
  Th,
  Td,
} from 'styles/TermStyle';
import { Margin } from 'styles/style';
import { CloseButtonOnTerms } from 'components/CloseButtonOnTerms';

export const Term2_en = () => {
  return (
    <Container>
      <CloseButtonOnTerms />
      <Title>Real-Feed Privacy Policy</Title>
      <Margin margin='0 0 20px 0' />
      <Dd>
        Hello, we are Spanlink Co., Ltd. (hereinafter referred to as the
        "Company"), the operator of the Real-Feed service. The Company complies
        with the relevant laws and regulations regarding the protection of
        personal information, including the Act on Promotion of Information and
        Communications Network Utilization and Information Protection, the
        Protection of Communications Secrets Act, the Telecommunications
        Business Act, and the Personal Information Protection Act. This Privacy
        Policy applies to all users (both members and non-members) of the
        Real-Feed service and contains the following information:
      </Dd>
      <Margin margin='0 0 20px 0' />
      <TermBox>
        <Dl>
          <Dt>1. Purpose of Collection and Use of Personal Information</Dt>
          <Dd>
            1.1. The Company collects personal information through the following
            methods:
            <br />• Collection through the website, application, written forms,
            fax, phone, customer service inquiries, email, and event
            participation.
            <br /> 1.2. The Company processes personal information for the
            following purposes, and will not use it for any purposes other than
            those listed below. If there is a change in the purpose of
            collection and use, the Company will seek separate prior consent
            from users.
            <br />• The Company collects or uses members' personal information
            to provide smooth services and enhanced experiences. For instance,
            members' email addresses, date of birth, gender, and device
            information may be required to provide the basic functions or
            various specialized features of the service according to the agreed
            terms of use.
            <br />• It is also necessary when the Company needs to notify
            members individually or when members have inquiries or disputes
            related to service use.
            <br />• In addition, members' personal information is needed for new
            service development, function improvement, personalized service
            provision and advertising, event participation, provision of various
            promotional information, and prevention and blocking of improper use
            that may harm members in violation of laws or terms of service.
            <br />• The Company may also use personal information for marketing
            purposes, such as providing event and promotional information, only
            with the member's consent.
          </Dd>
          <Dt>2. Personal Information Items Collected</Dt>
          <Dd>
            2.1. The following personal information of users is collected to
            provide better services:
            <br />
            <Margin margin='10px 0 0 0' />
            <Table>
              <tbody>
                <tr>
                  <Th>Category</Th>
                  <Th>Items Collected</Th>
                </tr>
                <tr>
                  <Td>Sign Up</Td>
                  <Td>
                    Nickname, Email Address, Password, Mobile Phone Number
                  </Td>
                </tr>
              </tbody>
            </Table>
            <br />
            2.2. In addition to the items mentioned in 2.1, IP Address, cookies,
            visit dates, service usage records, bad usage records, device
            information, and location information (when agreed to the
            location-based service terms) may be collected during service use or
            app execution. Email addresses or phone numbers may also be
            collected during customer inquiries and processing.
            <br /> 2.3. The Company may collect additional information, such as
            name, date of birth, gender, nationality, mobile phone number, or
            i-PIN number, for user identification, age verification, or to
            confirm the user's identity during service use. For minors,
            information on the legal guardian may be automatically generated and
            collected.
            <br /> 2.4. The Company does not collect sensitive personal
            information that may infringe on users' basic human rights (e.g.,
            race, ideology, political orientation, criminal records, medical
            information). Even if users do not provide optional information,
            there are no restrictions on using the services provided by the
            Company.
          </Dd>
          <Dt>3. Retention and Use Period of Personal Information</Dt>
          <Dd>
            3.1. The Company retains and uses personal information only during
            the period the user is a member. When a user withdraws their consent
            or when the purpose of collection or use is achieved, the personal
            information will be destroyed immediately. In such cases, the
            personal information will be processed so that it cannot be accessed
            or used for any purpose. However, the Company may retain personal
            information for a specified period even after account deletion for
            the following reasons:
            <br />• For internal policy reasons.
            <br />• If an investigation or inquiry related to a violation of
            laws is ongoing: Until the investigation is concluded.
            <br />• If there are remaining debts or claims between the Company
            and the user: Until settlement.
            <br />• In accordance with related laws, such as the Commercial Act,
            the Act on the Consumer Protection in Electronic Commerce, etc., the
            Company will retain user information for a specified period. During
            this time, the information will only be used for the preservation
            purpose, and the retention period is as follows:
            <br />
            ◼ Service usage records, access logs, IP information <br />
            • Retention Period: 1 year. Reason: Protection of Communications
            Secrets Act <br />
            ◼ Records related to display/advertising <br />
            • Retention Period: 6 months. Reason: Act on the Consumer Protection
            in Electronic Commerce, etc.
            <br />
            ◼ Records related to contract or subscription withdrawal <br />•
            Retention Period: 5 years. Reason: Act on the Consumer Protection in
            Electronic Commerce, etc.
            <br />◼ Records related to payment and supply of goods (order
            history) <br />• Retention Period: 5 years. Reason: Act on the
            Consumer Protection in Electronic Commerce, etc.
            <br />
            ◼ Records related to consumer complaints or dispute resolution
            (consultation history) <br />• Retention Period: 3 years. Reason:
            Act on the Consumer Protection in Electronic Commerce, etc.
            <br />• However, records of improper use of the service will be
            retained for 1 year to prevent disputes arising from improper use
            and will be destroyed afterward. Improper use refers to:
            <br />① Transactions that violate the Company's policies (including
            terms and conditions).
            <br />② Transactions that infringe upon the rights or interests of
            the Company, members, or third parties.
            <br /> ③ Transactions equivalent to ① and ②.
            <br />
            3.2. If there is no service usage record for one year, the user's
            personal information will be destroyed or separately stored and
            managed. The Company will notify the user in advance by email at
            least 30 days before converting the account to dormant status.
          </Dd>
          <Dt>4. Destruction of Personal Information</Dt>
          <Dd>
            4.1. Users' personal information will be permanently deleted by
            technical means to ensure that the records cannot be recovered after
            the purpose of providing the information is achieved or when the
            user cancels their membership (account deletion). Personal
            information transferred to a separate database (DB) after account
            deletion will be stored for a specified period in accordance with
            the Company's internal policies and relevant laws and regulations
            before being permanently deleted. The transferred personal
            information will not be used for any purpose other than retention
            unless required by law.
            <br /> 4.2. Personal information recorded or stored on paper will be
            shredded or incinerated, and personal information stored in
            electronic file formats will be deleted using technical methods to
            ensure the records cannot be recovered.
          </Dd>
          <Dt>
            5. Provision and Delegation of Personal Information to Third Parties
          </Dt>
          <Dd>
            5.1. The Company does not provide or disclose users' personal
            information to third parties beyond the scope of collection and use
            without the user's consent. However, if it is necessary to share
            personal information with partners or other third parties for better
            service, the Company will notify the user and obtain their consent.
            Additionally, personal information may be provided in accordance
            with legal provisions or at the request of investigative agencies
            following the procedures and methods set by law.
            <br /> 5.2. The Company does not use users' personal information for
            purposes other than providing its services or provide it to third
            parties without the user's consent. If third-party provision is
            necessary, the Company will notify the user and obtain separate
            consent. However, the following cases are exceptions:
            <br />• When the user violates the terms and conditions or engages
            in illegal activities that harm others or disrupt public order, and
            the disclosure of personal information is deemed necessary for legal
            action.
            <br />• When required by law or at the request of investigative
            agencies following legal procedures and methods.
            <br />• When the information is provided in a form that does not
            allow the identification of specific individuals for statistical
            purposes, academic research, market research, information provision,
            and notification emails.
            <br />• When the user has given prior consent.
            <br /> 5.3. The Company may delegate the processing of users'
            personal information to external parties for service improvement,
            prevention, and blocking of improper use, and stable personal
            information handling. The details of the entrusted organization and
            the content of the delegated work are as follows:
            <br /> Trustee: Amazon Web Services, Inc.
            <Margin margin='10px 0 0 0' />
            <Table>
              <tr>
                <Th>Trustee Name</Th>
                <Th>Delegated Work</Th>
                <Th>Retention and Use Period of Personal Information</Th>
              </tr>
              <tr>
                <Td>Amazon Web Services, Inc.</Td>
                <Td>System management through AWS</Td>
                <Td>
                  Until membership withdrawal or end of delegation contract
                </Td>
              </tr>
            </Table>
          </Dd>
          <Dt>6. Technical Measures for Encryption and Hacking Prevention</Dt>
          <Dd>
            6.1. The Company establishes and implements an internal management
            plan, installs and operates access control devices, and uses
            security features to prevent unauthorized access and to secure
            personal information from loss, theft, leakage, alteration, or
            damage. The Company also takes technical and managerial measures to
            ensure the safety of users' personal information.
            <br />- Encryption of personal information: The Company encrypts
            personal information, such as passwords and payment methods, in
            accordance with legal standards and takes separate security
            measures, such as encrypting files and transmission data, to protect
            users' personal information.
            <br />- Measures against hacking: The Company makes every effort to
            prevent the leakage or damage of users' personal information due to
            hacking or computer viruses. The Company regularly backs up data,
            uses the latest antivirus programs to prevent the leakage or damage
            of personal information and data, and secures personal information
            transmission through encrypted communication. The Company also
            controls unauthorized access from external sources using an
            intrusion prevention system and strives to equip itself with all
            possible technical measures to ensure security.
            <br />- Minimization and training of handling personnel: The Company
            limits personal information handling to designated personnel and
            regularly updates passwords for them. The Company also provides
            regular training to ensure that the Privacy Policy is strictly
            followed, thereby striving for practical personal information
            protection.
            <br />
            6.2. Despite the measures mentioned in 6.1, the Company is not
            responsible for issues arising from the intentional or negligent
            disclosure of personal information, such as nickname, password, or
            resident registration number, due to the user's own actions or
            internet problems.
          </Dd>
          <Dt>7. User Rights</Dt>
          <Dd>
            7.1. Users can view or modify their personal information at any time
            and may request the withdrawal of their consent to the collection
            and use of personal information (termination, account deletion).
            <br />
            7.2. Users and legal representatives can view or modify their
            personal information at any time and may request the withdrawal of
            their consent to the collection and use of personal information
            (termination of subscription). If this process is not smooth, please
            contact customer service for prompt assistance.
          </Dd>
          <Dt>
            8. Installation, Operation, and Rejection of Automatic Collection
            Devices
          </Dt>
          <Dd>
            8.1. The Company uses cookies and similar technologies to store and
            retrieve users' information to provide smooth services. A cookie is
            a small text file sent by the server operating the website to the
            user's browser, which may be stored on the user's computer hard
            drive (meaning of cookies).
            <br />
            8.2. The Company uses cookies to analyze users' access frequency,
            visit times, track users' preferences and interests, monitor
            participation in various events and visit counts, and provide target
            marketing and personalized services (purpose of using cookies).{' '}
            <br />
            8.3. Users have the option to accept or reject cookie installation.
            Users can set options in their web browser to allow all cookies, be
            prompted for confirmation each time cookies are stored, or reject
            the storage of all cookies (cookie installation, operation, and
            rejection). However, if users reject cookie installation, some
            services requiring login may be difficult to use.
            <br />
            8.4. To reject cookie installation, users can select the option in
            their web browser to allow all cookies, confirm each time cookies
            are stored, or reject all cookies.
            <br />
            8.5. To provide an internet environment similar to that on PC, the
            Company may use cookies on mobile devices (e.g., smartphones,
            tablets). Cookies are collected in accordance with relevant laws and
            guidelines issued by regulatory agencies. However, cookies that can
            identify a person, including personal information, will not be
            collected without the user's prior consent.
            <br />
            8.6. Users can choose whether to allow cookies in the web browser
            settings on mobile devices. The process may differ slightly
            depending on the operating system and web browser used, but in most
            cases, users can decide whether to allow cookies or delete all
            existing cookies through the settings menu. However, rejecting
            cookie storage may cause inconvenience when using some services
            requiring login.
          </Dd>
          <Dt>9. Personal Information Protection Officer</Dt>
          <Dd>
            The Company designates the following Personal Information Protection
            Officer to oversee personal information processing and to address
            user complaints and damage relief related to personal information
            processing:
            <br />
            ◼ Personal Information Protection Officer <br />
            Name: Youngkwon Jung <br />
            Position: CEO <br />
            Contact: +82-2-555-0634, spanlink@naver.com
            <br />◼ Personal Information Protection Department
            <br /> Name: Changwoon Kim <br /> Contact: +82-2-555-0634,
            kcw@whistle-pro.com
          </Dd>
          <Dt>10. Requests for Access to Personal Information</Dt>
          <Dd>
            If you need to report or consult regarding a personal information
            infringement, please contact the following institutions:
            <br />
            ◼ Personal Information Infringement Report Center (operated by the
            Korea Internet & Security Agency): 118 (privacy.kisa.or.kr)
            <br />
            ◼ Supreme Prosecutors' Office Cybercrime Investigation Unit: 1301
            (spo.go.kr)
            <br />◼ National Police Agency Cyber Safety Keeper: 182
            (police.go.kr)
          </Dd>
          <Dt>11. Company's Duty of Notification</Dt>
          <Dd>
            If there are any additions, deletions, or modifications to the
            current Privacy Policy, the Company will notify users through the
            platform at least 7 days before the changes take effect. However, if
            there are significant changes, such as changes to the collection
            items or purposes of use, or the provision of personal information
            to third parties, the Company will notify users at least 30 days in
            advance. This Privacy Policy is effective as of September 18, 2023.
          </Dd>
        </Dl>
      </TermBox>
    </Container>
  );
};
