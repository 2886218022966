import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 64px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
`;
export const FormWrapper = styled.div`
  width: 375px;
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
`;
export const Form = styled.form`
  width: 100%;
  background-color: #2c2d34;
  display: flex;
  flex-direction: column;
  padding: 24px;
  color: white;
  justify-content: center;
  border-radius: 24px;
  gap: 24px;

  > input,
  textarea {
    flex-shrink: 0;
  }
`;
export const Title = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 20.8px;
  letter-spacing: -0.02em;
  text-align: center;
`;
export const ImageContainer = styled.div`
  position: relative;
  margin: 0 auto;
`;
export const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
`;
export const PlusButton = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const Input = styled.input`
  background-color: var(--c700);
  width: 100%;
  height: 50px;
  border-radius: 8px;
  color: white;
  padding: 16px;
`;
export const SubTitle = styled.span`
  font-size: 1.25rem;
  color: var(--c100);
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--c800);
`;
export const TextareaContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
`;
export const Textarea = styled.textarea`
  background-color: var(--c700);
  width: 100%;
  height: auto;
  min-height: 100px;
  border-radius: 8px;
  color: white;
  padding: 16px;
  border: none;
  resize: none;
`;
export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;
export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  flex: 1;
  border-radius: 8px;
  font-weight: 700;
`;
export const CancleButton = styled(Button)`
  background-color: transparent;
  border: 2px solid white;
  color: white;
`;
export const SubmitButton = styled(Button)`
  background-color: var(--primary);
  color: black;
`;
export const ErrorSpan = styled.span`
  color: var(--semantic-red);
  margin-top: -10px;
`;
