// useVideoControl.js
import { useEffect, useRef, useState } from 'react';

const useVideoControl = (isMutedWhenPlay: boolean = false) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(true); // autoplay true라서
  const [isVideoEnd, setIsVideoEnd] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoadEnded, setIsLoadEnded] = useState(false);

  const play = () => {
    try {
      if (videoRef.current) {
        const video = videoRef.current;
        setIsVideoPlaying(true);
        if (isMutedWhenPlay) {
          // IOS 정책상 둘 중 하나의 비디오에만 소리가 나야함. 이 제한이 필요한 곳에서 사용
          video.muted = true;
        }
        video.play().catch((e) => {
          console.error('Video play error:', e);
          // alert('Video play error: ' + e.message);
        });
      }
    } catch (e) {
      alert(e);
    }
  };

  const pause = () => {
    const video = videoRef.current;
    setIsVideoPlaying(false);
    if (video) {
      video.pause();
    }
  };

  const move = (time: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time / 1000;
    }
  };

  const currentBarHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!videoRef.current || !videoRef.current.duration) {
      console.error('Video not loaded or duration not available.');
      return;
    }

    const rect = e.currentTarget.getBoundingClientRect();
    const { x, width } = rect;
    if (width === 0) {
      console.error('Progress bar width is zero.');
      return; // This prevents division by zero.
    }

    const selectedPoint = ((e.clientX - x) / width) * 100;
    const newTime = (videoRef.current.duration * selectedPoint) / 100;

    if (!isFinite(newTime)) {
      console.error('Computed time is non-finite.', { selectedPoint, newTime });
      return; // Guard against non-finite time values.
    }

    videoRef.current.currentTime = newTime;
  };

  useEffect(() => {
    const preventTouchMove = (e: TouchEvent) => {
      e.preventDefault();
    };

    document.addEventListener('touchmove', preventTouchMove, {
      passive: false,
    });

    return () => {
      document.removeEventListener('touchmove', preventTouchMove);
    };
  }, []);

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (
        progressRef.current &&
        videoRef.current &&
        videoRef.current.currentTime
      ) {
        const progressPercentage =
          (videoRef.current.currentTime / videoRef.current.duration) * 100;
        progressRef.current.style.setProperty(
          '--progress-width',
          `${progressPercentage}%`
        );
      }
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging && videoRef.current && progressRef.current) {
        const rect = progressRef.current.getBoundingClientRect();
        const movePosition = e.clientX - rect.left;
        const movePercentage = movePosition / rect.width;
        videoRef.current.currentTime =
          movePercentage * videoRef.current.duration;
      }
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (isDragging && videoRef.current && progressRef.current) {
        const rect = progressRef.current.getBoundingClientRect();
        const movePosition = e.touches[0].clientX - rect.left;
        const movePercentage = movePosition / rect.width;
        videoRef.current.currentTime =
          movePercentage * videoRef.current.duration;
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };
    const handleTouchEnd = () => {
      setIsDragging(false);
    };
    const handleEnded = () => {
      setIsVideoEnd(true);
    };
    const handleError = (e: Event) => {
      console.error('Video Error:', e);
    };
    const handleLoadEnded = () => {
      setIsLoadEnded(true);
    };
    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
      videoRef.current.addEventListener('ended', handleEnded);
      videoRef.current.addEventListener('error', handleError); // Add error event listener
      videoRef.current.addEventListener('loadeddata', handleLoadEnded);
    }
    if (progressRef.current) {
      progressRef.current.addEventListener('mousemove', handleMouseMove);
      progressRef.current.addEventListener('mouseup', handleMouseUp);
      progressRef.current.addEventListener('touchmove', handleTouchMove);
      progressRef.current.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
        videoRef.current.removeEventListener('ended', handleEnded);
        videoRef.current.removeEventListener('error', handleError);
        videoRef.current.removeEventListener('loadeddata', handleLoadEnded);
      }
      if (progressRef.current) {
        progressRef.current.removeEventListener('mousemove', handleMouseMove);
        progressRef.current.removeEventListener('mouseup', handleMouseUp);
        progressRef.current.removeEventListener('touchmove', handleTouchMove);
        progressRef.current.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [isDragging, videoRef, progressRef]);

  const startDragging = () => {
    setIsDragging(true);
  };

  return {
    videoRef,
    progressRef,
    isVideoPlaying,
    play,
    pause,
    move,
    currentBarHandler,
    startDragging,
    isDragging,
    isVideoEnd,
    isLoadEnded,
  };
};

export default useVideoControl;
