import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './style';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<'recommand' | 'myActivity'>(
    'recommand'
  );
  const { t } = useTranslation();

  useEffect(() => {
    switch (location.pathname) {
      case '/dashboard/recommand':
        setActiveItem('recommand');
        break;
      case '/dashboard/myActivity':
        setActiveItem('myActivity');
        break;
    }
  }, [location]);

  return (
    <S.Container>
      <S.NavItem
        onClick={() => navigate('/dashboard/recommand')}
        isActive={activeItem === 'recommand'}
      >
        <S.NavSpan>{t('추천')}</S.NavSpan>
      </S.NavItem>
      <S.NavItem
        onClick={() => navigate('/dashboard/myActivity')}
        isActive={activeItem === 'myActivity'}
      >
        <S.NavSpan>{t('내활동')}</S.NavSpan>
      </S.NavItem>
    </S.Container>
  );
};
export default Navigation;
