import { theme } from 'constants/theme';
import styled from 'styled-components';

export const RelativeDiv = styled.div`
  width: 100%;
  max-width: 950px;
  position: relative;
  margin-top: 100px;
  min-height: 150px;

  @media screen and (max-width: 1100px) {
    display: none;
  }
`;
export const Contaier = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: space-between;
  padding: 0 16px;
  align-items: top;
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  min-width: 240px;
`;
export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 192px;
  span {
    color: var(--c500);
    letter-spacing: -0.2px;
  }
`;
export const CSContainer = styled.div`
  width: 100%;
  max-width: 296px;
  height: 71px;
  display: flex;
  padding: 16px;
  justify-content: center;
  gap: 6px;
  align-items: flex-start;
  background-color: transparent;
  border-radius: 8px;
  border: 2px solid var(--c400, #b0b2bf);
  flex-shrink: 0;

  cursor: pointer;
`;
export const WrapIcon = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const MetaContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;
export const MetaSpan = styled.span`
  font-size: 1rem;
  white-space: nowrap;
`;
