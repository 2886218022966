import client from './client';
import { withErrorHandling } from './errorHandling';

// 커뮤니티 리스트
type postCommunityListType = {
  data: {
    centerId: string;
    limit: number;
    offset: number;
    orderBy: string;
  };
};
const postCommunityListRaw = async ({ data }: postCommunityListType) =>
  client.post('/api/center/comm/list', data);
export const postCommunityList = withErrorHandling(postCommunityListRaw);

// 커뮤니티 게시글 상세
type postCommunityType = {
  params: {
    postId: string;
  };
  data: {
    centerId: string;
  };
};
const postCommunityRaw = ({ params, data }: postCommunityType) =>
  client.post(`/api/center/comm/${params.postId}`, data);
export const postCommunity = withErrorHandling(postCommunityRaw);

// 커뮤니티 생성
type postCreateCommunityType = {
  centerId: string;
  content: string;
  isNoti: boolean;
  uploadImage:
    | {
        fileName: string;
        ratio: string;
      }
    | {};
  uploadVideo:
    | {
        fileName: string;
        ratio: string;
        duration: number;
      }
    | {};
};
const postCreateCommunityRaw = (data: postCreateCommunityType) =>
  client.post('/api/center/comm/create', data);
export const postCreateCommunity = withErrorHandling(postCreateCommunityRaw);

// 커뮤니티 수정
type postUpdateCommunityType = {
  params: {
    postId: string;
  };
  data: {
    centerId: string;
    content: string;
    isNoti: boolean;
    deleteImage?: string;
    deleteVideo?: string;
    uploadImage?: {
      fileName?: string;
      ratio?: string;
    };
    uploadVideo?: {
      fileName?: string;
      ratio?: string;
      duration?: number;
    };
  };
};
const postUpdateCommunityRaw = ({ params, data }: postUpdateCommunityType) =>
  client.post(`/api/center/comm/${params.postId}/update`, data);
export const postUpdateCommunity = withErrorHandling(postUpdateCommunityRaw);

// 커뮤니티 게시글 삭제
type postDeletePostType = {
  postId: string;
  centerId: string;
};
const postDeletePostRaw = (data: postDeletePostType) =>
  client.post('/api/center/comm/delete', data);
export const postDeletePost = withErrorHandling(postDeletePostRaw);

// 커뮤니티 댓글 리스트
type postReplyListType = {
  params: {
    postId: string;
  };
  data: {
    centerId: string;
    parentReplyId: string;
    limit: number;
    offset: number;
    orderBy: string;
  };
};
const postReplyListRaw = ({ params, data }: postReplyListType) =>
  client.post(`/api/center/comm/${params.postId}/reply/comment`, data);
export const postReplyList = withErrorHandling(postReplyListRaw);

// 커뮤니티 대댓글 리스트
type postSubReplyListType = {
  params: {
    postId: string;
  };
  data: {
    centerId: string;
    limit: number;
    offset: number;
    orderBy: string;
  };
};
const postSubReplyListRaw = ({ params, data }: postSubReplyListType) =>
  client.post(`/api/center/comm/${params.postId}/reply`, data);
export const postSubReplyList = withErrorHandling(postSubReplyListRaw);

// 커뮤니티 댓글 생성
type postCreateReplyType = {
  params: {
    postId: string;
  };
  data: {
    centerId: string;
    content: string;
    orderBy: string;
    parentReplyId: string | null;
  };
};
const postCreateReplyRaw = ({ params, data }: postCreateReplyType) =>
  client.post(`/api/center/comm/${params.postId}/reply/create`, data);
export const postCreateReply = withErrorHandling(postCreateReplyRaw);

// 커뮤니티 댓글 수정
type postEditReplyType = {
  params: {
    postId: string;
  };
  data: {
    centerId: string;
    content: string;
    orderBy: string;
    replyId: string;
  };
};
const postEditReplyRaw = ({ params, data }: postEditReplyType) =>
  client.post(`/api/center/comm/${params.postId}/reply/update`, data);
export const postEditReply = withErrorHandling(postEditReplyRaw);

// 커뮤니티 댓글 삭제
type postDeleteReplyType = {
  params: {
    postId: string;
  };
  data: {
    centerId: string;
    replyId: string;
  };
};
const postDeleteReplyRaw = ({ params, data }: postDeleteReplyType) =>
  client.post(`/api/center/comm/${params.postId}/reply/delete`, data);
export const postDeleteReply = withErrorHandling(postDeleteReplyRaw);

// 커뮤니티 비디오 업로드 완료
type postPostCompleteType = {
  postId: string;
  centerId: string;
};
const postPostCompleteRaw = (data: postPostCompleteType) =>
  client.post('/api/center/comm/vodUpdateCompleted', data);
export const postPostComplete = withErrorHandling(postPostCompleteRaw);

// 좋아요
type postAddLikeType = {
  centerId: string;
  postId: string;
  replyId?: string;
  postType?: string;
  isLikeData: boolean;
};
const postAddLikeRaw = (data: postAddLikeType) =>
  client.post('/api/center/comm/addLike', data);
export const postAddLike = withErrorHandling(postAddLikeRaw);
