import Header from 'components/Header';
import MyActivity from 'components/MyActivity';
import Navigation from 'components/Navigation';
import { useState } from 'react';
import { Container } from 'styles/style';

const MyActivityPage = () => {
  const [selectedTag, setSelectedTag] = useState<string[]>([]);

  return (
    <Container>
      <Header />
      <Navigation />
      <MyActivity />
    </Container>
  );
};
export default MyActivityPage;
