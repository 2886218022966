import { theme } from 'constants/theme';
import { deviceSizes } from 'constants/theme';
import styled from 'styled-components';
type videoType = {
  ratio: string;
};
export const WholeContainer = styled.div`
  margin-top: 60px;
`;
export const Container = styled.div<videoType>`
  display: flex;
  border-radius: 14px;
  width: 100%;
  overflow: hidden;
  flex-direction: ${({ ratio }) => (ratio === 'landscape' ? 'column' : 'row')};
  margin: 20px 0;
`;
export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
`;
export const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 5px;
  background-color: var(--c500);
  cursor: pointer;
`;

type CurrentBarType = {
  width: number;
};
export const CurrentBar = styled.div<CurrentBarType>`
  position: absolute;
  left: 0;
  max-width: 100%;
  width: ${({ width }) => (width ? `${width}%` : '0%')};
  height: 5px;
  background-color: var(--primary);
  cursor: pointer;
`;

export const ConvertButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  top: 0;
  left: 0;
  padding: 13px;
  cursor: pointer;
`;
