import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const ArrowDown = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='8'
    height='4'
    viewBox='0 0 8 4'
    fill='none'
    style={{ transform: `rotate(${props.rotate}deg)` }}
  >
    <g id='Arrows Button Down'>
      <path
        id='Vector 6902'
        d='M1 0.5L3.29289 2.79289C3.62623 3.12623 3.79289 3.29289 4 3.29289C4.20711 3.29289 4.37377 3.12623 4.70711 2.79289L7 0.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default ArrowDown;
