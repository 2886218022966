import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { Flex, Span } from 'styles/style';

const CompleteIconText = () => {
  const { t } = useTranslation();

  return (
    <Flex gap='3px'>
      <Icon icon='ToggleCheckButton_Active' width={16} height={16} />
      <Span fontSize='14px' color='var(--primary)' padding='1px 0 0 0'>
        {t('완료')}
      </Span>
    </Flex>
  );
};
export default CompleteIconText;
