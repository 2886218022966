import { VideoStatus } from 'constants/types/type';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { deviceSizes } from 'constants/theme';

type VideoRecorderType = {
  ratio: 'landscape' | 'portrait';
  duration: number;
  setRecordVideoBlob: (value: React.SetStateAction<Blob>) => void;
  currentStatus: VideoStatus;
  setCurrentStatus: (value: React.SetStateAction<VideoStatus>) => void;
};

const VideoRecorder = ({
  ratio,
  duration,
  setRecordVideoBlob,
  currentStatus,
  setCurrentStatus,
}: VideoRecorderType) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const videoChunks = useRef<Blob[]>([]);
  const videoStreamRef = useRef<MediaStream | null>(null);

  const getMediaPermission = async () => {
    try {
      const videoConstraints = { video: true };
      let videoStream: MediaStream;

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        videoStream = await navigator.mediaDevices.getUserMedia(
          videoConstraints
        );
      } else {
        const getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia;
        if (getUserMedia) {
          videoStream = await new Promise<MediaStream>((resolve, reject) => {
            getUserMedia.call(navigator, videoConstraints, resolve, reject);
          });
        } else {
          console.error('This browser does not support getUserMedia API');
          return;
        }
      }

      videoStreamRef.current = videoStream;

      if (videoRef.current) {
        videoRef.current.srcObject = videoStream;
        videoRef.current.muted = true;
        await videoRef.current.play().catch((error) => {
          console.error('Video play error:', error);
        });
      } else {
        console.error('video ref is not here');
      }

      const combinedStream = new MediaStream([...videoStream.getVideoTracks()]);

      const mimeType = isMobile ? 'video/mp4' : 'video/webm';
      const recorder = new MediaRecorder(combinedStream, { mimeType });

      // Events
      recorder.ondataavailable = (e) => {
        if (typeof e.data === 'undefined') return;
        if (e.data.size === 0) return;
        videoChunks.current.push(e.data);
      };

      recorder.onstop = () => {
        const videoBlob = new Blob(videoChunks.current, { type: mimeType });
        setRecordVideoBlob(videoBlob);
        // 비디오 트랙 중지
        if (videoStreamRef.current) {
          videoStreamRef.current.getTracks().forEach((track) => track.stop());
        }
      };

      mediaRecorder.current = recorder;
    } catch (err: any) {
      if (err.name === 'NotAllowedError') {
        console.error('Permission to access media devices was denied.');
      } else {
        console.error('getUserMedia error:', err);
      }
    }
  };

  useEffect(() => {
    if (!mediaRecorder.current) {
      getMediaPermission();
    }
  }, []);

  useEffect(() => {
    if (mediaRecorder.current && currentStatus === 'coaching_start') {
      mediaRecorder.current.start();

      const stopRecordingTimer = setTimeout(() => {
        if (
          mediaRecorder.current &&
          mediaRecorder.current.state === 'recording'
        ) {
          mediaRecorder.current.stop();
          setCurrentStatus('session');
        }
      }, duration);

      return () => clearTimeout(stopRecordingTimer);
    }
  }, [currentStatus, duration, setCurrentStatus]);

  return (
    <Container ratio={ratio}>
      <VideoWrapper>
        <Video
          ref={videoRef}
          autoPlay={false}
          playsInline={true}
          muted={true} // 비디오 음소거
        />
        {/* {!isPlaying && <PlayOverlay>Click to Play</PlayOverlay>} */}
      </VideoWrapper>
    </Container>
  );
};

type ContainerType = {
  ratio: string;
};

const Container = styled.div<ContainerType>`
  position: absolute;
  width: ${({ ratio }) => (ratio === 'landscape' ? '224px' : '126px')};
  height: ${({ ratio }) => (ratio === 'landscape' ? '126px' : '224px')};
  background-color: black;
  bottom: 10px;
  right: 10px;
  border: 1px solid #00ff99;
  border-radius: 10px;
  overflow: hidden;

  @media ${`screen and (min-width: ${deviceSizes.tablet})`} {
    width: ${({ ratio }) => (ratio === 'landscape' ? '350px' : '196px')};
    height: ${({ ratio }) => (ratio === 'landscape' ? '196px' : '350px')};
  }

  @media ${`screen and (min-width: ${deviceSizes.laptop})`} {
    width: ${({ ratio }) => (ratio === 'landscape' ? '450px' : '253px')};
    height: ${({ ratio }) => (ratio === 'landscape' ? '253px' : '450px')};
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
  object-fit: cover;
`;

const PlayOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  font-size: 16px;
`;

export default VideoRecorder;
