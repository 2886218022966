import Icon from 'components/Icon';
import { ProfileImage } from 'styles/style';
import { timeAgo } from 'constants/common';
import { FeedbackType } from 'constants/types/type';
import { useNavigate } from 'react-router-dom';
import { CircleImage, Flex, PremiumBadge, Span } from 'styles/style';
import {
  AddFeedbackButton,
  ContentContainer,
  ContentWrapper,
  ItemContainer,
  MainContainer,
  ThumbImageWrapper,
  TopContainer,
} from './style';
import * as U_S from '../FeedbackItemForUser/style';

import Img from 'components/Img';
import { useTranslation } from 'react-i18next';
type FeedbackItemForStaffType = {
  feedback: FeedbackType;
  url?: string;
};
const FeedbackItemForStaff = ({ feedback, url }: FeedbackItemForStaffType) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <ItemContainer
        onClick={() =>
          navigate(
            `/feedback/detail?centerId=${feedback.center?.centerId}&sessionId=${feedback.sessionId}&feedbackId=${feedback.feedbackId}`
          )
        }
      >
        <CircleImage>
          <img
            src={
              feedback.requestedBy?.profileImage?.thumbnailImage ||
              '/defaultUserImage.png'
            }
            alt={t('프로필 이미지')}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null; // 재귀적인 호출을 방지
              target.src = '/defaultUserImage.png';
            }}
          />
        </CircleImage>
        <Flex flexDirection='column' gap='10px'>
          <TopContainer>
            <Span fontSize='14px' color='white'>
              {feedback.requestedBy?.nickname}
            </Span>
            <Span fontSize='12px' color='var(--c500)'>
              {timeAgo(feedback.createdAt)}
            </Span>
          </TopContainer>
          <MainContainer>
            <ThumbImageWrapper>
              {feedback.feedbackVideo.thumbnailImage?.originalImage && (
                <Img
                  src={
                    feedback?.response?.image?.originalImage ||
                    feedback?.response?.video?.thumbnailImage?.originalImage ||
                    feedback.feedbackVideo.thumbnailImage?.originalImage
                  }
                  alt={t('요청 영상 썸네일 이미지')}
                />
              )}
            </ThumbImageWrapper>
            <ContentContainer>
              <PremiumBadge>{t('회원전용')}</PremiumBadge>
              <U_S.Title>{feedback.sessionTitle}</U_S.Title>
              <U_S.Desc>{feedback.feedbackContent}</U_S.Desc>
              {feedback.response ? (
                <Flex alignItems='center' gap='3px'>
                  <Icon
                    icon='ToggleCheckButton_Active'
                    width={16}
                    height={16}
                  />
                  <Span fontSize='14px' color='var(--primary)'>
                    {t('응답완료')}
                  </Span>
                </Flex>
              ) : (
                <AddFeedbackButton
                  onClick={() =>
                    navigate(
                      `/feedback/detail?centerId=${feedback.centerId}&sessionId=${feedback.sessionId}&feedbackId=${feedback.feedbackId}`
                    )
                  }
                >
                  <Span>{t('피드백 남기기')}</Span>
                  <Icon icon='RightArrow' />
                </AddFeedbackButton>
              )}
            </ContentContainer>
          </MainContainer>
        </Flex>
      </ItemContainer>
    </>
  );
};
export default FeedbackItemForStaff;
