import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const Download = (props: IconPropsWithSVGProps) => (
  <svg
    width='15'
    height='15'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.00065 7.66667L6.00065 1M6.00065 7.66667C5.53383 7.66667 4.66167 6.33713 4.33398 6M6.00065 7.66667C6.46747 7.66667 7.33963 6.33713 7.66732 6'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.3337 9C11.3337 10.6547 10.9883 11 9.33366 11H2.66699C1.01233 11 0.666992 10.6547 0.666992 9'
      stroke='black'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Download;
