import Icon from 'components/Icon';
import { ProfileImage } from 'styles/style';
import { timeAgo } from 'constants/common';
import { FeedbackType, ProfileType } from 'constants/types/type';
import { useNavigate } from 'react-router-dom';
import { CircleImage, Flex, PremiumBadge, Span } from 'styles/style';
import * as S from './style';
import Img from 'components/Img';
import { useTranslation } from 'react-i18next';
type FeedbackItemForUserType = {
  feedback: FeedbackType;
  title?: string;
  circleImage: string;
  firstDesc?: string;
};
const FeedbackItemForUser = ({
  feedback,
  title,
  circleImage,
  firstDesc,
}: FeedbackItemForUserType) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    feedback && (
      <S.Container
        onClick={() =>
          navigate(
            `/feedback/detail?centerId=${feedback.centerId}&sessionId=${feedback.sessionId}&feedbackId=${feedback.feedbackId}`
          )
        }
      >
        <S.IconWrapper>
          <img
            src={circleImage || '/defaultUserImage.png'}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null; // 재귀적인 호출을 방지
              target.src = '/defaultUserImage.png';
            }}
          />
        </S.IconWrapper>
        <S.Contaienr2>
          <S.Title>{title || feedback.displayName}</S.Title>
          <S.SubDesc>
            {firstDesc && (
              <>
                <span>{firstDesc}</span>
                <span>-</span>
              </>
            )}
            <span>{timeAgo(feedback.modifiedAt)}</span>
          </S.SubDesc>
          <S.MainContainer>
            <S.ThumbImageWrapper>
              {feedback.feedbackVideo.thumbnailImage?.originalImage && (
                <Img
                  src={
                    feedback?.response?.image?.originalImage ||
                    feedback?.response?.video?.thumbnailImage?.originalImage ||
                    feedback.feedbackVideo.thumbnailImage?.originalImage
                  }
                  alt={t('요청 영상 썸네일 이미지')}
                />
              )}
            </S.ThumbImageWrapper>
            <S.ContentContainer>
              <Flex gap='8px'>
                {feedback.sessionAuthority === 'member_only' && (
                  <PremiumBadge>{t('회원전용')}</PremiumBadge>
                )}
                {feedback.response && (
                  <Flex alignItems='center' gap='3px'>
                    <Icon
                      icon='ToggleCheckButton_Active'
                      width={16}
                      height={16}
                    />
                    <Span fontSize='14px' color='var(--primary)'>
                      {t('완료')}
                    </Span>
                  </Flex>
                )}
              </Flex>
              <S.Title>{feedback.sessionTitle}</S.Title>
              <S.Desc>{feedback.feedbackContent}</S.Desc>
              <Flex gap='8px'>
                <Span>{t('피드백 확인하기')}</Span>
                <Icon icon='RightArrow' />
              </Flex>
            </S.ContentContainer>
          </S.MainContainer>
        </S.Contaienr2>
      </S.Container>
    )
  );
};
export default FeedbackItemForUser;
