import { useNavigate } from 'react-router-dom';
import * as S from './style';

type SportsTagViewType = {
  items: any[];
};
const SportsTagView = ({ items }: SportsTagViewType) => {
  const navigate = useNavigate();
  return (
    <S.TagContainer>
      {items.map((sportsTag, index) => (
        <S.TagImageContainer
          key={index}
          backgroundImage={sportsTag.image?.mediumImage}
          onClick={() => navigate(`/search?keyword=${sportsTag.tagName}`)}
        >
          <S.TagName>#{sportsTag.tagName}</S.TagName>
        </S.TagImageContainer>
      ))}
    </S.TagContainer>
  );
};
export default SportsTagView;
