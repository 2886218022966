import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

type ImgType = {
  src: string;
  alt: string;
  isTimestamp?: boolean;
};
const Img = ({ src, alt, isTimestamp = true }: ImgType) => {
  const [imageSrc, setImageSrc] = useState(src);
  const [isCompleted, setIsCompleted] = useState(false);
  const retryInterval = 1000; // 0.5초 (500ms)
  const maxRetries = 3; // 최대 재시도 횟수 설정 (옵션)
  const retryCountRef = useRef(0);
  const timeoutIdRef = useRef<number | NodeJS.Timeout | null>(null);
  const timeStamp = new Date().getTime();
  const defaultImage = '/default.png';

  const handleError = () => {
    if (retryCountRef.current >= maxRetries) {
      setImageSrc(defaultImage);
      setIsCompleted(true);
      return;
    }

    retryCountRef.current += 1; // 재시도 횟수 증가

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    timeoutIdRef.current = setTimeout(() => {
      const newTimeStamp = new Date().getTime();
      loadImage(`${src}?${newTimeStamp}`);
    }, retryInterval);
  };

  const loadImage = (url: string) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setIsCompleted(true);
      setImageSrc(url);
    };
    img.onerror = handleError;
  };

  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    retryCountRef.current = 0;
    setIsCompleted(false);
    loadImage(isTimestamp ? `${src}?${timeStamp}` : src);
  }, [src]);

  return (
    <img
      src={isCompleted ? imageSrc : defaultImage}
      alt={alt}
      style={{ display: isCompleted ? 'block' : 'none' }}
    />
  );
};

export default Img;
