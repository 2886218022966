import styled from 'styled-components';

export const MainVideoContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 80px;
`;
export const MainVideoWrapper = styled.div`
  width: 320px;
  height: 180px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid var(--c500);
`;
export const MetaDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  cursor: default;
  * {
  }
`;
const MetaDataText = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.5px;
`;
export const MetaDataTitle = styled(MetaDataText)`
  width: 54px;
  text-align: left;
  color: #fff;
`;
export const MetaDataValue = styled(MetaDataText)`
  letter-spacing: 0.8px;
  color: var(--c500);
  white-space: nowrap;
`;
export const MetaDataColumn = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
export const VideoChangeButton = styled.div`
  width: 115px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--c300);

  color: var(--c300);
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  cursor: pointer;
`;
export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 11px;
  align-items: center;
`;
export const ProgressTime = styled.div`
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: -0.5px;
`;
export const ProgressBar = styled.div`
  position: relative;
  width: 296px;
  height: 5px;
  background-color: var(--c500);
  cursor: pointer;
`;
type CurrentBarType = {
  width: number;
};
export const CurrentBar = styled.div<CurrentBarType>`
  position: absolute;
  left: 0;
  max-width: 296px;
  width: ${({ width }) => (width ? `${width}%` : '0%')};
  height: 5px;
  background-color: var(--primary);
  cursor: pointer;
`;
export const PlayButtonWrapper = styled.div`
  cursor: pointer;
`;
export const HorizontalBox = styled(MainVideoWrapper)`
  width: 320px;
  height: 180px;
`;
export const VerticalBox = styled(MainVideoWrapper)`
  width: 160px;
  height: 283px;
  margin: 0 30px;
`;
export const SortButton = styled.div`
  font-weight: 600;
  color: white;
  margin: 0px 5px;
  cursor: pointer;
`;
