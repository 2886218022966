import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 45px;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--c900);
  margin-bottom: 20px;
`;
type NavItemType = {
  isActive: boolean;
};
export const NavItem = styled.div<NavItemType>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-bottom: ${({ isActive }) =>
    isActive ? '1px solid var(--primary)' : 'none'};

  cursor: pointer;
`;
export const NavSpan = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.5px;
  user-select: none;
`;
