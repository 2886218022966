const Sun = () => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.3337 7C10.3337 8.84095 8.84127 10.3333 7.00033 10.3333C5.15938 10.3333 3.66699 8.84095 3.66699 7C3.66699 5.15905 5.15938 3.66667 7.00033 3.66667C8.84127 3.66667 10.3337 5.15905 10.3337 7Z'
      stroke='white'
    />
    <path
      d='M6.99701 1H7.00299M6.9974 13H7.00338M11.2392 2.75733H11.2452M2.75606 11.2427H2.76204M2.75606 2.75765H2.76204M11.2388 11.243H11.2448M12.994 7.00039H13M1 7.00039H1.00598'
      stroke='#D3D4DC'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);

export default Sun;
