const X = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='10'
    height='10'
    viewBox='0 0 10 10'
    fill='none'
  >
    <g id='Group 762161'>
      <rect
        id='Rectangle 238'
        x='3.05176e-05'
        y='8.48529'
        width='12'
        height='2'
        rx='1'
        transform='rotate(-45 3.05176e-05 8.48529)'
        fill='var(--background-color)'
      />
      <rect
        id='Rectangle 239'
        x='1.41422'
        width='12'
        height='2'
        rx='1'
        transform='rotate(45 1.41422 0)'
        fill='var(--background-color)'
      />
    </g>
  </svg>
);

export default X;
