import VideoPlayer from 'components/Video/VideoPlayer';
import { VideoType } from 'constants/types/type';
import * as S from './style';

type SessionVideoType = {
  video: VideoType;
  videoRef: React.RefObject<HTMLVideoElement>;
  flipped: boolean;
  setIsPaused: React.Dispatch<React.SetStateAction<boolean>>;
  isCoaching: boolean;
};
const SessionVideo = ({
  video,
  videoRef,
  flipped,
  setIsPaused,
  isCoaching,
}: SessionVideoType) => {
  return (
    <S.VideoPlayerWrapper ratio={video.ratio}>
      <VideoPlayer
        autoplay={true}
        videoRef={videoRef}
        src={video.streamingUrl}
        flipped={flipped}
        muted={true}
        controls={false}
        endTime={video.duration && video.duration / 1000 + 1000} //VideoPlayer는 초단위를 쓰고 있음
        setIsPaused={setIsPaused}
        isStopImmediate={false}
      />
    </S.VideoPlayerWrapper>
  );
};

export default SessionVideo;
