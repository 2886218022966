import {
  Title,
  Container,
  Contents,
  TermBody,
  TermTitle,
  TermBox,
  Dl,
  Dt,
  Dd,
  Table,
  Th,
  Td,
} from 'styles/TermStyle';
import { Margin } from 'styles/style';
import { CloseButtonOnTerms } from 'components/CloseButtonOnTerms';

export const Term1_en = () => {
  return (
    <Container>
      <CloseButtonOnTerms />
      <Title>Whistle Pro Terms and Conditions</Title>
      <Margin margin='0 0 20px 0' />
      <Dd>
        Hello, we are Spanlink Co., Ltd., the operator of the Real-Feed service.
        We would like to explain the necessary rights, obligations, and
        responsibilities, as well as the basic conditions and procedures for
        using the Real-Feed service (hereinafter referred to as the "Service"),
        which is provided through our website and application (including apps,
        applications, collectively referred to as the "Platform"). Please note
        that in the course of providing various services, the company may apply
        individual terms or policies in addition to the service terms and
        conditions.
      </Dd>
      <Margin margin='0 0 20px 0' />
      <TermBox>
        <Dl>
          <Dt>1. Definitions</Dt>
          <Dd>
            1.1. "Company" refers to Spanlink Co., Ltd., the operator of the
            Real-Feed service.
            <br /> 1.2. "Academy" refers to a person who has contracted with the
            company and been approved to continuously utilize all the services
            provided on the platform.
            <br /> 1.3. "Member" refers to a person who has signed up for
            membership on the platform and can continuously use all the services
            provided by the company or academy on the platform.
            <br /> 1.4. "Academy Subscription Service" refers to a paid service
            that provides space for utilizing all programs and learning content
            offered on the platform.
            <br /> 1.5. "Account Information" refers to the information related
            to the member (or the person intending to become a member) provided
            to the company through the platform, such as an email address.
          </Dd>
          <Dt>2. Effectiveness and Amendment of the Terms</Dt>
          <Dd>
            These terms apply to all academies and members who wish to use the
            service.
            <br />
            2.1. The company will post the contents of these terms, the name of
            the representative, business address, phone number, email address,
            business registration number, online sales license number, and the
            person in charge of personal information management at a location
            where academies and members can easily access them. The contents of
            the terms can be viewed through a connected screen.
            <br />
            2.2. When an academy is approved or a member signs up, the company
            will take measures to ensure that the member can check the entire
            content of the terms through a separate connection screen or popup
            window.
            <br />
            2.3. The company may operate individual terms or policies for
            individual services that constitute the service. In this case, the
            individual terms or policies will have the same effect as these
            terms for academies and members, and in the event of a conflict
            between these terms and the individual terms or policies, the
            individual terms or policies will take precedence.
            <br />
            2.4. Agreeing to these terms means agreeing to regularly check for
            changes to the terms. The company is not responsible for damages
            caused by not being aware of the changed terms.
          </Dd>
          <Dt>3. Supplementary Provisions</Dt>
          <Dd>
            Matters not specified in these terms shall be governed by related
            laws such as the Act on the Regulation of Terms and Conditions, the
            Act on the Consumer Protection in Electronic Commerce, the Act on
            Promotion of Information and Communications Network Utilization and
            Information Protection, and the Consumer Protection Act, as well as
            the company's privacy policy.
          </Dd>
          <Dt>4. Service Usage</Dt>
          <Dd>
            4.1. Academies and members can use the service via information and
            communication devices such as computers and mobile phones. The
            specific contents and conditions of use for each individual service
            can be checked at a location on the service where academies and
            members can recognize them.
            <br />
            4.2. The company may change or discontinue all or part of the
            service at any time without prior notice to academies and members.
            <br />
            4.3. The company generally provides the same content of the service
            to all academies and members. However, the company may restrict the
            use of the service by distinguishing users by age or other criteria
            based on special circumstances related to the provision of
            individual services.
            <br />
            4.4. The company will make every effort to provide the best service
            to academies and members. However, regular or temporary inspections,
            or other reasons may temporarily suspend the provision of the
            service.
            <br />
            4.5. The company principally provides members with 24/7 service, and
            the type and content of the paid services provided by the company
            may change according to the company's policy.
            <br />
            4.6. In cases such as maintenance, replacement, or inspection of
            information and communication facilities, hacking incidents,
            communication accidents, abnormal usage patterns, legal
            prohibitions, natural disasters, national emergencies, power
            outages, service facility failures, or overloads, the company may
            not provide the service for a certain period, or the service may be
            partially or completely discontinued. In such cases, the company is
            not obligated to provide the service.
            <br />
            4.7. In the cases mentioned in 4.6. (1, 3, 5), the company will
            notify academies and members in advance through the platform.
            However, if a member fails to recognize the notice during the period
            without the company's fault, the company is not responsible.
            <br />
            4.8. In case of unavoidable circumstances where advance notice or
            communication to academies and members is not possible, such as
            urgent system inspections, expansions, replacements, disk failures
            without the administrator's intent or negligence, or service
            unavailability due to reasons beyond the company's control, the
            company may notify or communicate after the fact.
          </Dd>
          <Dt>5. Academy Approval and Withdrawal</Dt>
          <Dd>
            5.1. Academies apply for approval (also referred to as "account
            creation" in these terms) by indicating their consent to these terms
            after entering the required information according to the company's
            academy approval procedures.
            <br />
            5.2. By indicating consent to these terms during the approval
            process, the academy is deemed to have recognized, understood, and
            agreed to these terms.
            <br />
            5.3. The company generally approves the application submitted by
            academies according to the approval procedures. However, the company
            may delay or refuse approval in the following cases:
            <br />
            1. Refusal to provide required information
            <br />
            2. Providing false information or using another person's information
            to apply
            <br />
            3. Applying with the intent to disrupt public order
            <br />
            4. Account suspension due to a violation of terms or related laws
            <br />
            5. Suspicion of applying with the intent to harm or disrupt the
            company
            <br />
            6. Physical or technical reasons preventing approval
            <br />
            7. Other reasons deemed inappropriate for approval
            <br />
            5.4. Academies can apply for withdrawal (also referred to as
            "account deletion" in these terms) at any time according to these
            terms, and the company generally approves the withdrawal
            application. However, the company may delay or refuse approval in
            the following cases:
            <br />
            1. Legal obligations owed to the company
            <br />
            2. Ongoing or potential disputes between the academy and the company
            <br />
            5.5. All rights to use the service are terminated at the time of
            withdrawal.
            <br />
            5.6. If an academy withdraws (deletes the account), the services in
            use will no longer be available, and the subsequent actions will be
            handled according to these terms.
          </Dd>
          <Dt>6. Membership Registration and Withdrawal</Dt>
          <Dd>
            6.1. Members apply for membership registration (also referred to as
            "account creation" in these terms) by entering the required
            information according to the company's membership registration
            procedures and indicating their consent to these terms.
            <br />
            6.2. By indicating consent to these terms during the registration
            process, members are deemed to have recognized, understood, and
            agreed to these terms.
            <br />
            6.3. The company generally approves the membership registration
            application according to the registration procedures. However, the
            company may delay or refuse approval in the following cases:
            <br />
            1. Refusal to provide required information
            <br />
            2. Providing false information or using another person's information
            to apply
            <br />
            3. Applying with the intent to disrupt public order
            <br />
            4. Account suspension due to a violation of terms or related laws
            <br />
            5. Suspicion of applying with the intent to harm or disrupt the
            company
            <br />
            6. Applying for commercial purposes
            <br />
            7. Physical or technical reasons preventing approval
            <br />
            8. Other reasons deemed inappropriate for approval
            <br />
            6.4. Members can apply for withdrawal (also referred to as "account
            deletion" in these terms) at any time according to these terms, and
            the company generally approves the withdrawal application. However,
            the company may delay or refuse approval in the following cases:
            <br />
            1. Legal obligations owed to the company
            <br />
            2. Ongoing or potential disputes between the member and the company
            <br />
            6.5. All rights to use the service are terminated at the time of
            withdrawal. Even if a member accidentally withdraws, the account
            cannot be restored. Additionally, withdrawn members cannot
            re-register with the same ID after the withdrawal date.
            <br />
            6.6. If a member withdraws (deletes the account), the services in
            use will no longer be available, and the subsequent actions will be
            handled according to these terms.
            <br />
            6.7. The company cancels or disallows registration for members under
            14 years old without the consent of their legal guardian.
            <br />
            6.8. Members can delete or modify content, including reviews posted
            within the platform, until the time of withdrawal, but after
            withdrawal, deletion or modification is not possible. If a member
            withdraws, content not deleted before withdrawal will not be deleted
            as a result of the withdrawal.
          </Dd>
          <Dt>7. Account Information</Dt>
          <Dd>
            7.1. "Account Information" (also referred to as "Account" in these
            terms) refers to the information provided by the member (or the
            person intending to become a member) to the company through the
            platform, such as an email, nickname, password, etc.
            <br />
            7.2. When an account is created, the member is deemed to have agreed
            to these terms and the privacy policy.
            <br />
            7.3. The management responsibility for the account information lies
            with the member. Therefore, members should not allow third parties
            to use their account information. If a member incurs damage due to
            negligence in managing or protecting their account information, the
            member is responsible for it.
            <br />
            7.4. If a member becomes aware that their account information has
            been stolen or is being used by a third party, they must immediately
            notify the company and follow the company's instructions. If a
            member fails to notify the company promptly or fails to follow the
            company's instructions after notification, the company is not
            responsible for any disadvantages or damages incurred.
            <br />
            7.5. The company may refuse the membership application or delete the
            account of members who have violated the terms or related laws.
            <br />
            7.6. If the company determines that a member has violated or is
            likely to violate these terms, the company may suspend or delete the
            account without prior notice.
            <br />
            7.7. The company may delete all or part of the information posted by
            a member whose account has been suspended.
          </Dd>
          <Dt>8. Use and Cancellation of Academy Subscription Service</Dt>
          <Dd>
            8.1. Academies can use the service according to the company's policy
            after paying the subscription fee.
            <br />
            8.2. After paying the subscription fee, academies can cancel or
            terminate the subscription service. The company will process this
            promptly according to the procedures specified by related laws.
            <br />
            8.3. When an academy cancels or terminates the subscription service,
            the service will generally continue until the end of the paid
            period, after which the termination will take effect.
            <br />
            8.4. Academies can continue to use the service normally during the
            remaining paid period, and no refund will be given.
            <br />
            8.5. If the service contract is canceled or terminated due to
            circumstances on the company's side, the company will refund the
            service fee corresponding to the remaining period to the academy,
            calculating the refund amount based on a monthly usage rate.
            <br />
            8.6. The company will complete the refund process within three
            business days from the service termination date. However, in
            unavoidable circumstances, the refund may be delayed, and the
            company will notify the academy in advance.
            <br />
            8.7. Content related to subscription services not specified in these
            terms will be governed by the company's policies or guidelines.
          </Dd>
          <Dt>9. Payment Methods</Dt>
          <Dd>
            9.1. Academies must make payments according to the payment methods
            provided by the company for Real-Feed platform services. Academies
            can request cancellation at any time, and the service will end when
            the service period ends unless additional payments are made.
            <br />
            9.2. If a member chooses the automatic payment method, the academy
            must register payment-related information such as a credit card.
          </Dd>
          <Dt>10. Refunds</Dt>
          <Dd>
            10.1. If an academy wishes to request a refund during the use of the
            Real-Feed platform service, they must contact customer service and
            follow a separate procedure.
            <br />
            10.2. When refunding payments, the company requests the payment
            service provider (e.g., credit card, electronic money) to stop or
            cancel the payment. If a refund to the original payment method is
            not possible, the company may allow the member to choose a refund
            method such as a bank transfer.
          </Dd>
          <Dt>11. Obligations of Academies and Members</Dt>
          <Dd>
            11.1. Academies and members must confirm and comply with the
            provisions of these terms, service usage guidelines, notices, and
            other communications from the company.
            <br />
            11.2. Academies and members are prohibited from engaging in the
            following actions (including actions similar to these):
            <br />
            1. Actions that violate the purpose of using the service (regardless
            of profit or non-profit)
            <br />
            2. Installing or distributing malicious code, viruses, or other
            programs intentionally or negligently
            <br />
            3. Abnormal use of the service or joining with false or others'
            information
            <br />
            4. Inputting false or exaggerated information
            <br />
            5. Inputting or using identifiable information such as names, logos,
            addresses, phone numbers, or email addresses without the company's
            prior consent or permission
            <br />
            6. Violating public order, discriminating, or promoting prejudice
            based on gender, politics, religion, disability, age, social status,
            race, region, occupation, etc.
            <br />
            7. Discriminating, slandering, or insulting other academies,
            members, or third parties, or damaging their reputation
            <br />
            8. Posting excessively exposed or obscene content, sharing
            prostitution-related information, or posting content that can cause
            sexual shame or discomfort to others
            <br />
            9. Posting hateful images or content, using abusive language,
            profanity, slang, or engaging in abnormal activities contrary to
            social norms
            <br />
            10. Infringing or likely infringing on others' rights, including
            patents, trademarks, copyrights, and other intellectual property
            rights
            <br />
            11. Selling, transferring, renting, or allowing others to use
            accounts or account information, or facilitating such actions
            <br />
            12. Impersonating other academies, members, third parties, or
            company employees through name impersonation or forgery
            <br />
            13. Disrupting the company's business operations
            <br />
            14. Other actions equivalent to the above
          </Dd>
          <Dt>12. Intellectual Property Rights Protection</Dt>
          <Dd>
            12.1. The copyright of content (symbols, text, shapes, colors,
            sounds, audio, images, reviews, videos, etc., including their
            combinations) created and uploaded by academies and members on the
            platform belongs to the respective academies and members, and the
            company has the right to post (including transformation, processing,
            summarizing, extracting, and aggregating actions) such content
            within the platform for the smooth provision of the service.
            <br />
            12.2. The company prohibits the posting of content on the platform
            that falls under any of the following categories. In such cases, the
            company may delete the content at its discretion:
            <br />
            1. Content that does not align with the purpose of using the service
            <br />
            2. Content that infringes on the personal information of third
            parties
            <br />
            3. Content that induces or solicits direct transactions without
            going through the company's platform
            <br />
            4. Content that does not correspond to objective facts
            <br />
            5. Content that violates these terms or is deemed illegal, obscene,
            or vulgar
            <br />
            6. Content that slanders or damages the reputation of other members
            or third parties
            <br />
            7. Content that violates public order and morals
            <br />
            8. Content that is recognized as being related to criminal
            activities
            <br />
            9. Content that infringes on the portrait rights, copyrights, or
            other rights of third parties
            <br />
            10. Content that contains advertising information against the
            recipient's will
            <br />
            11. Content that is unrelated to the product or is written for the
            purpose of defamation without objective evidence
            <br />
            12. Content that violates related laws
            <br />
            12.3. If an academy or member infringes on the copyright of other
            academies, members, or third parties, the company may delete or
            repost the content at the request of the copyright holder or the
            academy or member according to the procedures specified by the
            Copyright Act and other related laws. In such cases, the company is
            not liable. The academy or member who infringes on the rights of
            other academies, members, or third parties is solely responsible for
            any civil or criminal liability arising from the infringement.
            <br />
            12.4. The copyright and other intellectual property rights of the
            content created by the company belong to the company. The company
            only grants the right to use the service according to the usage
            conditions set by the company, and members cannot commercially use
            the content posted on the platform by processing, selling,
            transferring, or pledging it for financial gain.
            <br />
            12.5. Academies and members must not use or allow third parties to
            use the information acquired through the service, which is subject
            to intellectual property rights or other rights belonging to the
            company or third parties, without prior consent. This includes both
            commercial and non-commercial use.
            <br />
            12.6. The intellectual property rights and other rights related to
            the service, software, images, marks, logos, designs, service names,
            information, and trademarks provided by the company belong to the
            company. Except as explicitly approved by the company, academies and
            members are prohibited from modifying, renting, lending, selling,
            distributing, producing, transferring, sublicensing, or pledging the
            above-mentioned assets, or allowing third parties to engage in such
            acts.
          </Dd>
          <Dt>13. Protection of Member Information</Dt>
          <Dd>
            The company protects and respects the privacy of its members. The
            company collects and uses the minimum amount of personal information
            necessary for providing the service, according to the privacy
            policy. However, members can refuse to provide personal information
            or request its deletion at any time. The company establishes and
            adheres to a privacy policy in accordance with the Personal
            Information Protection Act to protect members' valuable personal
            information. Members can check the details of how the company safely
            handles their personal information in the privacy policy.
          </Dd>
          <Dt>14. Provision of Marketing Information</Dt>
          <Dd>
            14.1. In accordance with the Act on Promotion of Information and
            Communications Network Utilization and Information Protection, the
            company may provide members with various marketing information for
            commercial purposes via SMS, email, push notifications, or other
            electronic transmission media.
            <br />
            14.2. The company provides members with a method to refuse
            advertising and allows them to refuse advertising at any time. In
            this case, the company will immediately stop providing marketing
            information.
          </Dd>
          <Dt>15. Notification and Announcement of Terms</Dt>
          <Dd>
            The company may change these terms at any time without prior notice
            to members and will announce the changes by posting the revised
            terms, the reasons for the change, and the effective date on the
            platform's home screen or a connected screen from at least seven
            days before the effective date. If the changes are disadvantageous
            to members, the company will notify members at least 30 days before
            the effective date. If a member does not agree to the revised terms,
            the company cannot apply the revised terms and may terminate the
            service agreement with the member. The member may also terminate the
            service agreement by withdrawing from the service. If a member
            continues to use the service without withdrawing by the effective
            date of the revised terms, they are deemed to have agreed to the
            revised terms.
          </Dd>
          <Dt>16. Company's Responsibility and Disclaimer</Dt>
          <Dd>
            The company compensates members for damages caused by the company's
            fault. However, compensation is limited to actual damages incurred
            in connection with the use of the service, and the company is not
            responsible for damages caused by events beyond its control, such as
            natural disasters, wars, service interruptions by telecommunication
            providers, unresolved technical defects, and other force majeure
            events. The company does not guarantee that the service is free from
            defects, errors, bugs, or infringements, and is not obligated to
            provide a service that removes such defects.
          </Dd>
          <Dt>17. Governing Law and Jurisdiction</Dt>
          <Dd>
            Disputes between the company and members regarding the use of the
            service are governed by the laws of the Republic of Korea, and any
            lawsuits arising from such disputes are subject to the jurisdiction
            of the courts in Korea under the Civil Procedure Act. These terms
            are effective as of September 18, 2023.
          </Dd>
        </Dl>
      </TermBox>
    </Container>
  );
};
