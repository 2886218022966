import {
  postFeedbackRequest,
  postFeedbackRequestComplete,
} from 'apis/feedback';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import FeedbackVideoContainer from 'components/RequestFeedback/FeedbackVideoContainer';
import { PageHeaderForRequestFeedback } from 'components/RequestFeedback/PageHeader';
import SwitchBox, { SwitchBoxType } from 'components/RequestFeedback/SwitchBox';
import { UPLOAD_ALERT_TEXT } from 'constants/alertText';
import { getSupportedMimeType, uploadS3 } from 'constants/common';
import { VideoType } from 'constants/types/type';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Flex } from 'styles/style';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

type RequestFeedbackPageType = {
  centerId: string;
  sessionId: string;
  userVideoBlob: Blob;
  sceneVideo: VideoType;
  centerName: string;
};
type formInputType = {
  content: string;
};
const RequestFeedbackPage = ({
  centerId,
  sessionId,
  userVideoBlob,
  sceneVideo,
  centerName,
}: RequestFeedbackPageType) => {
  const navigate = useNavigate();
  const [switchOn_alarm, setSwitchOn_alarm] = useState<boolean>(true);
  const [switchOn_show, setSwitchOn_show] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userVideoUrl, setUserVideoUrl] = useState('');
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<formInputType>();
  useEffect(() => {
    setIsLoading(true);
  }, []);
  useEffect(() => {
    if (userVideoBlob) {
      const url = URL.createObjectURL(userVideoBlob);
      setUserVideoUrl(url);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [userVideoBlob]);

  // 터치 이벤트를 위한 useEffect 추가
  useEffect(() => {
    const handleTouchMove = (e: TouchEvent) => {
      // 이 페이지에서는 preventDefault를 막습니다.
      e.stopImmediatePropagation();
    };

    document.addEventListener('touchmove', handleTouchMove, { capture: true });

    return () => {
      document.removeEventListener('touchmove', handleTouchMove, {
        capture: true,
      });
    };
  }, []);

  const checkList: SwitchBoxType[] = [
    {
      key: 1,
      icon: 'Notify',
      iconWidth: 16,
      title: t('피드백 알림'),
      desc: t('센터의 전문가가 피드백을 남겼을 때 알람을 받습니다'),
      switchOn: switchOn_alarm,
      setSwitchOn: setSwitchOn_alarm,
    },
    {
      key: 2,
      icon: 'Sun',
      iconWidth: 16,
      title: t('보이기 설정'),
      desc: `${t('피드백을 센터 피드에 노출합니다')}\n(${t(
        '내용과 영상은 보이지 않습니다'
      )})`,
      switchOn: switchOn_show,
      setSwitchOn: setSwitchOn_show,
    },
  ];

  const submitFeedback: SubmitHandler<formInputType> = async (
    data: formInputType
  ) => {
    setIsLoading(true);
    const mimeType = getSupportedMimeType();
    const uploadFile = new Blob([userVideoBlob], { type: `video/${mimeType}` });

    try {
      const response = await postFeedbackRequest({
        centerId,
        sessionId,
        feedbackVideos: {
          fileName: `vod.${mimeType}`,
        },
        feedbackAlarm: switchOn_alarm,
        timeFit: 0,
        showClass: switchOn_show ? 'friend' : 'experts',
        isReverse: true,
        feedbackVodRatio: sceneVideo.ratio,
        feedbackDuration: sceneVideo.duration,
        feedbackContent: data.content,
      });

      if (!response) {
        alert(t('피드백 요청에 실패했습니다. 잠시 후 다시 시도해주세요'));
        return;
      }

      const uploadTasks: any[] = [];
      const { feedbackPresignedUrl, feedbackView } = response;
      if (feedbackPresignedUrl) {
        uploadTasks.push(uploadS3(feedbackPresignedUrl, uploadFile));
      }

      const { feedbackId } = feedbackView;
      Promise.all(uploadTasks)
        .then(() => {
          sendComplete(feedbackId);
        })
        .catch((error) => {
          console.error('An error occurred during the upload:', error);
          alert(UPLOAD_ALERT_TEXT);
        })
        .finally(() => {
          setIsLoading(false);
          navigate(
            `/feedback/detail?centerId=${centerId}&sessionId=${sessionId}&feedbackId=${feedbackId}`
          );
        });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      alert(t('피드백 요청에 실패했습니다. 잠시 후 다시 시도해주세요'));
    }
  };
  const sendComplete = async (feedbackId: string) => {
    await postFeedbackRequestComplete({
      params: {
        feedbackId,
      },
      data: {
        sessionId,
        centerId,
      },
    });
  };

  const downloadVideo = () => {
    const link = document.createElement('a');
    const type = getSupportedMimeType();

    link.download = `My video.${type}`;
    link.href = userVideoUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Container>
      {isLoading && <Loading num={2} />}
      <PageHeaderForRequestFeedback centerName={centerName} />
      <form onSubmit={handleSubmit(submitFeedback)}>
        <Flex flexDirection='column' padding='0 16px'>
          <FeedbackVideoContainer
            feedbackVideo={sceneVideo}
            userVideo={{
              ratio: sceneVideo.ratio,
              streamingUrl: userVideoUrl,
              convertStatus: '',
              duration: sceneVideo.duration,
              thumbnailImage: undefined,
              metadata: {
                originalWidth: 0,
                originalHeight: 0,
              },
              videoKey: '',
              isCompleted: true,
            }}
          />
          <ContentsContainer>
            <TextAreaInput
              placeholder={`${centerName}${t(
                '의 전문가에게 피드백을 요청하세요'
              )}`}
              {...register('content', { required: true })}
            />
          </ContentsContainer>

          <SwitchBoxContainer>
            {checkList.map((item) => {
              return (
                <SwitchBox
                  key={item.key}
                  icon={item.icon}
                  iconWidth={item.iconWidth}
                  title={item.title}
                  desc={item.desc}
                  switchOn={item.switchOn}
                  setSwitchOn={item.setSwitchOn}
                />
              );
            })}
            <DownloadBox>
              <DownloadTitle>{t('내 영상 다운로드')}</DownloadTitle>
              <DownloadButton onClick={() => downloadVideo()}>
                <span>{t('다운로드')}</span> <Icon icon='Download' />
              </DownloadButton>
            </DownloadBox>
          </SwitchBoxContainer>
          <SubmitButton isActive={isValid} type='submit' disabled={isLoading}>
            {t('피드백 요청하기')}
          </SubmitButton>
        </Flex>
      </form>
    </Container>
  );
};

export default RequestFeedbackPage;

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  min-width: 320px;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  overflow: hidden;
`;
const ContentsContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;
const TextAreaInput = styled.textarea`
  width: 100%;
  height: 150px;
  border-radius: 12px;
  margin: 15px 0;
  background-color: var(--background-color);
  padding: 15px;
  color: white;
  resize: none;
  line-height: 20px;
`;
const SwitchBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

type SubmitButtonType = {
  isActive: boolean;
};
export const SubmitButton = styled.button<SubmitButtonType>`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive }) =>
    isActive ? 'var(--primary)' : 'var(--c700)'};
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: -0.5px;
  padding: 0px 20px;
  margin: 35px auto;
  color: ${({ isActive }) => (isActive ? 'black' : 'var(--c300)')};
  border-radius: 10px;
  cursor: pointer;
`;
export const DownloadBox = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color);
  border-radius: 18px;
  padding: 16px;
`;

const DownloadTitle = styled.span`
  font-family: SF Pro Text;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  letter-spacing: -0.5px;
  text-align: left;
  color: var(--c300);
`;
const DownloadButton = styled.div`
  height: 30px;
  cursor: pointer;
  background-color: var(--c200);
  padding: 5px 10px 5px 14px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  > span {
    font-family: SF Pro Text;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
  }
`;
