import { FeedbackType, ProfileType, RoleType } from 'constants/types/type';
import { useNavigate } from 'react-router-dom';
import FeedbackItemForStaff from 'components/FeedbackItemForStaff';
import FeedbackItemForUser from 'components/FeedbackItemForUser';
import { useProfileStore } from 'store/auth';
type FeedbackItemType = {
  feedback: FeedbackType;
  title?: string;
  circleImage: string;
  firstDesc?: string;
  role: RoleType;
};
const FeedbackItem = ({
  feedback,
  role,
  circleImage,
  title,
  firstDesc,
}: FeedbackItemType) => {
  return (
    <>
      {role === 'staff' || role === 'owner' ? (
        <FeedbackItemForStaff feedback={feedback} />
      ) : (
        <FeedbackItemForUser
          feedback={feedback}
          title={title}
          circleImage={circleImage}
          firstDesc={firstDesc}
        />
      )}
    </>
  );
};
export default FeedbackItem;
