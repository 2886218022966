import SignUpForm from "../components/SignUpForm";

const SignUpPage = () => {
  return (
    <>
      <SignUpForm />
    </>
  );
};

export default SignUpPage;
