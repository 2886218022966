import client from './client';
import { withErrorHandling } from './errorHandling';

type postHomeRecommandType = {
  limit: number;
  offset: number;
  count: number;
};
const postHomeRecommandRaw = (data: postHomeRecommandType) =>
  client.post('/api/home/recommand', data);
export const postHomeRecommand = withErrorHandling(postHomeRecommandRaw);
