import Icon from 'components/Icon';
import { Flex } from 'styles/style';

export const CloseButtonOnTerms = () => {
  return (
    <Flex
      width='100%'
      margin='0 0 15px 0'
      justifyContents='right'
      onClick={() => window.close()}
    >
      <Icon icon='X_2' width={13} height={13} color={`gray`} />
    </Flex>
  );
};
