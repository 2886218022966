import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  gap: 20px;
  margin-top: 23px;
  padding: 0 16px;
`;
type ItemType = {
  isActive: boolean;
};
export const Item = styled.div<ItemType>`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: ${({ isActive }) => isActive && '1px solid var(--primary)'};
  cursor: pointer;
`;
export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--c900);
`;
export const TopBar = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 0px 20px;
`;
export const WriteButton = styled.button`
  width: 68px;
  height: 24px;
  border-radius: 6.5px;
  border: 0.5px solid var(--c100);
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  > span {
    font-size: 10px;
    color: var(--c100);
    font-weight: 600;
  }

  @media (max-width: 768px) {
    transform: scale(1.1); /* 모바일 뷰에서 약간 확대 */
  }
`;
export const NoListDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  color: var(--c300펴);
`;
