export const breakpoints_float = {
  320: {
    slidesPerView: 1.1,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 2.1,
    spaceBetween: 20,
  },
};
export const breakpoints_int = {
  320: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
};
