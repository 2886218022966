const Home = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30px'
    height='30px'
    viewBox='0 0 24 24'
    version='1.1'
  >
    <title>Home</title>
    <g
      id='Page-1'
      stroke='none'
      stroke-width='1'
      fill='none'
      fill-rule='evenodd'
    >
      <g id='Home'>
        <rect
          id='Rectangle'
          fill-rule='nonzero'
          x='0'
          y='0'
          width='auto'
          height='auto'
        ></rect>
        <path
          d='M5,10 L5,19 C5,19.5523 5.44772,20 6,20 L18,20 C18.5523,20 19,19.5523 19,19 L19,10'
          id='Path'
          stroke='white'
          stroke-width='1'
          stroke-linecap='round'
        ></path>
        <path
          d='M21,11 L12.307,4.23875 C12.1264,4.09832 11.8736,4.09832 11.693,4.23875 L3,11'
          id='Path'
          stroke='white'
          stroke-width='1'
          stroke-linecap='round'
        ></path>
      </g>
    </g>
  </svg>
);

export default Home;
