import styled from 'styled-components';
import { MaxLineLimitSpan } from 'styles/style';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
  cursor: pointer;
`;
export const Contaienr2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px 0;
`;
export const Title = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 16.71px;
  letter-spacing: -0.5px;
  text-align: left;
`;
export const SubDesc = styled.span`
  display: flex;
  gap: 2px;
  margin-top: 2px;
  margin-bottom: 10px;
  > span {
    font-size: 1rem;
    font-weight: 400;
    line-height: 11.93px;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--c500);
  }
`;
export const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: var(--c900);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
`;
export const Content = styled(MaxLineLimitSpan)`
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: -0.5px;
  color: var(--c500);
`;
