import { theme } from 'constants/theme';
import styled from 'styled-components';

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: black;
  margin: 0 auto;
`;
type VideoPlayerWrapperType = {
  ratio: 'landscape' | 'portrait';
};
export const VideoPlayerWrapper = styled.div<VideoPlayerWrapperType>`
  position: absolute;
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  height: fill-available;

  @media ${`screen and (max-width:500px)`} {
    video {
      object-fit: ${({ ratio }) =>
        ratio === 'landscape' ? 'obtain' : 'cover'};
    }
  }
`;
export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 11px;
  align-items: center;
`;
export const ProgressTime = styled.div`
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: -0.5px;
`;
export const ProgressBar = styled.div`
  position: relative;
  width: 296px;
  height: 5px;
  background-color: var(--c500);
  cursor: pointer;
`;
type CurrentBarType = {
  width: number;
};
export const CurrentBar = styled.div<CurrentBarType>`
  position: absolute;
  left: 0;
  max-width: 296px;
  width: ${({ width }) => (width ? `${width}%` : '0%')};
  height: 5px;
  background-color: var(--primary);
  cursor: pointer;
`;

export const PlayButtonWrapper = styled.div`
  cursor: pointer;
`;
