import CenterView from 'components/CenterView';
import CenterViewSlide from 'components/CenterViewSlide';
import { CenterType } from 'constants/types/type';
import { getLocalStorage } from 'constants/useLocalStorage';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NotFoundDiv } from 'styles/style';
import * as S from './style';

export const RecentSection = () => {
  const navigate = useNavigate();
  const [recentSearch, setRecentSearch] = useState<string[]>([]);
  const [recentActivity, setRecentActivity] = useState<CenterType[]>([]);
  useEffect(() => {
    getRecentData();
  }, []);
  const getRecentData = () => {
    const recentSearch = getLocalStorage('recentSearch');
    const recentActivity = getLocalStorage('recentActivity');
    setRecentSearch(recentSearch);
    setRecentActivity(recentActivity);
  };
  useEffect(() => {}, [recentActivity]);
  const { t } = useTranslation();

  return (
    <S.Container>
      {recentSearch.length > 0 && (
        <S.SectionContainer>
          <S.ItemTitle>{t('최근 검색어')}</S.ItemTitle>
          <S.ItemContainer>
            {recentSearch.map((search) => (
              <S.ItemSpan onClick={() => navigate(`/search?keyword=${search}`)}>
                {search}
              </S.ItemSpan>
            ))}
          </S.ItemContainer>
        </S.SectionContainer>
      )}

      {recentActivity.length > 0 && (
        <S.SectionContainer>
          <S.ItemTitle>{t('최근 활동')}</S.ItemTitle>
          <S.ItemContainer>
            <CenterViewSlide items={recentActivity} />
          </S.ItemContainer>
        </S.SectionContainer>
      )}

      {recentSearch.length < 1 && recentActivity.length < 1 && (
        <NotFoundDiv>{t('검색 기록 및 최근 활동이 없습니다')}</NotFoundDiv>
      )}
    </S.Container>
  );
};
