import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import Swiper, { Slide } from 'components/Swiper';
import AspectRatioBox from 'components/AspectRatioBox';
import { breakpoints_float } from '../../constants/swiper_breakpoints';
import CenterView from 'components/CenterView';

type CenterViewSlideType = {
  items: any[];
};
const CenterViewSlide = ({ items }: CenterViewSlideType) => {
  const navigate = useNavigate();

  return (
    <Swiper
      breakpoints={breakpoints_float}
      loop={true}
      autoplay={true}
      pagination={false}
    >
      {[...items, ...items, ...items, ...items].map((item, index) => (
        <Slide key={index}>
          <AspectRatioBox ratio={0.75}>
            <CenterView item={item} />
          </AspectRatioBox>
        </Slide>
      ))}
    </Swiper>
  );
};

export default CenterViewSlide;
