import styled from 'styled-components';

export const TopBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0px 20px;
`;
export const FilteringContainer = styled.div`
  display: flex;
  gap: 3px;
  cursor: pointer;
`;
export const DisplayModeContainer = styled.div`
  display: flex;
  gap: 13px;
  > div {
    cursor: pointer;
  }
`;
