import { postSessionListApp } from 'apis/session';
import Icon from 'components/Icon';
import SessionCard from 'components/SessionCard';
import SessionView from 'components/SessionView';
import { SessionType } from 'constants/types/type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Flex, NotFoundDiv, Span } from 'styles/style';
import useHandleScroll from 'utils/useHandleScroll';
import * as S from './style';

const OnlineSession = () => {
  const navigate = useNavigate();
  const [sessionList, setSessionList] = useState<SessionType[]>([]);
  const [offset, setOffset] = useState<number>(0);

  const [scrollActive, setScrollActive] = useState<boolean>(true);

  // 필터링 조건
  const [onlyFailComplete, setOnlyFailComplete] = useState<boolean>(false);

  // display 방식
  const [displayMode, setDisplayMode] = useState<'card' | 'album'>('album');

  const [searchParams, setSearchParams] = useSearchParams();
  const centerId = searchParams.get('centerId');

  const [isPending, setIsPending] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    getData();
  }, [onlyFailComplete, offset]);

  const getData = async () => {
    if (!centerId) {
      return;
    }
    setIsPending(true);
    const { sessionList, listInfo } = await postSessionListApp({
      centerId,
      failToComplete: onlyFailComplete,
      limit: 10,
      offset,
    });
    if (sessionList?.length > 0 && offset === 0) {
      setSessionList(sessionList);
    } else if (sessionList.length > 0) {
      setSessionList((prevList) => [...prevList, ...sessionList]);
    } else {
      setScrollActive(false);
    }
    setIsPending(false);
  };

  const handleFilteringButton = () => {
    setOffset(0);
    setOnlyFailComplete((prev) => !prev);
  };
  useHandleScroll(setOffset, 5, scrollActive);

  return (
    <>
      <S.TopBar>
        <S.DisplayModeContainer>
          <Icon
            icon='Menu'
            color={displayMode === 'album' ? 'white' : '#8A8C99'}
            onClick={() => setDisplayMode('album')}
          />
          <Icon
            icon='Layout'
            color={displayMode === 'card' ? 'white' : '#8A8C99'}
            onClick={() => setDisplayMode('card')}
          />
        </S.DisplayModeContainer>
        <S.FilteringContainer onClick={() => handleFilteringButton()}>
          {onlyFailComplete ? (
            <Icon icon='ToggleCheckButton_Active' width={16} height={16} />
          ) : (
            <Icon icon='ToggleCheckButton' width={16} height={16} />
          )}
          <Span
            fontSize='14px'
            color={onlyFailComplete ? 'var(--primary)' : 'white'}
            padding='1px 0 0 0'
          >
            {t('미완료만')}
          </Span>
        </S.FilteringContainer>
      </S.TopBar>
      {sessionList.length < 1 && !isPending ? (
        <NotFoundDiv>{t('등록된 세션이 없습니다')}</NotFoundDiv>
      ) : displayMode === 'card' ? (
        <>
          {sessionList.map((session: SessionType, index) => (
            <SessionView session={session} key={session.sessionId + index} />
          ))}
        </>
      ) : (
        displayMode === 'album' && (
          <Flex gap='20px' flexWrap='wrap' justifyContents='left'>
            {sessionList.map((session: SessionType, index) => (
              <SessionCard session={session} key={session.sessionId + index} />
            ))}
          </Flex>
        )
      )}
    </>
  );
};
export default OnlineSession;
