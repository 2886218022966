import client from './client';
import { withErrorHandling } from './errorHandling';

type postNotificationListType = {
  limit: number;
  offset: number;
  count: number;
};
const postNotificationListRaw = (data: postNotificationListType) =>
  client.post('/api/app/notificationList', data);
export const postNotificationList = withErrorHandling(postNotificationListRaw);

const postNotificationYNRaw = () => client.post('/api/app/notificationNew');
export const postNotificationYN = withErrorHandling(postNotificationYNRaw);

type postNotificationReadType = {
  pushResultNo: number;
  pushId: string;
};
const postNotificationReadRaw = (data: postNotificationReadType) =>
  client.post('/api/app/notificationRead', data);
export const postNotificationRead = withErrorHandling(postNotificationReadRaw);
