import { NavItemType } from 'components/CenterDetailAfterSignup';
import { useTranslation } from 'react-i18next';
import * as S from './style';

type NavigationType = {
  activeNavItem: NavItemType;
  setActiveNavItem: React.Dispatch<React.SetStateAction<NavItemType>>;
};
const Navigation = ({ activeNavItem, setActiveNavItem }: NavigationType) => {
  const { t } = useTranslation();

  const list: NavItemType[] = [
    '전체',
    '온라인세션',
    '커뮤니티',
    '피드백',
    '멤버',
  ];

  return (
    <>
      <S.Container>
        {list.map((item, index) => (
          <S.Item
            isActive={activeNavItem === item}
            onClick={() => setActiveNavItem(item)}
            key={index}
          >
            {t(item)}
          </S.Item>
        ))}
      </S.Container>
      <S.Line />
    </>
  );
};
export default Navigation;
