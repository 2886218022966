import client from './client';
import { withErrorHandling } from './errorHandling';

// 피드백 리스트
type postMyActivityType = {
  limit: number;
  offset: number;
  count: number;
};
const postMyActivityRaw = (data: postMyActivityType) =>
  client.post('/api/app/myactivity', data);
export const postMyActivity = withErrorHandling(postMyActivityRaw);
