import { postNotificationList, postNotificationRead } from 'apis/alarm';
import PageHeader from 'components/PageHeader';
import { useEffect, useState } from 'react';
import { NotificationTypes } from 'constants/types/notification';
import NotiItem from 'components/NotiItem';
import { RelativeDiv } from 'styles/style';
import { Line } from 'components/FeedbackItem/style';
import styled from 'styled-components';
import useHandleScroll from 'utils/useHandleScroll';
import { useTranslation } from 'react-i18next';

const NotifyPage = () => {
  const [offset, setOffset] = useState<number>(0);
  const [notificationList, setNotificationList] = useState<NotificationTypes[]>(
    []
  );

  const [scrollActive, setScrollActive] = useState<boolean>(true);

  useEffect(() => {
    getData();
  }, [offset]);

  useHandleScroll(setOffset, 5, scrollActive);

  const getData = async () => {
    const { listInfo, notificationList } = await postNotificationList({
      offset,
      limit: 10,
      count: 10,
    });
    if (notificationList?.length > 0 && offset === 0) {
      setNotificationList(notificationList);
    } else if (notificationList.length > 0) {
      setNotificationList((prevList) => [...prevList, ...notificationList]);
    } else {
      setScrollActive(false);
    }
  };
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title={t('알림')} isLineShow={true} />
      <NotiContainer>
        {notificationList.map((item: NotificationTypes, index: number) => {
          return (
            <RelativeDiv key={item.contentId + index}>
              <NotiItem item={item} />
              <Line isLast={index === notificationList.length - 1} />
            </RelativeDiv>
          );
        })}
      </NotiContainer>
    </>
  );
};
export default NotifyPage;

const NotiContainer = styled.div`
  padding: 30px 21px;
  margin-top: 60px;
`;
