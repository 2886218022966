import { AxiosResponse } from 'axios';

type ApiCallType<T, A extends any[]> = (
  ...args: A
) => Promise<AxiosResponse<T>>;

export const withErrorHandling = <T, A extends any[]>(
  apiCall: ApiCallType<T, A>
) => {
  return function (...args: A): Promise<T | Error> {
    return apiCall(...args)
      .then((response) => {
        if (!response?.data) {
          return Promise.reject(new Error('undefined response'));
        }
        return response.data;
      })
      .catch((error) => {
        console.error('API call error:', error);
        return Promise.reject(error);
      });
  };
};
