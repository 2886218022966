import i18n from 'i18n';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export const allowedImageFormats = '.jpg,.jpeg,.png,.bmp,.tiff,.webp';
export const uploadS3 = async (presignedUrl: string, file: File | Blob) => {
  // const uploadFile = file instanceof File ? await fileToBlob(file) : file;

  const contentType = file.type;

  try {
    const response = await fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': contentType,
      },
    });
    // Status 코드를 통한 추가적인 확인
    if (response.status >= 200 && response.status < 300) {
      await new Promise((res) => setTimeout(res, 1500));
      return true;
    } else {
      const errorData = await response.text(); // 또는 response.json()
      return `Error: ${errorData}`; // 에러 메시지 반환
    }
  } catch (e: any) {
    return `Error: ${e.message}`; // 에러 메시지 반환
  }
};
export const timeAgo = (timeString: string) => {
  const givenDate = new Date(timeString);
  // 수동으로 9시간을 뺌
  const koreanDate = new Date(givenDate.getTime() - 9 * 60 * 60 * 1000);

  // 현재 시간의 Date 객체
  const currentDate = new Date();

  // 두 날짜 사이의 차이(밀리초 단위)
  const difference = currentDate.getTime() - koreanDate.getTime();

  // 밀리초를 분, 시간, 일, 월로 변환
  const minutes = Math.floor(difference / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (months > 0) return `${months}${i18n.t('개월 전')}`;
  if (days > 0) return `${days}${i18n.t('일 전')}`;
  if (hours > 0) return `${hours}${i18n.t('시간 전')}`;
  if (minutes > 1) return `${minutes}${i18n.t('분 전')}`;
  return i18n.t(`방금 전`);
};

async function fileToBlob(file: File): Promise<Blob> {
  const arrayBuffer = await file.arrayBuffer();
  return new Blob([arrayBuffer], { type: file.type });
}
export function debounce<T>(
  func: (this: T, ...args: any[]) => void,
  timeout: number = 700
): (...args: any[]) => void {
  let timer: NodeJS.Timeout | null = null;
  return function (this: T, ...args: any[]) {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
export const maskEmail = (email: string) => {
  if (!email) {
    return;
  }
  const [localPart, domain] = email.split('@');

  if (localPart.length <= 3) {
    return email;
  }

  const visiblePart = localPart.substring(0, 3);
  const maskedPart = '*'.repeat(localPart.length - 3);

  return `${visiblePart}${maskedPart}@${domain}`;
};
export const getRoleDisplayName = (role: string) => {
  switch (role) {
    case 'owner':
      return i18n.t('관리자');
    case 'staff':
      return i18n.t('스태프');
    case 'member':
      return i18n.t('회원');
    case 'guest':
      return i18n.t('게스트');
    default:
      return ''; // 혹은 '알 수 없음' 등의 기본 값을 지정
  }
};
export const openNewWindow = (
  url: string,
  width: number = 400,
  height: number = 700
) => {
  // 화면의 가로 및 세로 중앙 위치를 계산합니다.
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;
  window.open(
    url,
    '_blank',
    `width=${width},height=${height},left=${left},top=${top}`
  );
};

//시퀀스 생성기
export const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const getKoreanDate = (date: number | string) => {
  const givenDate = new Date(date);
  // 수동으로 9시간을 뺌
  const koreanDate = new Date(givenDate.getTime() - 9 * 60 * 60 * 1000);

  const result =
    koreanDate.getFullYear() +
    '-' +
    (koreanDate.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    koreanDate.getDate().toString().padStart(2, '0');
  return result;
};

// 객체 비교
export const compareObjects = (a: any, b: any) =>
  JSON.stringify(a) === JSON.stringify(b);

export const handleLanguageChange = (
  event: React.ChangeEvent<HTMLSelectElement>
) => {
  const selectedLanguage = event.target.value;
  i18n.changeLanguage(selectedLanguage);
  localStorage.setItem('language', selectedLanguage);
};

export const getSupportedMimeType = () => {
  const mimeTypes = [
    'video/webm;codecs=vp9',
    'video/webm;codecs=vp8',
    'video/webm',
    'video/mp4', // Safari는 지원하지 않음
  ];
  return (
    mimeTypes.find((mimeType) => MediaRecorder.isTypeSupported(mimeType)) || ''
  );
};
