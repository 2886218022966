import { uploadS3 } from 'constants/common';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { CircleImage, Flex, Span } from 'styles/style';
import { useAccessTokenStore, useCenterStore } from 'store/auth';
import VideoPlayer from 'components/Video/VideoPlayer';
import Icon from 'components/Icon';
import { DeleteButton } from 'styles/FormStyle';
import { UPLOAD_ALERT_TEXT } from 'constants/alertText';
import {
  postCommunity,
  postPostComplete,
  postUpdateCommunity,
} from 'apis/community';
import Img from 'components/Img';
import PageHeaderWithSub from 'components/PageHeaderWithSub';
import { useTranslation } from 'react-i18next';

export const EditCommunityPage = () => {
  const [data, setData] = useState<any>();
  const [commentOrderBy, setCommentOrderBy] = useState<'desc' | 'asc'>('desc');
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const centerId = searchParams.get('centerId');
  const postId = searchParams.get('postId');
  const { accessToken } = useAccessTokenStore();
  const [canMediaUpload, setCanMediaUpload] = useState(true);
  const [isDeleteInitMedia, setIsDeleteInitMedia] = useState<
    'image' | 'video' | '-'
  >('-');
  const timeStamp = new Date().getTime();

  const { t } = useTranslation();
  //form data 이미 들어있는 데이터
  const [content, setContent] = useState<string>('');
  const [mediaRatio, setMediaRatio] = useState<string>('');
  const [mediaDuration, setMediaDuration] = useState<number>(0);
  const [isNoti, setIsNoti] = useState<boolean>(false);
  const [uploadImage_init, setUploadImage_init] = useState<string>();
  const [uploadVideo_init, setUploadVideo_init] = useState<string>();
  //새롭게 입력받을 데이터
  const [mediaUrl, setMediaUrl] = useState<string>('');
  const [uploadImage, setUploadImage] = useState<File[]>();
  const [uploadVideo, setUploadVideo] = useState<File>();
  const [mediaFormat, setMediaFormat] = useState<string>('');
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {}, ['삭제된 미디어: ', isDeleteInitMedia]);
  useEffect(() => {
    if (uploadImage_init || uploadVideo_init) {
      setCanMediaUpload(false);
    }
  }, [uploadImage_init, uploadVideo_init]);
  useEffect(() => {
    if (data) {
      setContent(data.content);
      setMediaRatio(data.images?.ratio || data.videos?.ratio);
      setMediaDuration(data.videos?.duration);
      setUploadImage_init(data.image?.originalImage);
      setUploadVideo_init(data.video?.streamingUrl);

      setIsNoti(data.isNoti);
    }
  }, [data]);
  useEffect(() => {
    if (uploadImage && uploadImage[0]) {
      const img = new Image();
      const fileExtension = uploadImage[0].name.split('.').pop()?.toLowerCase();

      if (fileExtension) {
        setMediaFormat(fileExtension);
      }
      img.onload = function () {
        const that = this as HTMLImageElement;
        if (that.width > that.height) {
          setMediaRatio('landscape');
        } else {
          setMediaRatio('portrait');
        }
      };
      const url = URL.createObjectURL(uploadImage[0]);
      img.src = url;
      setMediaUrl(url);
    }
    if (uploadVideo) {
      const video = document.createElement('video');
      const fileExtension = uploadVideo.name.split('.').pop()?.toLowerCase();

      if (fileExtension) {
        setMediaFormat(fileExtension);
      }
      video.addEventListener('loadedmetadata', function () {
        const duration = this.duration;
        setMediaDuration(duration * 1000);
        if (this.videoWidth > this.videoHeight) {
          setMediaRatio('landscape');
        } else {
          setMediaRatio('portrait');
        }
      });
      const url = URL.createObjectURL(uploadVideo);
      video.src = url;
      setMediaUrl(url);
    }
  }, [uploadImage, uploadVideo]);

  const getData = async () => {
    if (!postId || !centerId) {
      return;
    }
    const response = await postCommunity({
      params: { postId },
      data: {
        centerId: centerId,
      },
    });
    const result = response;

    if (result === null) {
      navigate(-1);
    }
    const { view } = result;
    setData(view);
  };
  const addMedia = (file: File) => {
    if (file) {
      const fileType = file.type;

      if (fileType.startsWith('image/')) {
        setUploadImage((prev) => (prev ? [...prev, file] : [file]));
      } else if (fileType.startsWith('video/')) {
        setUploadVideo(file);
        // 비디오 파일 처리 로직
      } else {
        // 기타 파일 처리 로직
      }
      setCanMediaUpload(false);
    }
  };
  const deleteMedia = () => {
    setUploadImage([]);
    setUploadVideo(undefined);
    setCanMediaUpload(true);
  };

  const update = async () => {
    if (!postId || !centerId) return;
    const response = await postUpdateCommunity({
      params: {
        postId,
      },
      data: {
        centerId: centerId,
        content,
        isNoti,
        ...(isDeleteInitMedia === 'image' &&
          uploadImage_init && {
            deleteImage: uploadImage_init,
          }),
        ...(isDeleteInitMedia === 'video' &&
          uploadVideo_init && {
            deleteVideo: uploadVideo_init,
          }),
        uploadImage: {
          fileName:
            uploadImage &&
            uploadImage[0] &&
            uploadImage[0].name.split('/').pop()?.toLowerCase(), //String (uuid).jpeg / .png ...
          ratio: uploadImage && mediaRatio, //  Float (세로 / 가로)
        },
        uploadVideo: {
          fileName: uploadVideo && uploadVideo.name.split('/').pop(),
          ratio: uploadVideo && mediaRatio, //  Float (세로 / 가로)
          duration: uploadVideo && Math.floor(mediaDuration),
        },
      },
    });
    const result = response;
    if (!result) return;

    const { videoPresignedUrl, imagePresignedUrl } = result;
    // navigate(`/center/community/detail?postId=${postId}`)
    const uploadTasks: any[] = [];
    if (videoPresignedUrl && uploadVideo) {
      uploadTasks.push(uploadS3(videoPresignedUrl, uploadVideo));
    } else if (imagePresignedUrl && uploadImage && uploadImage[0]) {
      uploadTasks.push(uploadS3(imagePresignedUrl, uploadImage[0]));
    }
    uploadTasks.length > 0 &&
      Promise.all(uploadTasks)
        .then(() => {
          postId && videoPresignedUrl && sendComplete(postId);
        })
        .catch((error) => {
          console.error('An error occurred during the upload:', error);
          alert(UPLOAD_ALERT_TEXT);
        });
    navigate(`/center/community/detail?postId=${postId}&centerId=${centerId}`);
  };

  const sendComplete = async (postId: string) => {
    if (!centerId) return;
    postPostComplete({
      postId,
      centerId: centerId,
    });
  };
  return (
    <>
      {data && (
        <>
          <PageHeaderWithSub
            title={t('수정하기')}
            sub={data.displayName}
            isLineShow={true}
            maxWidth='414px'
            isHomeShow={false}
          >
            <SaveButton onClick={() => update()}>{t('완료')}</SaveButton>
          </PageHeaderWithSub>
          <Container>
            <Flex
              alignItems='flex-start'
              justifyContents='space-between'
              padding='30px 30px 0 30px'
            >
              <Flex flexDirection='column' gap='5px'>
                <Span color='var(--c500)'>{data.displayName}</Span>
              </Flex>
            </Flex>
            <MainContainer>
              <Flex justifyContents='space-between' alignItems='center'>
                <UserInfoContainer>
                  <CircleImage>
                    <img
                      src={
                        data.authorProfile?.profileImage?.thumbnailImage ||
                        '/defaultUserImage.png'
                      }
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.onerror = null; // 재귀적인 호출을 방지
                        target.src = '/defaultUserImage.png';
                      }}
                      alt='profileImage'
                    />
                  </CircleImage>
                  <Flex
                    flexDirection='column'
                    gap='3px'
                    justifyContents='center'
                  >
                    <Span>{data.authorProfile.nickname}</Span>
                  </Flex>
                </UserInfoContainer>
                <StatusButton onClick={() => setIsNoti((prev) => !prev)}>
                  {isNoti ? (
                    <>
                      <Icon
                        icon='ToggleCheckButton_Active'
                        width={16}
                        height={16}
                      />
                      <Span fontSize='16px' color='var(--primary)'>
                        {t('공지로 등록')}
                      </Span>
                    </>
                  ) : (
                    <>
                      <Icon icon='ToggleCheckButton' width={16} height={16} />
                      <Span fontSize='16px' color='var(--c100)'>
                        {t('공지로 등록')}
                      </Span>
                    </>
                  )}
                </StatusButton>
              </Flex>
              <TextArea
                defaultValue={content}
                isMedia={!!(uploadImage && uploadImage[0]) || !!uploadVideo}
                onChange={(e) => setContent(e.target.value)}
              />
              <input
                id='feedbackMedia'
                accept='image/*, video/*'
                type='file'
                style={{ display: 'none' }}
                onChange={(e) => e.target.files && addMedia(e.target.files[0])}
              />
              {}
              {canMediaUpload && (
                <label htmlFor='feedbackMedia'>
                  <AddMediaButton>
                    <Icon icon='Plus' width={10} height={10} />
                    <Span>{t('사진이나 동영상 추가하기')}</Span>
                  </AddMediaButton>
                </label>
              )}
              {uploadImage && uploadImage[0] ? (
                <>
                  <Flex position='relative'>
                    <MediaWrapper>
                      <img src={mediaUrl} alt={t('커뮤니티 이미지')} />
                    </MediaWrapper>

                    <DeleteButton onClick={() => deleteMedia()}>
                      <Icon icon='X' width={9.8} height={9.8} />
                    </DeleteButton>
                  </Flex>
                </>
              ) : (
                isDeleteInitMedia === '-' &&
                !uploadVideo &&
                uploadImage_init && (
                  <Flex position='relative'>
                    <MediaWrapper>
                      <Img src={uploadImage_init} alt={t('커뮤니티 이미지')} />
                    </MediaWrapper>
                    <DeleteButton
                      onClick={() => {
                        setIsDeleteInitMedia('image');
                        setCanMediaUpload(true);
                      }}
                    >
                      <Icon icon='X' width={9.8} height={9.8} />
                    </DeleteButton>
                  </Flex>
                )
              )}
              {uploadVideo ? (
                <Flex position='relative'>
                  <MediaWrapper>
                    <video controls={true} src={mediaUrl} />
                  </MediaWrapper>
                  <DeleteButton onClick={() => deleteMedia()}>
                    <Icon icon='X' width={9.8} height={9.8} />
                  </DeleteButton>
                </Flex>
              ) : (
                isDeleteInitMedia === '-' &&
                !uploadImage &&
                uploadVideo_init && (
                  <Flex position='relative'>
                    <MediaWrapper>
                      <VideoPlayer
                        controls={true}
                        src={uploadVideo_init}
                        autoplay={false}
                        thumbnailImage={`${data.video.thumbnailImage?.originalImage}?${timeStamp}`}
                        isStopImmediate={false}
                      />
                    </MediaWrapper>
                    <DeleteButton
                      onClick={() => {
                        setIsDeleteInitMedia('video');
                        setCanMediaUpload(true);
                      }}
                    >
                      <Icon icon='X' width={9.8} height={9.8} />
                    </DeleteButton>
                  </Flex>
                )
              )}
            </MainContainer>
            {/* {<CommentContainer orderBy={commentOrderBy} postId={data?.postId} />} */}
          </Container>
        </>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 414px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  margin: 20px auto 0;
  padding-bottom: 30px;
  cursor: default;
`;
const MainContainer = styled.div`
  padding: 32px;
`;
const UserInfoContainer = styled.div`
  display: flex;
  gap: 10px;
`;
const MediaWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  margin-top: 20px;
  overflow: hidden;
`;

type TextAreaType = {
  isMedia: boolean;
};
const TextArea = styled.textarea<TextAreaType>`
  background-color: transparent;
  color: white;
  resize: none;
  border: none;
  font-size: 1.6rem;
  width: 100%;
  height: 200px;
  margin: ${({ isMedia }) => (isMedia ? '20px 0' : '20px 0 70px')};
  :focus {
    outline: none;
  }
`;
const ButtonWrapper = styled.div`
  cursor: pointer;
`;

const AddMediaButton = styled.div`
  position: absolute;
  bottom: 30px;
  width: calc(100% - 63px);
  padding: 15px;
  border-radius: 12px;
  background-color: var(--c700);
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: var(--c300);
  font-size: 1.6rem;
  cursor: pointer;
`;

const SubmitButton = styled.div`
  background-color: var(--primary);
  border-radius: 6.5px;
  padding: 8px 10px;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  width: 50px;
  text-align: center;
  cursor: pointer;
  color: black;
`;

const StatusButton = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
`;

const SaveButton = styled.div`
  font-family: 'SF Pro Text';
  font-size: 1.3rem;
  letter-spacing: -0.5px;
  position: fixed;
  color: white;
  top: 20px;
  right: 20px;
  z-index: 9999;
  cursor: pointer;
`;
