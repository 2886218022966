import {
  postSignupCenter,
  postUpdateProfile,
  postUpdateStaffProfile,
  profileQueryDataType,
} from 'apis/center';
import Icon from 'components/Icon';
import Img from 'components/Img';
import { allowedImageFormats, uploadS3 } from 'constants/common';
import {
  ProfileType,
  StaffDetailInfoNameTypes,
  StaffProfileType,
} from 'constants/types/type';
import { t } from 'i18next';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  FieldValues,
  useForm,
  useFormContext,
  UseFormGetValues,
  UseFormRegister,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useProfileStore } from 'store/auth';

import { Flex, Span } from 'styles/style';
import * as S from './style';

type CenterProfileModalBaseType = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
type CenterProfileModalTypeSignupMode = CenterProfileModalBaseType & {
  mode: 'signup';
  profile?: never;
};
type CenterProfileModalTypeEditMode = CenterProfileModalBaseType & {
  mode: 'edit';
  profile: ProfileType;
};
type CenterProfileModalTypeViewMode = CenterProfileModalBaseType & {
  mode: 'view';
  profile: ProfileType;
};
type CenterProfileModalType =
  | CenterProfileModalTypeSignupMode
  | CenterProfileModalTypeViewMode
  | CenterProfileModalTypeEditMode;
const CenterProfileModal = ({
  setIsModalOpen,
  profile,
  mode,
}: CenterProfileModalType) => {
  const [imageUrl, setImageUrl] = useState<string>('');
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const centerId = searchParams.get('centerId');
  const profileImage = watch('profileImage');
  const { profile: store_profile, setProfile: store_setProfile } =
    useProfileStore();
  const { t } = useTranslation();

  useEffect(() => {
    if (profile) {
      profile.profileImage?.mediumImage &&
        setImageUrl(profile.profileImage.mediumImage);

      setValue('nickname', profile.nickname);
    }
    if (profile && (profile.role === 'staff' || profile.role === 'owner')) {
      const staffProfile = profile as StaffProfileType;
      if (staffProfile.profileDetail) {
        const { explain, education, careerawards, certificatewriting, etc } =
          staffProfile.profileDetail;
        setValue('explain', explain);
        setValue('education', education);
        setValue('careerawards', careerawards);
        setValue('certificatewriting', certificatewriting);
        setValue('etc', etc);
      }
    }
  }, [profile]);
  useEffect(() => {
    if (profileImage && profileImage.length > 0) {
      const file = profileImage[0];
      setImageUrl(URL.createObjectURL(file));
    }
  }, [profileImage]);

  const signupProfile = async (data: any) => {
    if (!centerId) {
      return;
    }
    try {
      const { imagePresignedUrl, watchingProfile } = await postSignupCenter({
        centerId,
        profileImage: data.profileImage.length > 0 ? 'profileImage.png' : '',
        nickname: data.nickname,
      });
      if (imagePresignedUrl) uploadS3(imagePresignedUrl, data.profileImage[0]);

      store_setProfile(watchingProfile);

      setIsModalOpen(false);
    } catch (e) {
      alert(t('요청이 실패하였습니다'));
      console.error(e);
    }
  };
  const updateMemberProfile = async (data: any) => {
    if (!centerId) {
      return;
    }
    try {
      const body: any = {
        centerId,
        profileImage: data.profileImage.length > 0 ? 'profileImage.png' : '',
        nickname: data.nickname,
      };
      const { imagePresignedUrl, myProfile } = await postUpdateProfile(body);
      if (imagePresignedUrl) uploadS3(imagePresignedUrl, data.profileImage[0]);

      //프로필 수정은 본인만 가능
      store_setProfile(myProfile);

      setIsModalOpen(false);
    } catch (e) {
      alert(t('요청이 실패하였습니다'));
      console.error(e);
    }
  };
  const updateStaffProfile = async (data: any) => {
    if (!centerId) {
      return;
    }
    try {
      const { explain, education, careerawards, certificatewriting, etc } =
        data;
      const body = {
        centerId,
        profileImage: data.profileImage.length > 0 ? 'profileImage.png' : '',
        nickname: data.nickname,
        profileDetail: {
          careerawards,
          certificatewriting,
          education,
          etc,
          explain,
        },
      };
      const { imagePresignedUrl, myProfile } = await postUpdateStaffProfile(
        body
      );
      if (imagePresignedUrl) uploadS3(imagePresignedUrl, data.profileImage[0]);

      //프로필 수정은 본인만 가능
      store_setProfile(myProfile);

      setIsModalOpen(false);
    } catch (e) {
      alert(t('요청이 실패하였습니다'));
      console.error(e);
    }
  };

  const submitHandler = (data: any) => {
    switch (mode) {
      case 'signup':
        signupProfile(data);
        break;
      case 'edit':
        profile.role === 'staff' || profile.role === 'owner'
          ? updateStaffProfile(data)
          : updateMemberProfile(data);
        break;
    }
  };
  return (
    <S.Background
      onClick={() => {
        setIsModalOpen(false);
      }}
    >
      <S.FormWrapper>
        <S.Form
          onSubmit={handleSubmit((data) => {
            submitHandler(data);
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <S.Title>
            {mode === 'view' ? t('프로필') : t('센터 프로필 설정')}
          </S.Title>
          <S.ImageContainer>
            <S.ImageWrapper>
              <Img
                src={imageUrl ? imageUrl : '/defaultUserImage.png'}
                alt='profileImage'
                isTimestamp={false}
              />
            </S.ImageWrapper>
            <input
              id='profileImage'
              type='file'
              accept={allowedImageFormats}
              style={{ display: 'none' }}
              {...register('profileImage', {
                validate: (value) => {
                  if (imageUrl) return true; // 이미지 URL이 있으면 유효성 검사 통과
                  if (value && value.length > 0) return true; // 파일이 업로드되면 유효성 검사 통과
                  return t('사진을 업로드해주세요'); // 둘 다 없으면 에러 메시지 표시
                },
              })}
            />
            {mode !== 'view' && (
              <label htmlFor='profileImage'>
                <S.PlusButton>
                  <Icon icon='Plus' width={15} height={15} color='black' />
                </S.PlusButton>
              </label>
            )}
          </S.ImageContainer>
          {errors.profileImage && (
            <Flex width='100%' justifyContents='center'>
              <S.ErrorSpan>
                {errors.profileImage.message?.toString()}
              </S.ErrorSpan>
            </Flex>
          )}
          <S.Input
            placeholder={t('닉네임을 입력해주세요')}
            readOnly={mode === 'view'}
            {...register('nickname', {
              required: t('닉네임을 입력해주세요'),
            })}
          />

          {/* 스태프 전용 추가 정보 */}
          {(profile?.role === 'staff' || profile?.role === 'owner') && (
            <DetailInfoOnlyStaff
              register={register}
              mode={mode}
              getValues={getValues}
            />
          )}

          {(errors.explain ||
            errors.education ||
            errors.careerawards ||
            errors.certificatewriting) && (
            <S.ErrorSpan>{t('필수 사항을 모두 입력해주세요')}</S.ErrorSpan>
          )}
          {mode !== 'view' && (
            <S.ButtonContainer>
              <S.CancleButton onClick={() => setIsModalOpen(false)}>
                {t('취소')}
              </S.CancleButton>
              <S.SubmitButton type='submit'>
                {mode === 'signup' ? t('가입 신청') : t('변경하기')}
              </S.SubmitButton>
            </S.ButtonContainer>
          )}
        </S.Form>
      </S.FormWrapper>
    </S.Background>
  );
};

type DetailInfoOnlyStaffType = {
  register: UseFormRegister<FieldValues>;
  mode: 'signup' | 'edit' | 'view';
  getValues: UseFormGetValues<FieldValues>;
};
type TextareaPropsType = {
  name: StaffDetailInfoNameTypes;
  title: string;
  placeholder: string;
  requiredMessage: string;
  required?: boolean;
};
const DetailInfoOnlyStaff = ({
  register,
  mode,
  getValues,
}: DetailInfoOnlyStaffType) => {
  const values = getValues();

  const data: TextareaPropsType[] = [
    {
      name: 'explain',
      title: t('소개'),
      placeholder: t('소개를 입력해주세요. (전문성 중심)'),
      requiredMessage: t('소개를 입력해주세요'),
    },
    {
      name: 'education',
      title: t('학력 사항'),
      placeholder: t('학력 사항을 입력해주세요'),
      requiredMessage: t('학력 사항을 입력해주세요'),
    },
    {
      name: 'careerawards',
      title: t('경력 및 수상내역'),
      placeholder: t('경력 및 수상내역을 입력해주세요'),
      requiredMessage: t('경력 및 수상내역을 입력해주세요'),
    },
    {
      name: 'certificatewriting',
      title: t('자격증 및 저서'),
      placeholder: t('자격증 및 저서를 입력해주세요'),
      requiredMessage: t('자격증 및 저서를 입력해주세요'),
    },
    {
      name: 'etc',
      title: t('기타 정보(선택)'),
      placeholder: t('기타 정보를 입력해주세요'),
      requiredMessage: t(''),
      required: false,
    },
  ];

  let validValueCount = 0;

  return (
    <>
      {data.map((item) => {
        const value = values[item.name];
        if (!value && mode === 'view') {
          return null;
        } else {
          validValueCount++;
          return (
            <S.TextareaContainer key={item.name}>
              <S.SubTitle>{item.title}</S.SubTitle>
              <AutoResizeTextarea
                register={register}
                name={item.name}
                placeholder={item.placeholder}
                requiredMessage={item.requiredMessage}
                readOnly={mode === 'view'}
              />
            </S.TextareaContainer>
          );
        }
      })}
      {validValueCount < 1 && (
        <>
          <S.Line />
          <Flex justifyContents='center'>
            <Span fontSize='1.2rem' color='var(--c200)'>
              {t('프로필 준비중입니다')}
            </Span>
          </Flex>
        </>
      )}
    </>
  );
};

type AutoResizeTextareaType = {
  register: UseFormRegister<FieldValues>;
  name: StaffDetailInfoNameTypes;
  placeholder: string;
  required?: boolean;
  requiredMessage?: string;
  readOnly: boolean;
};
const AutoResizeTextarea = ({
  register,
  name,
  placeholder,
  required = true,
  requiredMessage,
  readOnly,
}: AutoResizeTextareaType) => {
  const textarea_ref = useRef<HTMLTextAreaElement | null>(null);

  const handler = () => {
    if (textarea_ref.current) {
      textarea_ref.current.style.height = 'auto';
      const scrollHeight = textarea_ref.current.scrollHeight;
      textarea_ref.current.style.height = scrollHeight + 'px';
    }
  };

  useEffect(() => {
    handler();
  }, [textarea_ref.current?.value]);

  const { ref, ...rest } = register(name);

  return (
    <S.Textarea
      placeholder={placeholder}
      readOnly={readOnly}
      {...register(name, {
        required: required ? requiredMessage : false,
        onChange: () => handler(),
      })}
      ref={(e) => {
        ref(e);
        textarea_ref.current = e;
      }}
    />
  );
};
export default CenterProfileModal;
