import { Map, MapMarker } from 'react-kakao-maps-sdk';
import AspectRatioBox from 'components/AspectRatioBox';

import * as S from './style';
import { useTranslation } from 'react-i18next';

type LocationType = {
  latitude: string;
  longitude: string;
};
const Location = ({ latitude, longitude }: LocationType) => {
  const { t } = useTranslation();

  return (
    <>
      <S.Title>{t('센터 위치')}</S.Title>
      <S.MapWrapper>
        <AspectRatioBox ratio={0.4}>
          <Map
            center={{
              lat: Number(latitude),
              lng: Number(longitude),
            }}
            // onCenterChanged={(map) => {
            //   var locPosition = new kakao.maps.LatLng(
            //     Number(latitude),
            //     Number(longitude)
            //   );
            //   map.setCenter(locPosition);
            // }}
            style={{ width: '100%', height: '100%' }}
          >
            <MapMarker
              position={{
                lat: Number(latitude),
                lng: Number(longitude),
              }}
            ></MapMarker>
          </Map>
        </AspectRatioBox>
      </S.MapWrapper>
    </>
  );
};
export default Location;
