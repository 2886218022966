import { theme } from 'constants/theme';
import styled from 'styled-components';

export const TagContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  padding-bottom: 10px;
  overflow-x: auto;

  @media ${theme.tablet} {
    padding-bottom: 0px;
  }
`;
type TagImageContainerType = {
  backgroundImage: string;
};
export const TagImageContainer = styled.div<TagImageContainerType>`
  position: relative;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 100%;
  overflow: hidden;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${({ backgroundImage }) => backgroundImage});
  background-color: var(--c800);
  background-size: cover;
  cursor: pointer;
`;
export const TagName = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
`;
