import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Span } from 'styles/style';

type ConvertButtonType = {
  backgroundColor?: string;
  setFlipped: React.Dispatch<React.SetStateAction<boolean>>;
};
export const ConvertButton = ({
  backgroundColor,
  setFlipped,
}: ConvertButtonType) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setFlipped((prev) => !prev);
  };
  const { t } = useTranslation();

  return (
    <ConvertButtonDiv
      backgroundColor={backgroundColor}
      onClick={(e) => handleClick(e)}
    >
      <Icon icon='FilterVertical' width={12} height={12} />
      <Span>{t('좌우반전')}</Span>
    </ConvertButtonDiv>
  );
};

type ConvertButtonDivType = {
  backgroundColor?: string;
};
export const ConvertButtonDiv = styled.div<ConvertButtonDivType>`
  width: auto;
  max-width: 101px;
  height: 36px;
  padding: 15px;
  border-radius: 18px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'var(--c800)'};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
`;
