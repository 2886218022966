import styled from 'styled-components';
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
  padding-top: 3px;
  z-index: 2;
  > * {
    z-index: 2;
  }
`;
export const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: var(--c900);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
`;
export const Contaienr2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px 0;
  justify-content: center;
`;
export const Title = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 16.71px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-bottom: 6px;
`;
export const SubInfo = styled.span`
  font-size: 1.2rem;
  margin-bottom: 20px;
`;
export const SubDesc = styled.span`
  display: flex;
  gap: 2px;
  margin-top: 2px;
  margin-bottom: 10px;
  > span {
    font-size: 1rem;
    font-weight: 400;
    line-height: 11.93px;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--c500);
  }
`;
export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  overflow: hidden;
`;
export const Desc = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 14.32px;
  letter-spacing: -0.5px;
  color: var(--c500);
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  justify-content: center;
`;
export const ThumbImageWrapper = styled.div`
  width: 80px;
  height: 143px;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
`;
export const ContentWrapper = styled.div`
  font-size: 1.4rem;
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--c100);
  cursor: pointer;
  :hover {
    color: white;
  }
`;
export const AddFeedbackButton = styled.div`
  width: 124px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 18px;
  background: rgb(80 81 86 / 70%);
  color: white;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  margin-top: 5px;
  cursor: pointer;
`;
export const Line = styled.div`
  position: absolute;
  width: 1px;
  top: 10px;
  left: 17.5px;
  height: 100%;
  background-color: var(--c800);
  z-index: 1;
`;
