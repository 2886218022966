const Noti_Out = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M10.5 12.6875C10.4387 14.2308 9.15258 15.5412 7.4297 15.499C7.02887 15.4892 6.53344 15.3495 5.5426 15.07C3.15801 14.3974 1.08796 13.267 0.591296 10.7346C0.5 10.2691 0.5 9.74532 0.5 8.69771L0.5 7.30229C0.5 6.25468 0.5 5.73087 0.591296 5.26538C1.08796 2.73304 3.15801 1.60263 5.5426 0.930022C6.53345 0.650537 7.02887 0.510795 7.4297 0.500989C9.15257 0.458841 10.4387 1.76923 10.5 3.31251'
      stroke='#D3D4DC'
      strokeLinecap='round'
    />
    <path
      d='M15.5 7.99996H6.33334M15.5 7.99996C15.5 7.41644 13.8381 6.32623 13.4167 5.91663M15.5 7.99996C15.5 8.58348 13.8381 9.67368 13.4167 10.0833'
      stroke='#D3D4DC'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default Noti_Out;
