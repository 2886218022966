import {
  Title,
  Container,
  Contents,
  TermBody,
  TermTitle,
  TermBox,
  Dl,
  Dt,
  Dd,
  Table,
  Th,
  Td,
} from 'styles/TermStyle';
import { Margin } from 'styles/style';
import { CloseButtonOnTerms } from 'components/CloseButtonOnTerms';

export const Term2 = () => {
  return (
    <Container>
      <CloseButtonOnTerms />
      <Title>Real-Feed 개인정보 처리방침</Title>
      <Margin margin='0 0 20px 0' />
      <Dd>
        안녕하세요. 저희는 Real-Feed 서비스를 운영하고 있는 주식회사
        스판링크(이하 ‘회사’)라 고 합니다. 회사는 『정보통신망 이용촉진 및
        정보보호 등에 관한 법률』, 『통신비밀보호법』, 『전기 통신사업법』,
        『개인정보보호법』 등 정보통신서비스제공자가 준수하여야 할 관련 법령상
        의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을
        정하여 이용자 의 권익 보호에 최선을 다하고 있습니다. 본
        개인정보처리방침은 Real-Feed 서비스 이용 자(회원, 비회원 모두 포함)에게
        적용되며, 다음과 같은 내용을 담고 있습니다
      </Dd>
      <Margin margin='0 0 20px 0' />
      <TermBox>
        <Dl>
          <Dt>1. 개인정보의 수집 및 이용 목적</Dt>
          <Dd>
            1.1. 회사는 다음과 같은 방법으로 개인정보를 수집합니다.
            <br />• 홈페이지, 어플리케이션, 서면, 팩스, 전화, 고객센터 문의,
            이메일, 이벤트 응모를 통한 수집
            <br /> 1.2. 회사는 다음과 같은 목적을 위하여 개인정보를 처리하고
            있으며, 다음의 목적 이외의 용도로는 이용하지 않으며, 수집 및 이용
            목적이 변경될 경우에는 이용자로부터 별도의 사 전 동의를 구합니다.
            <br />• 회사는 원활한 서비스를 제공하고 더욱 향상된 경험을 드리기
            위하여 필요한 회원 의 개인정보를 수집 또는 이용합니다. 회원이 동의한
            이용약관에 따라 서비스의 기본 기능이나 여러 특화된 기능을 제공하기
            위해서 회원의 이메일, 생년월일, 성별, 사용기기 등의 확인이 필요한
            경우가 있습니다.
            <br />• 또한 회사가 회원에게 개별적으로 알려 드릴 사항이 있을 때나
            회원이 서비스 이 용과 관련하여 문의나 분쟁이 있을 경우를 위해서도
            필요합니다.
            <br />• 그 외에도 새로운 서비스 개발 및 기능 개선, 맞춤형 서비스
            제공 및 광고 게재, 각종 이벤트 및 광고성 정보의 제공 및 참여 기회
            제공, 법령 등에 규정된 의무와 이행 법령이나 이용약관에 반하여
            회원에게 피해를 줄 수 있는 잘못된 이용행위를 방지 및 차단 등을
            위해서도 회원분들의 개인 정보가 필요합니다. <br />• 이벤트 정보,
            광고성 정보 제공 등 이벤트 및 프로모션의 목적 등에 개인정보를
            이용합니다(마케팅, 광고성 정보 제공 동의 시에 한함)
          </Dd>
          <Dt>2. 수집하는 개인정보 항목</Dt>
          <Dd>
            2.1. 회사가 보다 나은 서비스를 제공하기 위해서 수집하는 이용자의
            개인정보는 다음과 같습니다.
            <br />
            <Margin margin='10px 0 0 0' />
            <Table>
              <tbody>
                <tr>
                  <Th>구분</Th>
                  <Th>수집항목</Th>
                </tr>
                <tr>
                  <Td>회원가입</Td>
                  <Td>닉네임, 이메일 주소, 비밀번호, 휴대폰번호</Td>
                </tr>
              </tbody>
            </Table>
            <br />
            2.2. 위 2.1.의 개인정보 항목 외에 서비스 이용 과정이나 앱 실행
            과정에서 IP Address, 쿠키, 방문일시, 서비스 이용기록, 불량 이용기록,
            기기정보, 위치정보(위치기반서비스약관 동의 시)가 수집될 수 있으며,
            고객 문의 접수 및 처리 과정에서 이메일 주소나 전화번호가 수집될 수도
            있습니다.
            <br /> 2.3. 회사가 제공하는 서비스 이용과정에서 이용자의 식별 및
            본인여부, 연령 확인이 필요 한 경우 이름, 생년월일, 성별,
            내외국인정보, 휴대폰번호 또는 아이핀 번호, 암호화된 동일 인
            식별연계정보(CI), 중복가입확인정보(DI). 미성년자의 경우
            법정대리인정보가 자동으로 생성 및 추가되어 수집될 수 있습니다.
            <br /> 2.4. 회사는 이용자의 기본적 인권 침해 우려가 있는 민감한
            개인정보(인종, 사상, 신조, 정 치적 성향, 범죄기록, 의료 정보 등)는
            수집하지 않으며, 이용자가 선택 항목의 정보를 입력 하지 않은 경우에도
            회사가 제공하는 서비스 이용에 제한은 없습니다.
          </Dd>
          <Dt>3. 개인정보의 보유 및 이용기간</Dt>
          <Dd>
            3.1. 이용자가 회원으로 가입한 날부터 서비스를 이용하는 기간 동안에만
            이용자의 개인 정 보를 보유 및 이용합니다. 개인정보의 수집 및 이용
            동의를 철회할 경우 또는 수집 및 이용 목적이 달성되거나 보유 및 이용
            기간이 종료한 경우 개인 정보를 지체없이 파기합니다. 이 경우
            개인정보는 어떠한 용도로도 열람 또는 이용할 수 없도록 처리됩니다.
            다만, 회사는 거래 관련 분쟁 방지 등을 위하여 다음과 같은 경우에는
            회원탈퇴(계정삭제) 이후에도 명시 구분 수집항목 회원가입 닉네임,
            이메일 주소, 비밀번호, 휴대폰번호 한 기간 동안 보유합니다. <br />•
            회사 내부 방침에 의한 개인정보 보존 사유 <br />• 이용자에 대하여
            관계 법령 위반에 따른 수사 · 조사 등이 진행중인 경우: 해당 수사 ·
            조사 종료 시까지 <br />• 회사와 이용자 사이에 채권 · 채무관계가
            잔존하는 경우: 해당 채권 · 채무관계 정산 완료 시까지 <br />• 상법,
            『전자상거래 등에서의 소비자보호에 관한 법률』 등 관계 법령의 규정에
            따라 보존할 필요가 있는 경우 회사는 관계 법령에서 정한 일정한 기간
            동안 이용 자의 정보를 보존합니다. 이 경우 회사는 보존하는 정보를 그
            보존의 목적으로만 사용하며 보존기간은 아래와 같습니다.
            <br />
            ◼ 서비스 이용 기록, 접속 로그, 접속 IP 정보 <br />
            • 보존 기간: 1년보존 이유: 통신비밀보호법 <br />
            ◼ 표시 /광고에 관한 기록 <br />
            • 보존 기간: 6개월보존 이유: 전자상거래 등에서의 소비자보호에 관한
            법률
            <br />
            ◼ 계약 또는 청약 철회 등에 관한 기록 <br />• 보존 기간: 5년보존
            이유: 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            ◼ 대금 결제 및 재화 등의 공급에 관한 기록 (주문이력) <br />• 보존
            기간: 5년보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            ◼ 소비자의 불만 또는 분쟁 처리에 관한 기록 (상담이력) 보존 기간:
            3년보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률 <br />•
            다만 서비스의 부정한 이용으로 인한 분쟁을 방지하기 위한 내부방침에
            따라 서비 스의 부정 이용 기록은 1년간 보존한 다음 파기됩니다. 여기서
            ‘부정한 이용’이란 <br />① 회사가 정한 정책(약관 등 포함)에 위배되는
            방법이나 내용의 거래, <br />② 회사, 회원, 제3자의 권리나 이익을
            침해하는 방법이나 거래,
            <br /> ③ ①과 ②에 준하는 내용 의 거래를 의미합니다. <br />
            3.2. 1년간 서비스 이용기록이 없는 이용자의 개인정보는 파기 또는
            별도로 분리 보관하여 관리됩니다. 회사는 휴면계정으로 전환되는
            시점으로부터 최소 30일 전에 휴면계정에 대한 내용을 이메일 등을 통해
            사전 통지합니다.
          </Dd>
          <Dt>4. 개인정보의 파기</Dt>
          <Dd>
            4.1. 회원의 개인정보는 회원가입 해지 시(탈퇴/계정삭제 시) 또는 제공
            목적 달성 후 별도 의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부
            방침 및 기타 관련 법령에 의한 정보보 호 사유에 따라(보유 및 이용기간
            참조) 일정 기간 저장된 후 기록을 재생할 수 없는 기술 적 방법에
            의하여 영구 삭제됩니다. 별도 DB로 옮겨진 개인정보는 법률에 의한
            경우가 아닌 한 보유 이외의 다른 목적으로 이용되지 않습니다.
            <br /> 4.2. 종이에 기록, 저장된 개인정보는 분쇄기로 분쇄하거나
            소각을 통하여 파기하며, 전자적 파일 형태로 저장된 개인정보는 기록을
            재생할 수 없도록 기술적 방법을 사용하여 삭제합니 다.
          </Dd>
          <Dt>5. 개인정보 제3자 제공 및 위탁</Dt>
          <Dd>
            5.1. 회사는 원칙적으로 이용자의 개인정보를 수집 및 이용목적 범위를
            넘어 제3자에게 제공하거나 외부에 공개하지 않습니다. 다만, 보다 나은
            서비스 제공을 위하여 이용자의 개 인정보를 협력업체 등과 공유할
            필요가 있는 경우에는 제공받는 자, 제공 목적, 제공 정보 항목, 이용 및
            보유기간 등을 이용자에게 고지하여 동의를 구합니다. 또한 법령의
            규정에 의거하거나, 수사목적으로 법령에서 정해진 절차와 방법에 따라
            수사기관의 요구가 있는 경 우에는 이용자의 개인정보를 제공할 수
            있습니다.
            <br /> 5.2. 회사는 이용자의 개인정보를 회사가 제공하는 서비스 외의
            용도로 사용하거나 이용 자의 동의 없이 제3자에게 제공하지 않습니다.
            필요에 의하여 제3자에게 제공할 경우에는 이용자에게 알리고 별도의
            동의를 받습니다. 다만, 다음 각 사항의 경우에는 예외로 합니다.
            <br />• 이용자가 회사의 이용약관을 위배하거나 서비스를 이용하여
            타인에게 피해를 주거 나 미풍양속을 해치는 위법행위를 한 경우, 법적인
            조치를 취하기 위하여 개인정 보를 공개해야 한다고 판단되는 경우{' '}
            <br />• 법령의 규정에 의거하거나, 수사목적으로 법령에서 정해진
            절차와 방법에 따라 수 사기관의 요구가 있는 경우 <br />• 통계작성,
            학술연구, 시장조사, 정보제공 및 공지 안내 메일 발송의 경우로서 특정
            개인을 식별할 수 없는 형태로 제공되는 경우 <br />• 이용자가 사전에
            동의한 경우 5.2. 회사는 서비스 향상, 법령이나 이용약관에 반하여 다른
            회원들에게 피해를 줄 수 있는 잘못된 이용행위의 차단, 방지 및
            안정적인 개인정보 취급을 위해서 회원의 개인정보를 외 부에 위탁하여
            처리할 수 있습니다. 위탁처리 기관 및 위탁업무의 내용은 아래를 참조해
            주세요.
            <br /> 수탁업체 : Amazon Web Service
            <Margin margin='10px 0 0 0' />
            <Table>
              <tr>
                <Th>수탁업체명</Th>
                <Th>위탁업무 내용</Th>
                <Th>개인정보의 보유 및 이용기간</Th>
              </tr>
              <tr>
                <Td>Amazon Web Services, Inc.</Td>
                <Td>AWS를 통한 시스템 관리</Td>
                <Td>회원탈퇴 시 혹은 위탁계약 종료 시 까지</Td>
              </tr>
            </Table>
          </Dd>
          <Dt>6. 개인정보의 암호화 및 해킹 등에 대한 기술적 대책</Dt>
          <Dd>
            6.1. 회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실,
            도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
            내부관리계획을 수립/시행하고 접근통제장치를 설치 및 운영하며,
            접속기록의 위/변조 방지를 위해 보안기능을 사용하는 등 기술적/관리적
            대책 을 강구하고 있습니다. <br />- 개인정보 암호화: 회사는 비밀번호,
            결제수단 등의 개인정보를 법령에서 요구하는 기준에 따라 암호화
            처리하고 있으며, 중요한 데이터는 파일 및 전송 데이터를 암호화하는 등
            별도 의 보안 조치를 취하여 이용자의 개인정보를 보호하고 있습니다.{' '}
            <br />- 해킹 등에 대비한 대책: 회사는 해킹이나 컴퓨터 바이러스 등에
            의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을
            다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고
            있고, 최신 백신프로그램을 이용하여 이용자 들의 개인정보나 자료 가
            유출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여
            네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
            그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
            있으며, 기타 시스템적으로 보안성을 확보하기 위한 가 능한 모든 기술적
            장치를 갖추기 위하여 노력하고 있습니다. <br />- 처리 직원의 최소화
            및 교육: 회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있 고,
            이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며,
            담당자에 대한 수 시 교육을 통하여 회사 개인정보처리방침의 준수를
            항상 강조함으로써 개인정보 보호가 실 질적으로 이루어질 수 있도록
            계속적으로 노력하고 있습니다. <br />
            6.2. 위 6.1.에도 불구하고 이용자 본인의 고의 또는 부주의나
            인터넷상의 문제로 닉네임, 비밀번호, 주민등록번호 등 개인정보가
            유·노출되어 발생한 문제에 대해 회사는 일체의 책 임을 지지 않습니다.
          </Dd>
          <Dt>7. 이용자의 권리</Dt>
          <Dd>
            7.1. 이용자는 언제든지 자신의 개인정보를 조회하거나 수정할 수
            있으며, 수집 및 이용에 대한 동의 철회(해지, 계정삭제)를 요청할 수
            있습니다. <br />
            7.2. 이용자 및 법정대리인은 언제든지 자신의 개인정보를 조회하거나
            수정할 수 있으며, 수집 및 이용에 대한 동의 철회(가입 해지)를 요청할
            수 있습니다. 해당 조치가 원활하지 않을 경우에는 고객센터에
            요청하시면 신속히 처리할 수 있도록 하겠습니다.
          </Dd>
          <Dt>8. 개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항</Dt>
          <Dd>
            8.1. 회사는 이용자에게 원활한 서비스 제공을 위하여 이용자들의 정보를
            저장하고, 수시로 불러내는 찾아내는 ‘쿠키(cookie)’등을 운용합니다.
            쿠키란 웹사이트를 운영하는 데 이용되 는 서버가 이용자의 브라우저에
            보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디 스크에
            저장되기도 합니다(쿠키의 의미). <br />
            8.2. 회사는 이용자의 접속 빈도나 방문 시간 등을 분석, 이용자의
            취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문
            횟수 파악 등을 통한 타깃 마케팅 및 개인 맞춤 서비스 제공 등의
            목적으로 쿠키를 사용합니다(쿠키의 사용 목적). 수탁업체명 위탁업무
            내용 개인정보의 보유 및 이용기간 Amazon Web Services, Inc. AWS를
            통한 시스템 관 리 회원탈퇴 시 혹은 위탁계약 종료 시 까지 <br />
            8.3. 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서,
            이용자는 웹 브라우저 에서 옵션을 설정함으로써 모든 쿠키를
            허용하거나, 쿠키가 저장될 때마다 확인을 거치거 나, 아니면 모든
            쿠키의 저장을 거부할 수도 있습니다(쿠키의 설치, 운영 및 거부). 다만,
            이용자가 쿠키 설치를 거부하는 경우 로그인이 필요한 일부 서비스
            이용에 어려움이 있을 수 있습니다. <br />
            8.4. 위 8.3.에 따라 쿠키 설정을 거부하는 방법으로, 이용자는 사용하는
            웹 브라우저의 옵 션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를
            저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다{' '}
            <br />
            8.5. 회사는 PC 환경과 동일·유사한 인터넷 환경을 모바일 서비스에서도
            제공하기 위해 모 바일 기기 (예: 스마트 폰, 태블릿 PC)에서도
            '쿠키(cookie)'를 사용할 수 있습니다. 회사는 관련 법령 및 규제기관의
            가이드라인에 따라 쿠키를 수집하며 단, 이용자의 개인정보를 포 함하여
            그 자체로 개인을 식별할 수 있는 쿠키는 이용자의 사전 동의 없이
            임의로 수집하지 않습니다. <br />
            8.6. 모바일 기기에서도 웹브라우저 설정 기능에서 쿠키 허용 여부를
            선택할 수 있습니다. 모바일 기기의 운영체제 및 웹 브라우저 종류에
            따라 다소 상이할 수 있지만, 대부분의 경 우 모바일 웹브라우저의 환경
            설정을 통해 쿠키허용 여부를 결정하거나, 기존의 쿠키 일체 를 삭제할
            수 있습니다. 다만 쿠키의 저장을 거부할 경우, 로그인이 필요한 일부
            서비스의 이용에 불편함이 있을 수 있습니다.
          </Dd>
          <Dt>9. 개인정보보호 책임자</Dt>
          <Dd>
            회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
            처리와 관련한 정보주체 의 불만처리 및 피해구제 등을 위하여 아래와
            같이 개인정보보호 책임자를 지정하고 있습니 다. <br />
            ◼ 개인정보보호 책임자 <br />
            성명: 정영권 <br />
            직책: 대표 <br />
            연락처: 02-555-0634 , spanlink@naver.com
            <br />◼ 개인정보보호 담당부서
            <br /> 성명: 김창운 <br /> 연락처: 02-555-0634 , kcw@whistle-pro.com
          </Dd>
          <Dt>10. 개인정보 열람청구</Dt>
          <Dd>
            기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래기관에
            문의하시기 바랍니 다. <br />
            ◼ 개인정보 침해신고센터 (한국인터넷진흥원 운영) (국번없이) 118
            (privacy.kisa.or.kr)
            <br />
            ◼ 대검찰청 사이버범죄수사단: (국번없이) 1301 (spo.go.kr)
            <br />◼ 경찰청 사이버안전지킴이: (국번없이) 182 (police.go.kr)
          </Dd>
          <Dt>11. 회사의 고지의무</Dt>
          <Dd>
            회사는 현행 개인정보처리방침에 대한 내용 추가, 삭제 및 수정이 있을
            시에는 가능한 변경 사항의 시행 7일 전부터 플랫폼을 통해 고지할
            것입니다. 다만, 회사는 개인정보의 수집항 목, 활용목적, 제3자 제공
            등과 같이 이용자의 권리의 중요한 변경이 있을 경우에는 30일 전에
            고지합니다. 본 개인정보처리방침은 2023년 9월 18일부터 적용됩니다
          </Dd>
        </Dl>
      </TermBox>
    </Container>
  );
};
