import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  gap: 10px;
  align-items: center;

  border-radius: 8px;
  background-color: var(--background-color);
  padding: 10px 14px;
`;

export const Input = styled.input`
  background-color: transparent;
  width: 100%;
  color: white;
`;
