import { ProfileSample } from 'constants/types/type';
import {
  useAccessTokenStore,
  useAccountStore,
  useCenterStore,
  useProfileStore,
  useRefreshTokenStore,
} from 'store/auth';

export const logout = async (alertMessage?: string) => {
  //   await postLogout();

  useAccessTokenStore.setState({ accessToken: '' });
  useRefreshTokenStore.setState({ refreshToken: '' });
  useCenterStore.setState({
    center: {
      centerId: '',
      displayName: '',
      staffs: [],
      centerMemCount: 0,
      centerGrade: 'free',
      hasNewBadge: false,
      previewSessions: [],
      introduce: {
        text: '',
      },
      location: {
        fullAddress: '',
        latitude: '',
        longitude: '',
      },
      captions: [],
      centerProfileImage: {
        thumbnailImage: '',
        largeImage: '',
        mediumImage: '',
        originalImage: '',
      },
      bannerIds: [],
      bannerImages: [],
      sportsTagIds: [],
      ownerAccountId: '',
      activateState: 'pending',
      regNumber: '',
      isDeleted: false,
      startedAt: '',
      createdAt: '',
      approvedAt: '',
      ownerName: '',
      ownerPhone: '',
      businessRegImage: {
        thumbnailImage: '',
        largeImage: '',
        mediumImage: '',
        originalImage: '',
      },
      modifiedAt: '',
      myProfile: {
        userKey: '',
        centerId: '',
        profileImage: {
          thumbnailImage: '',
          largeImage: '',
          mediumImage: '',
          originalImage: '',
        },
        nickname: '',
        role: 'guest',
        permissionState: 'pending',
        isAdult: false,
        isDeleted: false,
        createdAt: '',
        modifiedAt: '',
      },
      terms: [],
    },
  });
  useAccountStore.setState({
    account: {
      userKey: '',
      phoneNumber: '', // masked
      username: '', // email @ 앞의 내용
      email: '',
      isAdult: false,
      createdAt: '',
    },
  });
  useProfileStore.setState({
    profile: ProfileSample,
  });

  // window.location.href = '/';
  window.location.reload();
};
