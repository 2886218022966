import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 19px 0;
`;
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 16.71px;
  letter-spacing: -0.5px;
  text-align: left;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const Name = styled.span`
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  letter-spacing: -0.5px;
  text-align: left;
`;
export const Desc = styled.span`
  color: var(--c500);
  font-size: 10px;
  font-weight: 400;
  line-height: 11.93px;
  letter-spacing: -0.5px;
  text-align: left;
`;

export const EditButton = styled.div`
  width: 81px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6.5px;
  border: 0.5px solid var(--c200);
  gap: 7px;
  background-color: var(--background-color);
  cursor: pointer;

  > span {
    color: var(--c200);
    font-size: 10px;
    font-weight: 600;
    line-height: 11.93px;
    letter-spacing: -0.5px;
  }
`;

export const NotItemDiv = styled.div`
  color: var(--c200);
  font-size: 1.2rem;
`;

// profile
export const ProfileContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
