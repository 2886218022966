import Icon from 'components/Icon';
import * as S from './style';
import { Flex, Span } from 'styles/style';
import { openNewWindow } from 'constants/common';
import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';
const Footer = () => {
  const { t } = useTranslation();
  return (
    <S.RelativeDiv>
      <S.Contaier>
        <S.LeftContainer>
          <S.CSContainer
            onClick={() => openNewWindow('http://pf.kakao.com/_zldjG')}
          >
            <Icon icon='SupportAgent' width={16} height={16} />
            <Flex gap='5px' flexDirection='column' flex={1}>
              <Span fontSize='1.4rem' fontWeight='600'>
                {t('리얼 피드 고객센터')}
              </Span>
              <Span fontSize='1.2rem' fontWeight='300'>
                {t('카카오톡 플러스친구 @리얼피드')}
              </Span>
            </Flex>
            <S.WrapIcon>
              <Icon icon='RightArrow' width={16} height={16} />
            </S.WrapIcon>
          </S.CSContainer>
          <Span fontSize='1.2rem' fontWeight='300'>
            {t(
              '운영시간 : 평일 10:00 - 19:00 (점심시간 13:00 - 14:00) 주말 및 공휴일은 휴무입니다.'
            )}
          </Span>
        </S.LeftContainer>
        <S.RightContainer>
          <Flex gap='8px' margin='0 0 16px 0'>
            <Span
              fontSize='1.2rem'
              cursor='pointer'
              onClick={() => {
                openNewWindow('/setting/terms/privacy');
              }}
            >
              {t('개인정보 취급방침')}
            </Span>
            <Span
              fontSize='1.2rem'
              cursor='pointer'
              onClick={() => {
                openNewWindow('/setting/terms/provision');
              }}
            >
              {t('이용약관')}
            </Span>
          </Flex>
          <Flex gap='12px' margin='0 0 8px 0' flexWrap='wrap'>
            <Span fontSize='1rem'>{t('상호명 : (주) 스판링크')}</Span>
            <Span fontSize='1rem'>{t('대표이사 : 정영권, 김창운')}</Span>
          </Flex>
          <Flex gap='12px' margin='0 0 8px 0' flexWrap='wrap'>
            <Span fontSize='1rem'>{t('사업자등록번호')} : 701-86-01391</Span>
            <Span fontSize='1rem'>
              {t('통신판매업신고번호')}: {t('재 2019-서울강남-02722 호')}
            </Span>
          </Flex>
          <S.MetaContainer>
            <S.MetaSpan>
              {t('주소')} :{' '}
              {t('서울특별시 송파구 올림픽로 424, SPORTS 360 LAB 201호')}
            </S.MetaSpan>
            <S.MetaSpan>{t('전화번호')} : 02-555-0634</S.MetaSpan>
            <S.MetaSpan>{t('이메일')} : admin@whistle-pro.com</S.MetaSpan>
          </S.MetaContainer>
        </S.RightContainer>
      </S.Contaier>
    </S.RelativeDiv>
  );
};
export default Footer;
