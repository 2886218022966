import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const UserIcon = (props: IconPropsWithSVGProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.86206 14.7853C6.73024 15.4592 3.76269 16.8353 5.57013 18.5573C6.45305 19.3984 7.43639 20 8.67269 20H15.7273C16.9636 20 17.947 19.3984 18.8299 18.5573C20.6373 16.8353 17.6698 15.4592 16.5379 14.7853C13.8838 13.2049 10.5161 13.2049 7.86206 14.7853Z'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.8 7.6C15.8 9.58822 14.1882 11.2 12.2 11.2C10.2118 11.2 8.6 9.58822 8.6 7.6C8.6 5.61177 10.2118 4 12.2 4C14.1882 4 15.8 5.61177 15.8 7.6Z'
      stroke='white'
    />
  </svg>
);

export default UserIcon;
