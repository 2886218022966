import VideoProgressBar from 'components/Video/VideoProgressBar';
import { getMMSSFormat_colon } from 'constants/video';
import * as S from './style';
type PlaySummaryBoxType = {
  title: string;
  currentTime: number;
  duration: number;
  currentBarHandler: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};
const PlaySummaryBox = ({
  title,
  currentTime,
  duration,
  currentBarHandler,
}: PlaySummaryBoxType) => {
  return (
    <S.Container onClick={(e) => e.stopPropagation()}>
      <S.TimeSpan>
        {getMMSSFormat_colon(currentTime).replace(' ', '')}
      </S.TimeSpan>
      <S.SceneTitleSpan>{title}</S.SceneTitleSpan>
      <VideoProgressBar
        height={2}
        backgroundColor='var(--c500)'
        borderRadius='1px'
        currentBarHandler={currentBarHandler}
        currentTime={currentTime}
        duration={duration}
        isShowCurrenetBall={false}
      />
    </S.Container>
  );
};
export default PlaySummaryBox;
