import { timeAgo, uploadS3 } from 'constants/common';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { CircleImage, Flex, Span } from 'styles/style';
import {
  useAccessTokenStore,
  useCenterStore,
  useAccountStore,
  useProfileStore,
} from 'store/auth';
import VideoPlayer from 'components/Video/VideoPlayer';
import React from 'react';
import { CommentContainer } from 'components/CommentContainer';
import Icon from 'components/Icon';
import { DeleteButton } from 'styles/FormStyle';
import { UPLOAD_ALERT_TEXT, VIDEOUPLOAD_ALERT_TEXT } from 'constants/alertText';
import MyProfileImage from 'components/MyProfileImage';
import { postCreateCommunity, postPostComplete } from 'apis/community';
import PageHeaderWithSub from 'components/PageHeaderWithSub';
import { useTranslation } from 'react-i18next';

export const AddCommunityPage = () => {
  const [content, setContent] = useState<string>('');
  const [uploadImage, setuploadImage] = useState<File[]>();
  const [uploadVideo, setUploadVideo] = useState<File>();
  const [mediaRatio, setMediaRatio] = useState<'portrait' | 'landscape'>(
    'landscape'
  );
  const [mediaDuration, setMediaDuration] = useState<number>(0);
  const [mediaUrl, setMediaUrl] = useState<string>('');
  const [mediaFormat, setMediaFormat] = useState<string>('');
  const [isNoti, setIsNoti] = useState<boolean>(false);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const centerId = searchParams.get('centerId');
  const { t } = useTranslation();

  //auth
  const { profile } = useProfileStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (uploadImage && uploadImage[0]) {
      const img = new Image();
      const fileExtension = uploadImage[0].name.split('.').pop()?.toLowerCase();

      if (fileExtension) {
        setMediaFormat(fileExtension);
      }
      img.onload = function () {
        const that = this as HTMLImageElement;
        if (that.width > that.height) {
          setMediaRatio('landscape');
        } else {
          setMediaRatio('portrait');
        }
      };
      const url = URL.createObjectURL(uploadImage[0]);
      img.src = url;
      setMediaUrl(url);
    }
    if (uploadVideo) {
      const video = document.createElement('video');
      const fileExtension = uploadVideo.name.split('.').pop()?.toLowerCase();

      if (fileExtension) {
        setMediaFormat(fileExtension);
      }
      video.addEventListener('loadedmetadata', function () {
        const duration = this.duration;
        setMediaDuration(duration * 1000);
        if (this.videoWidth > this.videoHeight) {
          setMediaRatio('landscape');
        } else {
          setMediaRatio('portrait');
        }
      });
      const url = URL.createObjectURL(uploadVideo);
      video.src = url;
      setMediaUrl(url);
    }
  }, [uploadImage, uploadVideo]);

  const submit = async () => {
    if (!centerId) {
      return;
    }
    if (content === '') {
      alert(t('게시글을 입력해주세요'));
      return;
    }
    const response = await postCreateCommunity({
      centerId,
      content: content,
      isNoti,
      uploadImage:
        uploadImage && uploadImage.length > 0
          ? {
              fileName: uploadImage[0].name.toLowerCase(),
              ratio: mediaRatio,
            }
          : {},
      uploadVideo: uploadVideo
        ? {
            fileName: uploadVideo.name.toLowerCase(),
            ratio: mediaRatio,
            duration: mediaDuration,
          }
        : {},
    });
    const result = response;
    if (!result) return;

    const { post, videoPresignedUrl, imagePresignedUrl } = result;
    const uploadTasks: any[] = [];
    //이후 작업
    if (videoPresignedUrl && uploadVideo) {
      uploadTasks.push(uploadS3(videoPresignedUrl, uploadVideo));
    } else if (imagePresignedUrl && uploadImage && uploadImage[0]) {
      uploadTasks.push(uploadS3(imagePresignedUrl, uploadImage[0]));
    }
    uploadTasks.length > 0 &&
      Promise.all(uploadTasks)
        .then(() => {
          sendComplete(post.postId);
        })
        .catch((error) => {
          console.error('An error occurred during the upload:', error);
          alert(UPLOAD_ALERT_TEXT);
        });
    navigate(
      `/center/community/detail?postId=${post.postId}&centerId=${post.centerId}`
    );
  };

  const sendComplete = async (postId: string) => {
    if (!centerId) return;
    await postPostComplete({
      postId,
      centerId,
    });
  };
  const addMedia = (file: File) => {
    if (!file) {
      return;
    }
    const fileType = file.type;

    if (fileType.startsWith('image/')) {
      setuploadImage((prev) => (prev ? [...prev, file] : [file]));
    } else if (fileType.startsWith('video/')) {
      if (file.size > 1 * 1024 * 1024 * 1024) {
        alert(VIDEOUPLOAD_ALERT_TEXT);
        return;
      }
      const videoElement = document.createElement('video');
      const objectUrl = URL.createObjectURL(file);

      videoElement.src = objectUrl;
      videoElement.addEventListener('loadedmetadata', () => {
        const videoDuration = videoElement.duration;
        if (videoDuration > 30 * 60) {
          alert(VIDEOUPLOAD_ALERT_TEXT);
          URL.revokeObjectURL(objectUrl);
          return;
        } else {
          setUploadVideo(file);
        }
      });
    } else {
    }
  };
  const deleteMedia = () => {
    setuploadImage([]);
    setUploadVideo(undefined);
  };
  return (
    <>
      {centerId && (
        <>
          <PageHeaderWithSub
            title={t('새 글쓰기')}
            sub=''
            isLineShow={true}
            maxWidth='414px'
          >
            <SaveButton onClick={() => submit()}>{t('완료')}</SaveButton>
          </PageHeaderWithSub>
          <Container>
            <MainContainer>
              <Flex justifyContents='space-between' alignItems='center'>
                <UserInfoContainer>
                  <CircleImage>
                    <MyProfileImage />
                  </CircleImage>
                  <Flex
                    flexDirection='column'
                    gap='3px'
                    justifyContents='center'
                  >
                    <Span>{profile.nickname}</Span>
                  </Flex>
                </UserInfoContainer>
                <StatusButton onClick={() => setIsNoti((prev) => !prev)}>
                  {isNoti ? (
                    <>
                      <Icon
                        icon='ToggleCheckButton_Active'
                        width={16}
                        height={16}
                      />
                      <Span fontSize='16px' color='var(--primary)'>
                        {t('공지로 등록')}
                      </Span>
                    </>
                  ) : (
                    <>
                      <Icon icon='ToggleCheckButton' width={16} height={16} />
                      <Span fontSize='16px' color='var(--c100)'>
                        {t('공지로 등록')}
                      </Span>
                    </>
                  )}
                </StatusButton>
              </Flex>
              <TextArea
                placeholder={t('커뮤니티에 한마디를 남기세요')}
                value={content}
                isMedia={!!(uploadImage && uploadImage[0]) || !!uploadVideo}
                onChange={(e) => setContent(e.target.value)}
              ></TextArea>
              <input
                id='feedbackMedia'
                accept='image/*, video/*'
                type='file'
                style={{ display: 'none' }}
                onChange={(e) => e.target.files && addMedia(e.target.files[0])}
              />
              {!(uploadImage && uploadImage[0]) && !uploadVideo && (
                <label htmlFor='feedbackMedia'>
                  <AddMediaButton>
                    <Icon icon='Plus' width={10} height={10} />
                    <Span>{t('사진이나 동영상 추가하기')}</Span>
                  </AddMediaButton>
                </label>
              )}
              {((uploadImage && uploadImage[0]) || uploadVideo) && (
                <MediaContainer>
                  <MediaWrapper>
                    {uploadImage && uploadImage[0] && <img src={mediaUrl} />}
                    {uploadVideo && <video controls src={mediaUrl} />}
                  </MediaWrapper>
                  {((uploadImage && uploadImage[0]) || uploadVideo) && (
                    <DeleteButton onClick={() => deleteMedia()}>
                      <Icon icon='X' width={9.8} height={9.8} />
                    </DeleteButton>
                  )}
                </MediaContainer>
              )}
            </MainContainer>
          </Container>
        </>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 414px;
  height: 100vh;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  margin: 20px auto 0;
  display: flex;
  flex-direction: column;
  cursor: default;
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 32px;
  flex: 1;
  overflow: auto;
`;
const MediaContainer = styled.div`
  position: relative;
  margin-top: 20px;
`;
const UserInfoContainer = styled.div`
  display: flex;
  gap: 10px;
`;
const MediaWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;
const SubmitButton = styled.div`
  background-color: var(--primary);
  border-radius: 6.5px;
  padding: 8px 10px;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  width: 50px;
  text-align: center;
  cursor: pointer;
  color: black;
`;

const StatusButton = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
`;
type TextAreaType = {
  isMedia: boolean;
};
const TextArea = styled.textarea<TextAreaType>`
  background-color: transparent;
  color: white;
  resize: none;
  margin: ${({ isMedia }) => (isMedia ? '20px 0' : '20px 0 70px')};
  border: none;
  font-size: 1.6rem;
  width: 100%;
  flex: 1;
  min-height: 350px;
  :focus {
    outline: none;
  }
`;
const AddMediaButton = styled.div`
  position: absolute;
  bottom: 30px;
  width: calc(100% - 63px);
  padding: 15px;
  border-radius: 12px;
  background-color: var(--c700);
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: var(--c300);
  font-size: 1.6rem;
  cursor: pointer;
`;
const SaveButton = styled.div`
  font-family: 'SF Pro Text';
  font-size: 1.3rem;
  letter-spacing: -0.5px;
  position: fixed;
  color: white;
  top: 20px;
  right: 20px;
  z-index: 9999;
`;
