// alertText.ts
import i18n from 'i18next';

export const DEFAULT_ALERT_TEXT = i18n.t(
  `문제가 발생하였습니다. 잠시 후 다시 시도해주세요.`
);
export const UPLOAD_ALERT_TEXT = i18n.t(`업로드에`) + DEFAULT_ALERT_TEXT;
export const ADD_ALERT_TEXT = i18n.t(`등록에`) + DEFAULT_ALERT_TEXT;
export const SAVE_ALERT_TEXT = i18n.t(`저장에`) + DEFAULT_ALERT_TEXT;
export const GET_ALERT_TEXT =
  i18n.t(`데이터를 불러오는데`) + DEFAULT_ALERT_TEXT;
export const VIDEOLOAD_ALERT_TEXT =
  i18n.t(`비디오를 로드하는데`) + DEFAULT_ALERT_TEXT;
export const VIDEOUPLOAD_ALERT_TEXT = i18n.t(
  '이 파일은 제한된 크기(1 GB) 또는 제한된 영상길이 30분을 초과하였기 때문에 업로드할 수 없습니다'
);
export const CENTER_NOTFOUND_ALERT_TEXT = i18n.t('센터를 찾을 수 없습니다');
export const PROFILE_NOTFOUND_ALERT_TEXT = i18n.t('프로필을 찾을 수 없습니다');
