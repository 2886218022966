import * as Icons from 'components/Icon/Icons';
import Icon from 'components/Icon';
import SwitchButton from 'components/SwitchButton';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

export type SwitchBoxType = {
  key: number;
  icon: keyof typeof Icons;
  iconWidth: number;
  title: string;
  desc: string;
  switchOn: boolean;
  setSwitchOn: React.Dispatch<React.SetStateAction<boolean>>;
};
const SwitchBox = ({
  key,
  icon,
  iconWidth,
  title,
  desc,
  switchOn,
  setSwitchOn,
}: SwitchBoxType) => {
  return (
    <Container key={key}>
      <ContentsContainer>
        <TitleContainer>
          <Icon icon={icon} width={iconWidth} />
          <Title>{title}</Title>
        </TitleContainer>
        <Desc>{desc}</Desc>
      </ContentsContainer>
      <SwitchButton id={title} switchOn={switchOn} setSwitchOn={setSwitchOn} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color);
  border-radius: 18px;
  padding: 16px;
`;
const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;
const TitleContainer = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
`;
const Title = styled.span`
  font-family: SF Pro Text;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.71px;
  letter-spacing: -0.5px;
  text-align: left;
  color: var(--c300);
`;
const Desc = styled.span`
  font-family: SF Pro Text;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.5px;
  text-align: left;
  color: var(--c500);
  white-space: pre-line;
`;
export default SwitchBox;
