const Stop = () => (
  <svg
    width='80'
    height='80'
    viewBox='0 0 80 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='40' cy='40' r='40' fill='black' fillOpacity='0.35' />
    <path
      d='M33.3332 26.25H31.6665C29.5954 26.25 27.9165 27.9289 27.9165 30V50C27.9165 52.0711 29.5954 53.75 31.6665 53.75H33.3332C35.4042 53.75 37.0832 52.0711 37.0832 50V30C37.0832 27.9289 35.4042 26.25 33.3332 26.25Z'
      stroke='white'
      strokeWidth='1.5'
    />
    <path
      d='M48.3332 26.25H46.6665C44.5954 26.25 42.9165 27.9289 42.9165 30V50C42.9165 52.0711 44.5954 53.75 46.6665 53.75H48.3332C50.4042 53.75 52.0832 52.0711 52.0832 50V30C52.0832 27.9289 50.4042 26.25 48.3332 26.25Z'
      stroke='white'
      strokeWidth='1.5'
    />
  </svg>
);

export default Stop;
