import { PreperationType, SessionType } from 'constants/types/type';
import { getMMSSFormat } from 'constants/video';
import {
  PrepareContainer,
  PrepareImageWrapper,
  PrepareRequiredBox,
  PrepareText,
} from 'styles/FormStyle';
import { Flex, Margin, PremiumBadge, Span } from 'styles/style';
import * as S from './style';
import { getKoreanDate } from 'constants/common';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import i18n from 'i18n';
type SessionDetailType = {
  session: SessionType;
};
function SessionDetail({ session }: SessionDetailType) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <S.BackgroundWrapper>
        <S.BackgroundImage
          backgroundImage={session?.displayImage.originalImage || ''}
        />
      </S.BackgroundWrapper>
      <S.Container>
        <S.MainInfoContainer>
          <Span fontSize='1.4rem' fontWeight='700'>
            {session.center.displayName}
          </Span>
          <Span fontSize='2.4rem' fontWeight='700'>
            {session.title}
          </Span>
          <Span>ㅡ</Span>
          <Span fontSize='1.6rem' fontWeight='700'>
            {getMMSSFormat(session.video.duration)}
          </Span>
        </S.MainInfoContainer>
        <S.SubInfoContainer>
          <S.CompletionCountContainer>
            <S.CircleContainer>
              {session.memberProfileData.map((v, i) => {
                return <S.CircleImage key={i} src={v.mediumImage} />;
              })}
            </S.CircleContainer>
            <S.CompletionCountText>
              {session.memberProfileDataCount}
              {t('명의 멤버가 완료함')}
            </S.CompletionCountText>
          </S.CompletionCountContainer>
          <S.SubItemContainer>
            <S.SubInfoTitle>{t('세션 소개')}</S.SubInfoTitle>
            <S.TextData>{session.introduceText}</S.TextData>
          </S.SubItemContainer>
          <S.SubItemContainer>
            <S.SubInfoTitle>{t('준비물')}</S.SubInfoTitle>
            <Flex gap='10px' width='343px' overflow='auto'>
              {session.preperationList.length > 0 ? (
                session.preperationList.map(
                  (item: PreperationType, index: number) => {
                    return (
                      <PrepareContainer key={index}>
                        <PrepareImageWrapper
                          width='72px'
                          height='72px'
                          backgroundColor='#2c2d34'
                          cursor='default'
                        >
                          <img src={item.iconUrl} />
                          {item.isNecessary && (
                            <PrepareRequiredBox>{t('필수')}</PrepareRequiredBox>
                          )}
                        </PrepareImageWrapper>
                        <PrepareText>{item.title}</PrepareText>
                      </PrepareContainer>
                    );
                  }
                )
              ) : (
                <span>{t('필요한 준비물이 없습니다')}</span>
              )}
            </Flex>
          </S.SubItemContainer>
          <S.SubItemContainer margin='10px 0 0 0'>
            <S.IntroducesTitle>{session.introduces[0].title}</S.IntroducesTitle>
            <S.TextData>{session.introduces[0].context}</S.TextData>
          </S.SubItemContainer>
          <S.SubItemContainer>
            <Flex flexDirection='row' gap='13px' margin='15px 0 0 0'>
              <S.CenterInfoCircle>
                <img
                  src={
                    session.center.bannerImages[0].largeImage ||
                    '/defaultUserImage.png'
                  }
                  alt={t('프로필 이미지')}
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.onerror = null; // 재귀적인 호출을 방지
                    target.src = '/defaultUserImage.png';
                  }}
                  onClick={() =>
                    navigate(
                      `/center/detail/all?centerId=${session.center.centerId}`
                    )
                  }
                />
              </S.CenterInfoCircle>
              <Flex flexDirection='column'>
                {session.center.centerGrade === 'premium' && (
                  <PremiumBadge>{t('회원전용')}</PremiumBadge>
                )}
                <Margin margin='7px 0 0 0' />
                <Span color='white' fontSize='14px'>
                  {session.center.displayName}
                </Span>
                <Span color='var(--c500)' fontSize='10px' margin='4px 0 0 0'>
                  {getKoreanDate(session.center.createdAt).replace(/-/g, '.')}
                </Span>
              </Flex>
            </Flex>
          </S.SubItemContainer>
          {i18n.language === 'en' ? (
            <Span
              color='var(--c500)'
              fontSize='10px'
              lineHeight='140%'
              margin='-10px 0 0 0'
            >
              {'<'}Copyright 2023. 스포애니강남역1호점. All right reserved{'>'}
              <br />
              <Trans
                i18nKey='본 영상의 저작권은 {{centerName}}에 있습니다'
                values={{ centerName: session.center.displayName }}
              />
              {t(
                '저작권자의 허가 없이 무단으로 배포, 변경 하는 행위에는 법적 조치가 이루어질 수 있습니다'
              )}
            </Span>
          ) : (
            <Span
              color='var(--c500)'
              fontSize='10px'
              lineHeight='140%'
              margin='-10px 0 0 0'
            >
              {session?.copyrightText}
            </Span>
          )}
        </S.SubInfoContainer>
        <S.StartButton
          onClick={() =>
            navigate(
              `/session/detail/video?centerId=${session.centerId}&sessionId=${session.sessionId}`
            )
          }
        >
          {t('세션 시작하기')}
        </S.StartButton>
      </S.Container>
    </>
  );
}
export default SessionDetail;
