import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const Layout = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <path
      d='M6.66675 2.29166H4.16675C3.13121 2.29166 2.29175 3.13113 2.29175 4.16666V15.8333C2.29175 16.8689 3.13121 17.7083 4.16675 17.7083H6.66675C7.70228 17.7083 8.54175 16.8689 8.54175 15.8333V4.16666C8.54175 3.13113 7.70228 2.29166 6.66675 2.29166Z'
      stroke='currentColor'
      strokeWidth='1.5'
    />
    <path
      d='M15.8333 2.29166H13.3333C12.2977 2.29166 11.4583 3.13113 11.4583 4.16666V6.66666C11.4583 7.7022 12.2977 8.54166 13.3333 8.54166H15.8333C16.8688 8.54166 17.7083 7.7022 17.7083 6.66666V4.16666C17.7083 3.13113 16.8688 2.29166 15.8333 2.29166Z'
      stroke='currentColor'
      strokeWidth='1.5'
    />
    <path
      d='M15.8333 11.4583H13.3333C12.2977 11.4583 11.4583 12.2978 11.4583 13.3333V15.8333C11.4583 16.8689 12.2977 17.7083 13.3333 17.7083H15.8333C16.8688 17.7083 17.7083 16.8689 17.7083 15.8333V13.3333C17.7083 12.2978 16.8688 11.4583 15.8333 11.4583Z'
      stroke='currentColor'
      strokeWidth='1.5'
    />
  </svg>
);

export default Layout;
