import Icon from 'components/Icon';
import SessionVideo from 'components/Session/SessionVideo';
import VideoRecorder from 'components/Session/VideoRecorder';
import { SceneType, VideoStatus } from 'constants/types/type';
import useVideoControl from 'hooks/useVideoControl';
import useVideoMetadata from 'hooks/useVideoMetadata';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Margin, Span } from 'styles/style';
import { CountDown } from '../CountDown';
import * as S from './style';

type CoachingVideo = {
  coachingScene: SceneType;
  currentStatus: VideoStatus;
  setCurrentStatus: (value: React.SetStateAction<VideoStatus>) => void;
  setRecordVideoBlob: (value: React.SetStateAction<Blob>) => void;
  setIsRequestFeedback: React.Dispatch<React.SetStateAction<boolean>>;
};
export const CoachingVideo = ({
  coachingScene,
  currentStatus,
  setCurrentStatus,
  setRecordVideoBlob,
  setIsRequestFeedback,
}: CoachingVideo) => {
  const {
    videoRef,
    progressRef,
    currentBarHandler,
    play,
    pause,
    move,
    startDragging,
  } = useVideoControl();
  const [count, setCount] = useState(0);
  const [isConvert, setIsConvert] = useState<boolean>(false);

  const { currentTime: currentTime_c } = useVideoMetadata({
    videoRef,
    play,
    pause,
  });

  useEffect(() => {
    pause();
  }, []);

  // count down
  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1000);
      return () => clearTimeout(timer);
    }

    if (count < 1 && currentStatus === 'coaching_countdown') {
      setCurrentStatus('coaching_start');
      play();
    }
  }, [count]);

  useEffect(() => {
    if (
      coachingScene.additionalVideo &&
      currentTime_c >= coachingScene.additionalVideo?.duration
    ) {
      setCurrentStatus('session');
    }
  }, [currentTime_c]);

  const handleCoachingSkip = () => {
    setCurrentStatus('session');
    setIsRequestFeedback(false);
  };
  const countdownCoaching = () => {
    if (videoRef.current) {
      videoRef.current.play().then(() => {
        videoRef.current && videoRef.current.pause();
        setIsRequestFeedback(true);
        setCurrentStatus('coaching_countdown');
        setCount(5);
      });
    }
  };

  const { t } = useTranslation();

  return (
    <>
      {coachingScene.additionalVideo && (
        <S.VideoContainer>
          <SessionVideo
            video={coachingScene?.additionalVideo}
            videoRef={videoRef}
            flipped={isConvert}
            setIsPaused={pause}
            isCoaching={true}
          />
          <VideoRecorder
            ratio={coachingScene?.additionalVideo.ratio}
            duration={coachingScene.additionalVideo.duration}
            setRecordVideoBlob={setRecordVideoBlob}
            currentStatus={currentStatus}
            setCurrentStatus={setCurrentStatus}
          />
        </S.VideoContainer>
      )}
      {count > 0 && <CountDown count={count} />}

      {currentStatus === 'coaching_wait' && (
        <S.PausedContainer onClick={(e) => e.stopPropagation()}>
          <S.SessionTextWrapper>
            <Span fontSize='2.5rem' fontWeight='700' lineHeight='3.5rem'>
              {t('지금 바로')}
              <br />
              {t('연습하고')}
              <br />
              {t('피드백을 받아보세요!')}
            </Span>
            <Margin margin='10px 0 0 0' />
            <S.CoachingSkipButton onClick={() => handleCoachingSkip()}>
              {t('건너뛰기')}
            </S.CoachingSkipButton>
            <Margin margin='20px 0 0 0' />
            <Flex
              gap='5px'
              alignItems='center'
              cursor='pointer'
              onClick={() => setIsConvert((prev) => !prev)}
            >
              {isConvert ? (
                <>
                  <Span color='var(--primary)'>{t('좌우반전')}</Span>
                  <Icon icon='CheckBox_Active' />
                </>
              ) : (
                <>
                  <Span color='var(--white)'>{t('좌우반전')}</Span>
                  <Icon icon='CheckBox' color='white' />
                </>
              )}
            </Flex>
          </S.SessionTextWrapper>
          <S.GreenStartBox onClick={() => countdownCoaching()}>
            <Icon icon='START_text' />
          </S.GreenStartBox>
        </S.PausedContainer>
      )}
    </>
  );
};
