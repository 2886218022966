import { postCommunityList } from 'apis/community';
import Icon from 'components/Icon';
import SessionCard from 'components/SessionCard';
import SessionView from 'components/SessionView';
import { PostType, SessionType } from 'constants/types/type';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Flex, NotFoundDiv, RelativeDiv, Span } from 'styles/style';
import { WriteButton } from '../All/style';
import { FaPlus } from 'react-icons/fa6';
import useHandleScroll from 'utils/useHandleScroll';
import * as S from './style';
import CommunityItem from 'components/CommunityItem';
import { Line } from 'components/FeedbackItem/style';
import { useTranslation } from 'react-i18next';

const Community = () => {
  const navigate = useNavigate();
  const [communityList, setCommunityList] = useState<PostType[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [menuOpenId, setMenuOpenId] = useState<string>('');

  const [scrollActive, setScrollActive] = useState<boolean>(true);

  // order
  const [orderBy, setOrderBy] = useState<'asc' | 'desc'>('desc');

  const [searchParams, setSearchParams] = useSearchParams();
  const centerId = searchParams.get('centerId');

  const [isPending, setIsPending] = useState<boolean>(true);

  useEffect(() => {
    getData();
  }, [orderBy, offset]);

  const getData = async () => {
    if (!centerId) {
      return;
    }
    setIsPending(true);
    const { list, listInfo } = await postCommunityList({
      data: {
        centerId,
        limit: 10,
        offset,
        orderBy,
      },
    });
    if (list?.length > 0 && offset === 0) {
      setCommunityList(list);
    } else if (list.length > 0) {
      setCommunityList((prevList) => [...prevList, ...list]);
    } else {
      setScrollActive(false);
    }
    setIsPending(false);
  };

  const handleSortingButton = () => {
    setOffset(0);
    if (orderBy === 'asc') setOrderBy('desc');
    if (orderBy === 'desc') setOrderBy('asc');
  };
  useHandleScroll(setOffset, 5, scrollActive);

  const { t } = useTranslation();
  return (
    <>
      <S.TopBar>
        <WriteButton
          onClick={() => navigate(`/center/community/add?centerId=${centerId}`)}
        >
          <FaPlus size={10} color='white' /> <span>{t('새 글쓰기')}</span>
        </WriteButton>
      </S.TopBar>
      {communityList.length < 1 && !isPending ? (
        <NotFoundDiv>{t('등록된 게시글이 없습니다')}</NotFoundDiv>
      ) : (
        communityList.map((post: PostType, index: number) => (
          <RelativeDiv>
            <CommunityItem
              {...post}
              menuOpenId={menuOpenId}
              setMenuOpenId={setMenuOpenId}
            />
            <Line isLast={index === communityList.length - 1} />
          </RelativeDiv>
        ))
      )}
    </>
  );
};
export default Community;
