import {
  postFeedback,
  postFeedbackComplete,
  postFeedbackResponse,
} from 'apis/feedback';
import CenterProfileModal from 'components/CenterProfileModal';
import FeedbackVideoContainer from 'components/FeedbackVideoContainer';
import Icon from 'components/Icon';
import MyProfileImage from 'components/MyProfileImage';
import { NotificationLine } from 'components/NotificationLine';
import PageHeader from 'components/PageHeader';
import ProfileImage from 'components/ProfileImage';
import { RowProfileBox } from 'components/RowProfileBox';
import VideoPlayer from 'components/Video/VideoPlayer';
import {
  DEFAULT_ALERT_TEXT,
  UPLOAD_ALERT_TEXT,
  VIDEOUPLOAD_ALERT_TEXT,
} from 'constants/alertText';
import { getRoleDisplayName, uploadS3 } from 'constants/common';
import {
  FeedbackType,
  ProfileSample,
  ProfileType,
  SceneType,
  VideoType,
} from 'constants/types/type';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useAccessTokenStore,
  useCenterStore,
  useProfileStore,
} from 'store/auth';
import styled from 'styled-components';
import { DeleteButton } from 'styles/FormStyle';
import { CircleImage, Flex, Margin, NullDataBox, Span } from 'styles/style';
const DetailFeedbackPage = () => {
  const navigate = useNavigate();

  //auth
  const { profile } = useProfileStore();

  const [watchingProfile, setWatchingProfile] =
    useState<ProfileType>(ProfileSample);

  const [feedback, setFeedback] = useState<FeedbackType>();
  const [feedbackResContent, setFeedbackResContent] = useState<string>('');
  const [userVideo, setUserVideo] = useState<VideoType | null>(null);

  const [media, setMedia] = useState<File>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const centerId = searchParams.get('centerId');
  const sessionId = searchParams.get('sessionId');
  const feedbackId = searchParams.get('feedbackId');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConvertEnd, setIsConvertEnd] = useState<boolean>(false);

  let nullVideo: VideoType = {
    ratio: 'portrait', // portrait, landscape,
    streamingUrl: '',
    convertStatus: '', // readyForUpload, uploaded, processing, completed, failed,
    duration: 0, // ms 단위
    metadata: {
      originalWidth: 0,
      originalHeight: 0,
    },
    videoKey: '', // 서버 내부 관리용
    isCompleted: false,
  };
  // 프로필 모달
  const [modalProfile, setModalProfile] = useState<ProfileType>(ProfileSample);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, [feedbackId]);
  useEffect(() => {
    let retryCount = 0;
    const maxRetries = 10;
    const interval = 1000;

    if (feedback?.feedbackVideo.convertStatus !== 'completed') {
      const intervalId = setInterval(() => {
        if (retryCount >= maxRetries) {
          clearInterval(intervalId);
        } else {
          getData();
          retryCount++;
        }
      }, interval);

      return () => clearInterval(intervalId);
    } else {
      setUserVideo(feedback.feedbackVideo);
    }

    if (
      feedback.response?.video &&
      feedback.response?.video?.convertStatus !== 'completed'
    ) {
      const intervalId = setInterval(() => {
        if (retryCount >= maxRetries) {
          clearInterval(intervalId);
        } else {
          getData();
          retryCount++;
        }
      }, interval);

      return () => clearInterval(intervalId);
    } else {
      // 동작 없어도 됨. feedback.response.video는 직접 값 사용중
    }
  }, [feedback]);
  // 터치 이벤트를 위한 useEffect 추가
  useEffect(() => {
    const handleTouchMove = (e: TouchEvent) => {
      // 이 페이지에서는 preventDefault를 막습니다.
      e.stopImmediatePropagation();
    };

    document.addEventListener('touchmove', handleTouchMove, { capture: true });

    return () => {
      document.removeEventListener('touchmove', handleTouchMove, {
        capture: true,
      });
    };
  }, []);
  const getData = async () => {
    try {
      if (!feedbackId || !sessionId || !centerId) {
        return;
      }
      const response = await postFeedback({
        params: { feedbackId },
        data: {
          centerId,
          sessionId,
        },
      });
      const result = response;
      if (!result) {
        navigate(-1);
        return;
      }

      const { feedbackView, watchingProfile } = result;
      setFeedback(feedbackView);
      setWatchingProfile(watchingProfile);
    } catch (e) {
      console.log(e);
      alert(DEFAULT_ALERT_TEXT);
    }
  };
  const addMedia = (file: File) => {
    if (file.size > 1 * 1024 * 1024 * 1024) {
      alert(VIDEOUPLOAD_ALERT_TEXT);
      return;
    }
    setMedia(file);
  };
  const submit = async () => {
    if (feedbackResContent === '') {
      alert(t('응답을 남겨주세요'));
      return;
    }
    if (!feedbackId || !sessionId || !centerId) {
      return;
    }
    setIsLoading(true);
    const response = await postFeedbackResponse({
      params: {
        feedbackId,
      },
      data: {
        sessionId,
        centerId,
        feedbackResContent,
        ...(media && media.type.startsWith('image/')
          ? { feedbackResImg: { fileName: media.name.toLowerCase() } }
          : { feedbackResImg: null }),
        ...(media && media.type.startsWith('video/')
          ? { feedbackResVod: { fileName: media.name.toLowerCase() } }
          : { feedbackResVod: null }),
      },
    });

    const result = response;
    if (!result) return;
    const { feedbackVideoPresignedUrl, feedbackImagePresignedUrl, responseId } =
      result;

    if (media && feedbackImagePresignedUrl) {
      uploadS3(feedbackImagePresignedUrl, media)
        .then(() => {
          feedbackId && sendComplete(feedbackId, responseId);
        })
        .catch((error) => {
          console.error('An error occurred during the upload:', error);
          alert(UPLOAD_ALERT_TEXT);
        });
    }
    if (media && feedbackVideoPresignedUrl) {
      uploadS3(feedbackVideoPresignedUrl, media)
        .then(() => {
          feedbackId && sendComplete(feedbackId, responseId);
        })
        .catch((error) => {
          console.error('An error occurred during the upload:', error);
          alert(UPLOAD_ALERT_TEXT);
        });
    }
    if (!media) {
      setIsLoading(false);
      navigate(0);
    }
  };

  const sendComplete = async (feedbackId: string, resNo: string) => {
    if (!sessionId || !centerId) return;
    const response = postFeedbackComplete({
      params: { feedbackId },
      data: {
        centerId,
        sessionId,
        resNo,
      },
    });
    setIsLoading(false);
    navigate(0);
  };

  const onChangeFeedbackResContent = useCallback((e: any) => {
    setFeedbackResContent(e.target.value);
  }, []);

  const openModal = (profile: ProfileType) => {
    setModalProfile(profile);
    setIsModalOpen(true);
  };
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title={t('피드백 보기')} isLineShow={false} />
      {feedback && (
        <Container>
          {!feedback.response && (
            <Notification>
              <Icon icon='Graph' />
              {feedback.center?.displayName}
              {t('에서 피드백을 준비중입니다')}
            </Notification>
          )}
          <Margin margin='27px 0 0 0' />
          <RowProfileBox
            profile={feedback.requestedBy}
            clickEvent={() => openModal(feedback.requestedBy)}
          />

          {!userVideo && (
            <NotificationLine
              margin='20px 0 -10px 0'
              type='alert'
              text={t('영상을 변환하고 있는 중입니다...')}
            />
          )}
          {feedback.scene?.additionalVideo ? (
            <FeedbackVideoContainer
              feedbackVideo={feedback.scene.additionalVideo}
              userVideo={userVideo || nullVideo}
            />
          ) : (
            <NullDataBox>{t('영상을 불러오는데 실패했습니다')}</NullDataBox>
          )}

          <TextArea value={feedback.feedbackContent} readOnly={true} />

          {feedback.response ? (
            <RowProfileBox
              profile={feedback.response.responsedBy}
              clickEvent={() => openModal(feedback.response.responsedBy)}
            />
          ) : watchingProfile &&
            (watchingProfile.role === 'staff' ||
              watchingProfile.role === 'owner') ? (
            <RowProfileBox
              profile={watchingProfile}
              clickEvent={() => openModal(watchingProfile)}
            />
          ) : null}

          {(watchingProfile.role === 'staff' ||
            watchingProfile.role === 'owner') && (
            <>
              <TextArea
                placeholder={t('회원님의 요청에 응답을 남겨주세요')}
                value={feedback.response?.content}
                onChange={onChangeFeedbackResContent}
                readOnly={!!feedback.response}
              />
              <input
                id='feedbackMedia'
                accept='image/*, video/*'
                type='file'
                style={{ display: 'none' }}
                onChange={(e) => e.target.files && addMedia(e.target.files[0])}
              />
            </>
          )}
          {(watchingProfile.role === 'member' ||
            watchingProfile.role === 'guest') &&
            feedback.response && (
              <TextArea value={feedback.response?.content} readOnly={true} />
            )}

          {!feedback.response &&
            (watchingProfile.role === 'staff' ||
              watchingProfile.role === 'owner') &&
            (media ? (
              <MediaContainer>
                <MediaWrapper>
                  {media.type.startsWith('image/') ? (
                    <img src={URL.createObjectURL(media)} />
                  ) : (
                    <video controls src={URL.createObjectURL(media)} />
                  )}
                </MediaWrapper>

                <DeleteButton onClick={() => setMedia(undefined)}>
                  <Icon icon='X' width={9.8} height={9.8} />
                </DeleteButton>
              </MediaContainer>
            ) : (
              <label htmlFor='feedbackMedia'>
                <AddMediaButton>
                  <Icon icon='Plus' width={10} height={10} />
                  <Span>{t('미디어 추가하기')}</Span>
                </AddMediaButton>
              </label>
            ))}
          {feedback.response &&
            (feedback.response.video || feedback.response.image) && (
              <MediaContainer>
                {!isConvertEnd &&
                  feedback.response.video?.convertStatus != 'completed' && (
                    <>
                      <NotificationLine
                        margin='30px 0 0px 0'
                        type='alert'
                        text={t('영상을 변환하고 있는 중입니다...')}
                      />
                      <Margin margin='0 0 12px 0' />
                    </>
                  )}
                <MediaWrapper>
                  {feedback.response.video ? (
                    <VideoPlayer
                      src={feedback.response.video?.streamingUrl}
                      controls={true}
                      autoplay={true} // 썸네일 보이게 하기 위해서
                      thumbnailImage={
                        feedback.response.video.thumbnailImage?.originalImage
                      }
                      isStopImmediate={true}
                      setIsConvertEnd={setIsConvertEnd}
                    />
                  ) : (
                    <img src={feedback.response.image?.thumbnailImage} />
                  )}
                </MediaWrapper>
              </MediaContainer>
            )}
          {!feedback.response &&
            (watchingProfile.role === 'staff' ||
              watchingProfile.role === 'owner') && (
              <SubmitButton
                disabled={isLoading}
                type='button'
                onClick={() => submit()}
              >
                {t('피드백 남기기')}
              </SubmitButton>
            )}
        </Container>
      )}
      {isModalOpen && (
        <CenterProfileModal
          profile={modalProfile}
          setIsModalOpen={setIsModalOpen}
          mode={
            modalProfile.userKey === watchingProfile.userKey ? 'edit' : 'view'
          }
        />
      )}
    </>
  );
};
export default DetailFeedbackPage;

const Container = styled.div`
  width: 100%;
  max-width: 414px;
  padding: 0 16px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  margin: 60px auto 120px;
  cursor: default;
`;
const Notification = styled.div`
  color: var(--c300);
  display: flex;
  gap: 5px;
  background-color: var(--background-color);
  font-family: SF Pro Text;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.5px;
  text-align: left;
  align-items: center;
  padding: 10px 14px;
`;
const UserInfoContainer = styled.div`
  display: flex;
  gap: 10px;
`;
const TextArea = styled.textarea`
  width: 100%;
  height: 150px;
  border-radius: 12px;
  margin: 15px 0 20px 0;
  background-color: var(--background-color);
  padding: 15px;
  color: white;
  resize: none;
  line-height: 20px;
`;
const AddMediaButton = styled.div`
  width: 100%;
  padding: 15px;
  border-radius: 12px;
  background-color: var(--c700);
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: var(--c300);
  font-size: 1.6rem;
  cursor: pointer;
`;
const SubmitButton = styled.button`
  display: flex;
  width: 100%;
  padding: 15px;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  color: black;
  margin-top: 30px;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
`;
const MediaContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 15px 0 20px;
`;
const MediaWrapper = styled.div`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
`;
